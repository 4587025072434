import { IconCircleMinus } from '@rossum/ui/icons/tabler';
import { Button, Chip, Stack, SvgIcon } from '@rossum/ui/material';
import { useIntl } from 'react-intl';

type Props = {
  selectedValues: string[];
  valueOptions: string[];
  setValues: (value: string[]) => void;
};

const SelectionHandlers = ({
  selectedValues,
  valueOptions,
  setValues,
}: Props) => {
  const intl = useIntl();

  return (
    <Stack
      flexDirection="row"
      alignItems="center"
      gap={0.5}
      flexWrap="wrap"
      justifyContent="flex-end"
    >
      <Button
        size="small"
        color="secondary"
        onClick={() => setValues(valueOptions)}
      >
        {intl.formatMessage({
          id: 'containers.filtering.buttons.selectAll',
        })}
      </Button>
      <Chip
        label={`${selectedValues.length} / ${valueOptions.length}`}
        onDelete={() => setValues([])}
        deleteIcon={
          <SvgIcon component={IconCircleMinus} sx={{ fill: 'none' }} />
        }
      />
    </Stack>
  );
};

export { SelectionHandlers };
