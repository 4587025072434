/* eslint-disable react/no-array-index-key */
import { ArrowDropDown } from '@rossum/ui/icons';
import {
  alpha,
  Box,
  BoxProps,
  Fade,
  ListItemText,
  styled,
} from '@rossum/ui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import * as R from 'remeda';
import { filter } from 'rxjs/operators';
import { getType } from 'typesafe-actions';
import { DEFAULT_ROW_TYPE } from '../../constants/values';
import { useDocumentStore } from '../../features/annotation-view/document-store/DocumentStore';
import { gridScaleFactorSelector } from '../../features/annotation-view/document-store/documentStoreSelectors';
import { useDocumentContext } from '../../features/annotation-view/DocumentContext';
import { updateGridAfterHorizontalSeparatorCreated } from '../../redux/modules/grid/actions';
import { Grid } from '../../types/datapoints';
import { GridSchema } from '../../types/schema';
import { usePageSpaceContext } from '../DocumentPage/PageSpaceContext';
import { useObservableContext } from '../ObservableProvider';
import { GRID_LINE_WIDTH, LABEL_HORIZONTAL_PADDING } from './constants';
import { ControlBar } from './ControlBar';
import { GridProvider } from './GridContext';
import { GridDragHandle } from './GridDragHandle';
import { HorizontalSeparator } from './HorizontalSeparator';
import { LabelSelectControl } from './LabelSelectControl';
import { ResizeHandle } from './ResizeHandle';
import { TemporaryRuler, TemporaryRulerProps } from './TemporaryRuler';
import { RowTypeOption } from './useGridState';
import {
  assignSchemaIds,
  canSeparatorBeCreated,
  changeRowType,
  createSeparator,
  deleteSeparator,
  getColumnsAreas,
  getRowAreas,
  GridAction,
  gridActions,
  GridUiState,
  isAreaHovered,
  isColumnVisible,
  isRowVisible,
  ResizingEdge,
  SelectOption,
} from './utils';
import { VerticalSeparator } from './VerticalSeparator';

const StyledGridContainer = styled(Box, {
  shouldForwardProp: propName =>
    propName !== 'disabled' &&
    propName !== 'focused' &&
    propName !== 'gridHovered',
})<
  BoxProps & {
    disabled: boolean;
    focused: boolean;
    gridHovered: boolean;
  }
>(({ theme, disabled, focused, gridHovered }) => ({
  position: 'absolute',
  borderWidth: '2px',
  borderStyle: 'solid',
  borderColor:
    disabled || (!focused && !gridHovered)
      ? alpha(theme.palette.common.black, theme.palette.action.disabledOpacity)
      : focused || gridHovered
        ? theme.palette.primary.main
        : alpha(theme.palette.primary.main, 0.5),
  zIndex: 10001,
  pointerEvents: 'none',
}));

export type GridContainerProps = BoxProps & {
  gridState: Grid;
  schemaIdOptions: SelectOption[];
  gridSchema: GridSchema;
  rowTypeOptions: RowTypeOption[];
  // data loading
  actionInProgress: boolean | string;
  // read-only documents and requests in flights
  disabled: boolean;
  // line item or child are selected;
  focused: boolean;
  onGridMoved: (newState: Grid) => void;
  onGridResized: (newState: Grid, resizingEdge: ResizingEdge) => void;
  onVerticalSeparatorMoved: (
    newState: Grid,
    verticalSeparatorIndex: number
  ) => void;
  onVerticalSeparatorDeleted: (grid: Grid, separatorIndex: number) => void;
  onVerticalSeparatorCreated: (grid: Grid, newPosition: number) => void;
  onHorizontalSeparatorMoved: (
    newState: Grid,
    horizontalSeparatorIndex: number
  ) => void;
  onHorizontalSeparatorDeleted: (
    newState: Grid,
    separatorIndex: number
  ) => void;
  onHorizontalSeparatorCreated: (grid: Grid, newPosition: number) => void;
  onSchemaIdsAssigned: (
    newState: Grid,
    changes: {
      separatorIndex: number;
      prev: string | null;
      current: string | null;
    }[]
  ) => void;
  onRowTypeChanged: (
    newState: Grid,
    separatorIndex: number,
    rowType: string | null
  ) => void;
  onGridActionFired: (actionId: GridAction, newState: Grid) => void;
};

const GridContainer = React.memo(
  ({
    children,
    gridState,
    schemaIdOptions,
    gridSchema,
    rowTypeOptions,
    onGridMoved,
    onGridResized,
    actionInProgress,
    disabled,
    focused,
    onVerticalSeparatorMoved,
    onVerticalSeparatorDeleted,
    onVerticalSeparatorCreated,
    onHorizontalSeparatorMoved,
    onHorizontalSeparatorDeleted,
    onHorizontalSeparatorCreated,
    onSchemaIdsAssigned,
    onRowTypeChanged,
    onGridActionFired,
    ...restBoxProps
  }: GridContainerProps) => {
    const intl = useIntl();
    const scaleFactor = useDocumentStore(gridScaleFactorSelector);

    const { mousePositionOnPageObservable, pageWidth, pageHeight } =
      usePageSpaceContext();

    // While any interaction is going on, use a draft state instead of derived state from server
    // Provided via context, child component _mutate this_ when necessary!
    const [gridDraftState, setGridDraftState] = useState<Grid | null>(null);

    const currentGridState = gridDraftState ?? gridState;

    const [gridUiState, setGridUiState] = useState<GridUiState>({
      activeColumnIndex: null,
      activeRowIndex: null,
      documentHovered: false,
    });

    const showCreateControls = focused && !disabled;
    const showResizeControls =
      showCreateControls && !actionInProgress && !disabled;

    const handleGridMoved = useCallback(() => {
      if (gridDraftState) {
        onGridMoved(gridDraftState);
      }
    }, [gridDraftState, onGridMoved]);

    const handleGridResized = useCallback(
      (resizingEdge: ResizingEdge) => {
        if (gridDraftState) {
          onGridResized(gridDraftState, resizingEdge);
        }
      },
      [gridDraftState, onGridResized]
    );

    // Vertical separators
    // Hovered state management
    const [hoveredColumnIndex, setHoveredColumnIndex] = useState<number | null>(
      null
    );

    const [hoveredRowIndex, setHoveredRowIndex] = useState<number | null>(null);

    const hoverableAreas = useMemo(
      () => ({
        columns: getColumnsAreas(gridState, scaleFactor),
        rows: getRowAreas(gridState, scaleFactor),
      }),
      [gridState, scaleFactor]
    );

    // Grid actions
    const [gridActionsOpen, setGridActionsOpen] = useState(false);

    const handleGridActionsOpen = useCallback(() => {
      setGridActionsOpen(true);
    }, []);

    const handleGridActionsClose = useCallback(() => {
      setGridActionsOpen(false);
    }, []);

    const handleGridAction = useCallback(
      (actionId: GridAction | null) => {
        if (actionId !== null) {
          onGridActionFired(actionId, gridState);
        }
      },
      [gridState, onGridActionFired]
    );

    const interactionInProgress =
      !!gridDraftState ||
      gridUiState.activeColumnIndex !== null ||
      gridUiState.activeRowIndex !== null;

    const gridInteractive =
      !interactionInProgress &&
      !disabled &&
      !actionInProgress &&
      !gridActionsOpen &&
      focused;

    const { isDocumentHoveredObserver } = useObservableContext();

    useEffect(() => {
      const isDocumentHoveredSubscription = isDocumentHoveredObserver.subscribe(
        documentHovered => {
          if (gridUiState.documentHovered !== documentHovered) {
            return setGridUiState({ ...gridUiState, documentHovered });
          }

          return false;
        }
      );

      return () => {
        isDocumentHoveredSubscription.unsubscribe();
      };
    }, [gridUiState, isDocumentHoveredObserver]);

    useEffect(() => {
      const hoverSubscription = mousePositionOnPageObservable
        .pipe(filter(() => gridInteractive))
        .subscribe(mousePosition => {
          const highlightedColumnIndex = hoverableAreas.columns.findIndex(
            area => isAreaHovered(area, mousePosition)
          );

          const highlightedRowIndex = hoverableAreas.rows.findIndex(area =>
            isAreaHovered(area, mousePosition)
          );

          setHoveredColumnIndex(
            highlightedColumnIndex > -1 ? highlightedColumnIndex : null
          );

          setHoveredRowIndex(
            highlightedRowIndex > -1 ? highlightedRowIndex : null
          );
        });

      return () => {
        hoverSubscription.unsubscribe();
      };
    }, [
      scaleFactor,
      mousePositionOnPageObservable,
      interactionInProgress,
      pageWidth,
      pageHeight,
      hoverableAreas,
      gridInteractive,
    ]);

    useEffect(() => {
      if (!focused || disabled || actionInProgress) {
        setHoveredColumnIndex(null);
        setHoveredRowIndex(null);
      }
    }, [disabled, focused, actionInProgress]);

    // Hide Top/Tool Bar when interacting
    const { setCanvasActionInProgress } = useDocumentContext();
    useEffect(() => {
      setCanvasActionInProgress(interactionInProgress);
    }, [interactionInProgress, setCanvasActionInProgress]);

    // Temporary ruler while moving over top control line
    const [rulerState, setRulerState] = useState<TemporaryRulerProps | null>(
      null
    );

    const handleRulerPosition = useCallback(
      (orientation: 'horizontal' | 'vertical') =>
        (e: React.MouseEvent<HTMLDivElement>) => {
          const position = Math.round(
            e.nativeEvent[
              orientation === 'horizontal' ? 'offsetY' : 'offsetX'
            ] / scaleFactor
          );

          if (canSeparatorBeCreated(orientation, currentGridState, position)) {
            setRulerState({ orientation, position });
          } else {
            setRulerState(null);
          }
        },
      [currentGridState, scaleFactor]
    );

    const handleVerticalSeparatorCreated = useCallback(() => {
      if (rulerState?.orientation === 'vertical' && !actionInProgress) {
        const grid = createSeparator(
          'vertical',
          gridState,
          gridState.columns[0].leftPosition + rulerState.position
        );

        onVerticalSeparatorCreated(
          grid,
          gridState.columns[0].leftPosition + rulerState.position
        );

        setRulerState(null);
      }
    }, [
      actionInProgress,
      gridState,
      onVerticalSeparatorCreated,
      rulerState?.orientation,
      rulerState?.position,
    ]);

    const handleVerticalSeparatorMoved = useCallback(
      (index: number) => {
        if (gridDraftState) {
          onVerticalSeparatorMoved(gridDraftState, index);
        }
      },
      [gridDraftState, onVerticalSeparatorMoved]
    );

    const handleVerticalSeparatorDelete = useCallback(
      (index: number) => {
        onVerticalSeparatorDeleted(
          deleteSeparator('vertical', gridState, index),
          index
        );
      },
      [gridState, onVerticalSeparatorDeleted]
    );

    const handleHorizontalSeparatorCreated = useCallback(() => {
      if (
        rulerState?.orientation === 'horizontal' &&
        (!actionInProgress ||
          actionInProgress ===
            getType(updateGridAfterHorizontalSeparatorCreated))
      ) {
        const grid = createSeparator(
          'horizontal',
          gridState,
          gridState.rows[0].topPosition + rulerState.position,
          gridSchema.defaultRowType ?? DEFAULT_ROW_TYPE
        );

        onHorizontalSeparatorCreated(
          grid,
          gridState.rows[0].topPosition + rulerState.position
        );

        setRulerState(null);
      }
    }, [
      actionInProgress,
      gridSchema.defaultRowType,
      gridState,
      onHorizontalSeparatorCreated,
      rulerState?.orientation,
      rulerState?.position,
    ]);

    const handleHorizontalSeparatorMoved = useCallback(
      (index: number) => {
        if (gridDraftState) {
          onHorizontalSeparatorMoved(gridDraftState, index);
        }
      },
      [gridDraftState, onHorizontalSeparatorMoved]
    );

    const handleHorizontalSeparatorDelete = useCallback(
      (index: number) => {
        onHorizontalSeparatorDeleted(
          deleteSeparator('horizontal', gridState, index),
          index
        );
      },
      [gridState, onHorizontalSeparatorDeleted]
    );

    const handleSchemaIdChanged = useCallback(
      (index: number, schemaId: string | null) => {
        const previouslyAssignedIndex = gridState.columns.findIndex(
          col => col.schemaId === schemaId
        );

        const schemaIdChanges = R.pipe(
          [
            {
              prev: gridState.columns[index].schemaId ?? null,
              current: schemaId,
              separatorIndex: index,
            },
            previouslyAssignedIndex > -1 && {
              separatorIndex: previouslyAssignedIndex,
              prev: schemaId,
              current: null,
            },
          ],
          R.filter(R.isTruthy)
        );

        onSchemaIdsAssigned(
          assignSchemaIds(gridState, schemaIdChanges),
          schemaIdChanges
        );
      },
      [gridState, onSchemaIdsAssigned]
    );

    const handleRowTypeChanged = useCallback(
      (index: number, rowType: string | null) => {
        onRowTypeChanged(
          changeRowType(
            gridState,
            index,
            rowType,
            gridSchema.rowTypesToExtract ?? []
          ),
          index,
          rowType
        );
      },
      [gridSchema.rowTypesToExtract, gridState, onRowTypeChanged]
    );

    return (
      <GridProvider
        gridState={gridState}
        gridDraftState={[gridDraftState, setGridDraftState]}
        gridUiState={[gridUiState, setGridUiState]}
        schemaIdOptions={schemaIdOptions}
        gridSchema={gridSchema}
        rowTypeOptions={rowTypeOptions}
        gridLoading={!!actionInProgress}
        gridDisabled={disabled}
        gridFocused={focused}
      >
        <StyledGridContainer
          {...restBoxProps}
          data-dragged={gridDraftState === null ? undefined : true}
          disabled={disabled || !!actionInProgress}
          focused={focused}
          gridHovered={gridUiState.documentHovered}
          style={{
            top:
              currentGridState.rows[0].topPosition * scaleFactor -
              GRID_LINE_WIDTH / 2,
            left:
              currentGridState.columns[0].leftPosition * scaleFactor -
              GRID_LINE_WIDTH / 2,
            width: currentGridState.width * scaleFactor + GRID_LINE_WIDTH,
            height: currentGridState.height * scaleFactor + GRID_LINE_WIDTH,
            cursor: actionInProgress ? 'progress' : 'unset',
          }}
          data-cy={`grid-container-${gridState.page}`}
        >
          {/* <InteractiveRectanglesDevtool
            areas={hoverableAreas.columns}
            origin={[
              gridState.columns[0].leftPosition * scaleFactor,
              gridState.rows[0].topPosition * scaleFactor,
            ]}
          /> */}
          <Fade in={showCreateControls} unmountOnExit>
            <div>
              <ControlBar
                orientation="horizontal"
                cssPosition={
                  rulerState?.orientation === 'vertical'
                    ? rulerState.position * scaleFactor
                    : null
                }
                isInteractive={!interactionInProgress && !actionInProgress}
                onMouseMove={handleRulerPosition('vertical')}
                onMouseLeave={() => setRulerState(null)}
                onClick={handleVerticalSeparatorCreated}
                data-cy={`grid-control-bar-horizontal-${currentGridState.page}`}
              />
              <ControlBar
                orientation="vertical"
                cssPosition={
                  rulerState?.orientation === 'horizontal'
                    ? rulerState.position * scaleFactor
                    : null
                }
                isInteractive={
                  !interactionInProgress &&
                  (!actionInProgress ||
                    actionInProgress ===
                      getType(updateGridAfterHorizontalSeparatorCreated))
                }
                onMouseMove={handleRulerPosition('horizontal')}
                onMouseLeave={() => setRulerState(null)}
                onClick={handleHorizontalSeparatorCreated}
                data-cy={`grid-control-bar-vertical-${currentGridState.page}`}
              />
            </div>
          </Fade>
          {currentGridState.rows.map((row, index) =>
            isRowVisible(currentGridState, row) ? (
              <HorizontalSeparator
                key={`hs-${index}-${gridState.rows.length}`}
                index={index}
                isHovered={hoveredRowIndex === index}
                onMoved={handleHorizontalSeparatorMoved}
                onDelete={handleHorizontalSeparatorDelete}
                onRowTypeChanged={handleRowTypeChanged}
              />
            ) : null
          )}
          {currentGridState.columns
            // not filtering because we need indexes to match with gridState
            // instead if it falls out of grid, render null
            .map((col, index) =>
              isColumnVisible(currentGridState, col) ? (
                <VerticalSeparator
                  key={`vs-${index}-${gridState.columns.length}`}
                  index={index}
                  isHovered={hoveredColumnIndex === index}
                  onMoved={handleVerticalSeparatorMoved}
                  onDelete={handleVerticalSeparatorDelete}
                  onSchemaIdChanged={handleSchemaIdChanged}
                />
              ) : null
            )}
          {rulerState &&
            (!actionInProgress ||
              actionInProgress ===
                getType(updateGridAfterHorizontalSeparatorCreated)) && (
              <TemporaryRuler
                orientation={rulerState.orientation}
                position={rulerState.position * scaleFactor}
              />
            )}
          <Fade in={showResizeControls} unmountOnExit>
            <div>
              {(
                [
                  'top-left',
                  'top',
                  'top-right',
                  'right',
                  'bottom-right',
                  'bottom',
                  'bottom-left',
                  'left',
                ] as const
              ).map(edge => (
                <ResizeHandle
                  key={edge}
                  edge={edge}
                  onResized={handleGridResized}
                  data-cy={`grid-resize-handle-${currentGridState.page}-${edge}`}
                />
              ))}

              <GridDragHandle
                onMoved={handleGridMoved}
                data-cy={`grid-drag-handle-${currentGridState.page}`}
              />
            </div>
          </Fade>
          <Box
            sx={{
              position: 'absolute',
              transform: `translate(calc(-100% - ${
                GRID_LINE_WIDTH + LABEL_HORIZONTAL_PADDING
              }px), calc(-100% - ${focused ? 32 : 16}px))`,
              transition: theme =>
                theme.transitions.create('all', {
                  duration: theme.transitions.duration.short,
                  easing: theme.transitions.easing.easeInOut,
                }),
            }}
          >
            <LabelSelectControl
              options={gridActions.map(action => ({
                id: action,
                label: action,
                disabled:
                  action === 'clearColumns'
                    ? currentGridState.columns.length === 1
                    : action === 'clearRows'
                      ? currentGridState.rows.length === 1
                      : false,
              }))}
              disabled={disabled || !!actionInProgress || !focused}
              highlighted={false}
              buttonLabel={
                <ArrowDropDown
                  fontSize="small"
                  sx={{
                    transform: gridActionsOpen ? 'rotate(180deg)' : undefined,
                    transition: theme => theme.transitions.create('transform'),
                  }}
                />
              }
              value={null}
              onChange={handleGridAction}
              onMenuOpen={handleGridActionsOpen}
              onMenuClose={handleGridActionsClose}
              renderOption={option => (
                <ListItemText primaryTypographyProps={{ variant: 'body2' }}>
                  {intl.formatMessage({
                    id: `components.magicGrid.gridActions.${option.id}`,
                  })}
                </ListItemText>
              )}
              dataCy={`grid-actions-${currentGridState.page}`}
            />
          </Box>
        </StyledGridContainer>
      </GridProvider>
    );
  }
);

GridContainer.displayName = 'GridContainer';

export { GridContainer };
