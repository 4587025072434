import { Url } from '@rossum/api-client';
import {
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  ClickAwayListener,
  Fade,
  FormControlLabel,
  Paper,
  Popper,
  Stack,
  Typography,
} from '@rossum/ui/material';
import {
  ChangeEvent,
  MouseEventHandler,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import QueuePicker from '../../../containers/Statistics/containers/QueuePicker';
import { VirtualElement } from '../../../types/CustomTypes/VirtualElement';
import { filterWorkspaces } from '../../queues/helpers/filterWorkspaces';
import { useQueuesForOrganization } from '../hooks/useQueuesForOrganization';
import { StatisticsFilterDropdown } from './StatisticsFilterDropdown';
export type QueueFilter = {
  urls: Url[];
  deleted: boolean;
};

type BillingQueuePickerProps = {
  organizationUrl: Url | undefined;
  initialValue: QueueFilter | undefined;
  onChange: (value: QueueFilter | undefined) => void;
};

export const BillingQueuePicker = ({
  organizationUrl,
  initialValue,
  onChange,
}: BillingQueuePickerProps) => {
  const intl = useIntl();
  const [open, setOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<VirtualElement | null>(null);
  const [queuesSearch, setQueuesSearch] = useState<string>('');

  const {
    data: queuesByWorkspace = [],
    isLoading,
    queues,
  } = useQueuesForOrganization(organizationUrl);

  const workspacesToOptions = <
    T extends { name: string; queues: { name: string; url: Url }[] },
  >(
    workspaces: T[]
  ) =>
    workspaces
      .filter(workspace => workspace.queues.length)
      .map(workspace => ({
        label: workspace.name,
        value: workspace.queues.map(queue => ({
          label: queue.name,
          value: queue.url,
        })),
      }));

  const queueOptions = useMemo(
    () =>
      workspacesToOptions(
        queuesSearch
          ? filterWorkspaces(queuesSearch, queuesByWorkspace)
          : queuesByWorkspace
      ),
    [queuesByWorkspace, queuesSearch]
  );

  const [fieldValue, setFieldValue] = useState<QueueFilter | undefined>(
    initialValue
  );

  useEffect(() => {
    setFieldValue(initialValue);
  }, [initialValue]);

  const { urls, deleted } = fieldValue ?? {
    urls: queues?.map(queue => queue.url),
    deleted: true,
  };

  const currentUrls = initialValue?.urls ?? queues?.map(queue => queue.url);

  const handleSubmit = () => {
    onChange(fieldValue);
    setOpen(false);
  };

  const handleClose = () => {
    setFieldValue(initialValue);
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClick: MouseEventHandler = event => {
    setAnchorEl(event.currentTarget);
    return open ? handleClose() : handleOpen();
  };

  const chipLabel = (numberOfSelectedQueues: number | undefined) =>
    queues ? (
      `${numberOfSelectedQueues ?? queues.length} / ${queues.length}`
    ) : (
      <CircularProgress color="secondary" size={8} />
    );

  const isDefaultValue = (filter: QueueFilter) => {
    return filter.urls?.length === queues?.length && filter.deleted === true;
  };

  return (
    <>
      <StatisticsFilterDropdown onClick={handleClick} open={open}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Typography variant="body2">
            {intl.formatMessage({
              id: 'containers.statistics.sidebar.title.queues',
            })}
          </Typography>
          <Chip
            label={chipLabel(currentUrls?.length)}
            sx={{ alignItems: 'center', cursor: 'pointer' }}
          />
        </Stack>
      </StatisticsFilterDropdown>

      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        transition
        sx={{ zIndex: 1 }}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={() => handleClose()}>
            <Fade {...TransitionProps} timeout={350}>
              <Paper
                sx={{
                  width: 300,
                }}
              >
                <Stack direction="column">
                  <Stack
                    sx={{
                      maxHeight: 450,
                      minHeight: 200,
                      width: '100%',
                      display: 'flex',
                      overflow: 'auto',
                    }}
                  >
                    <QueuePicker<Url>
                      titleId="containers.statistics.sidebar.title.queues"
                      onSearch={setQueuesSearch}
                      options={queueOptions}
                      isSearched={queuesSearch !== ''}
                      picked={urls ?? []}
                      onPick={(pickedUrls: Url[]) => {
                        const newFilter = { urls: pickedUrls, deleted };
                        return setFieldValue(
                          isDefaultValue(newFilter) ? undefined : newFilter
                        );
                      }}
                      queuesLoaded={!isLoading}
                    />
                  </Stack>
                  {urls && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          id="deleted"
                          onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            const newFilter = {
                              urls,
                              deleted: event.target.checked,
                            };
                            return setFieldValue(
                              isDefaultValue(newFilter) ? undefined : newFilter
                            );
                          }}
                          checked={deleted}
                        />
                      }
                      sx={{
                        justifyContent: 'space-between',
                        p: 1,
                        pl: 2,
                        mx: 0,
                        width: 'auto',
                        '&:hover': {
                          backgroundColor: theme =>
                            theme.palette.mode === 'dark'
                              ? 'rgba(255, 255, 255, 0.2)'
                              : 'rgba(0, 0, 0, 0.1)',
                        },
                      }}
                      labelPlacement="start"
                      label={
                        <Typography variant="body2">
                          {intl.formatMessage({
                            id: 'components.controlledQueuePicker.deletedQueues',
                          })}
                        </Typography>
                      }
                    />
                  )}
                  <Stack direction="row" m={1} justifyContent="flex-end">
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      onClick={() => handleSubmit()}
                      disabled={!urls || (urls.length === 0 && !deleted)}
                    >
                      {intl.formatMessage({
                        id: 'components.controlledQueuePicker.save',
                      })}
                    </Button>
                  </Stack>
                </Stack>
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  );
};
