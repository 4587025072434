import { endpoints } from '@rossum/api-client';
import { Hook as HookType } from '@rossum/api-client/hooks';
import { QueryClient } from '@tanstack/react-query';
import { QUERY_KEY_HOOKS } from '../../../../../../business/hooks/useHooks';
import { api } from '../../../../../../lib/apiClient';
import convertKeys, { camelToSnake } from '../../../../../../lib/keyConvertor';

export const excludeHookKeysFromConversion = [
  'settings',
  'settings_schema',
  'secrets',
  'secrets_schema',
];

const convertToSnakeCase = (hook: HookType) =>
  convertKeys<HookType>(camelToSnake, excludeHookKeysFromConversion)(hook);

type HookProps = {
  selectedExtension: HookType;
  queryClient?: QueryClient;
};

export class Hook {
  #selectedExtension: HookType;

  #queryClient?: QueryClient;

  constructor({ selectedExtension, queryClient }: HookProps) {
    this.#selectedExtension = selectedExtension;
    this.#queryClient = queryClient;
  }

  get = () => {
    const hook = convertToSnakeCase(this.#selectedExtension);

    return hook;
  };

  getSecretsKeys = () =>
    api.request(endpoints.hooks.getSecretsKeys(this.#selectedExtension.id));

  patchSettingsAndSecrets = (body: {
    settings: Record<string, unknown>;
    secrets: Record<string, unknown>;
  }) =>
    api
      .request(
        endpoints.hooks.patch(this.#selectedExtension.id, {
          settings: body.settings,
          secrets: body.secrets,
          type: this.#selectedExtension.type,
        })
      )
      .then(_extension => {
        this.#queryClient?.resetQueries([QUERY_KEY_HOOKS]);
        this.#queryClient?.invalidateQueries([QUERY_KEY_HOOKS]);
        const snakeCasedExtension = convertToSnakeCase(this.#selectedExtension);
        return snakeCasedExtension;
      });
}
