import { Stack } from '@rossum/ui/material';
import { useIntl } from 'react-intl';

export const ConditionCell = ({ value }: { value: boolean | undefined }) => {
  const intl = useIntl();

  if (value === undefined) {
    return null;
  }

  const conditionMatched = value === true;

  return (
    <Stack direction="row" spacing={1} width="100%">
      {conditionMatched
        ? intl.formatMessage({ id: 'features.formulas.matched' })
        : null}
    </Stack>
  );
};
