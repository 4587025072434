import { Inbox } from '@rossum/api-client/inboxes';
import { camelCase } from 'lodash';
import { combineEpics } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap, pluck } from 'rxjs/operators';
import { apiUrl } from '../../../constants/config';
import { INBOX_QUERY_KEY } from '../../../features/document-upload/hooks/useGetInbox';
import { errorHandler } from '../../../lib/api';
import { generateInboxPrefix } from '../../../lib/helpers';
import { convertKeys } from '../../../lib/keyConvertor';
import { invalidateQueryClientCache } from '../../middlewares/queryClientMiddleware';
import { throwError, throwInfo } from '../messages/actions';
import { organizationSelector } from '../organization/selectors';
import { createQueueFulfilled } from '../queues/actions';
import { isActionOf, makeEpic } from '../utils';
import { setValidationMessagesAction } from '../validationMessages/action';
import {
  createInboxFulfilled,
  updateInboxAction,
  updateInboxFulfilled,
} from './actions';

const createInboxOnQueueEpic = makeEpic(
  (action$, state$, { authPost$, authGetJSON$ }) =>
    action$.pipe(
      filter(isActionOf(createQueueFulfilled)),
      pluck('payload'),
      mergeMap(({ url, name, inbox: inboxUrl }) => {
        // Create an inbox, if it wasn't copied from the template queue
        const organizationName = organizationSelector(state$.value).name;

        if (!organizationName) {
          throw new Error('Organization not loaded before creating an inbox.');
        }

        if (!inboxUrl) {
          return authPost$<Inbox>(`${apiUrl}/inboxes`, {
            name: `${name} inbox`,
            queues: [url],
            emailPrefix: generateInboxPrefix(organizationName),
          }).pipe(
            map(inbox => createInboxFulfilled(inbox, true, url)),
            catchError(errorHandler)
          );
        }

        // Retrieve the existing inbox, which was copied from template queue
        return authGetJSON$<Inbox>(inboxUrl).pipe(
          map(inbox => createInboxFulfilled(inbox, true, url)),
          catchError(errorHandler)
        );
      })
    )
);

const updateInboxEpic = makeEpic((action$, _, { authPatch$ }) =>
  action$.pipe(
    filter(isActionOf(updateInboxAction)),
    mergeMap(({ payload: { inbox }, meta: { inboxUrl } }) =>
      authPatch$<Inbox>(inboxUrl, inbox).pipe(
        mergeMap(response => {
          return of(
            updateInboxFulfilled(response),
            invalidateQueryClientCache([INBOX_QUERY_KEY]),
            throwInfo('inbox.updated')
          );
        }),
        catchError(xhr =>
          xhr.status === 400
            ? of(
                setValidationMessagesAction(
                  convertKeys(camelCase)(xhr.response),
                  'inbox'
                ),
                throwError('inboxUpdatedError')
              )
            : errorHandler(xhr)
        )
      )
    )
  )
);

export default combineEpics(createInboxOnQueueEpic, updateInboxEpic);
