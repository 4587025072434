import { getIDFromUrl } from '@rossum/api-client';
import { Link } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
import { snakeToCamel } from '../../lib/keyConvertor';
import StatusChip from '../../ui/status/StatusChip';
import { TaskProgress } from './components/TaskProgress';
import { ImportTask } from './TaskContext';

type AnnotationProgressProps = {
  importTask: ImportTask;
};

export const AnnotationProgress = ({ importTask }: AnnotationProgressProps) => {
  const intl = useIntl();

  // If we don't know the status yet, let's consider it importing.
  const status = importTask.annotationStatus ?? 'importing';

  return (
    <TaskProgress
      primaryLabel={
        <Link
          component={RouterLink}
          target="_blank"
          sx={{
            color: 'inherit',
            textDecoration: 'none',
            '&:hover': { color: 'inherit' },
          }}
          to={{
            pathname: `/document/${getIDFromUrl(importTask.annotationUrl)}`,
          }}
        >
          {importTask.fileName ?? ''}
        </Link>
      }
      secondaryLabel={
        importTask.queueUrl ? (
          <>
            {intl.formatMessage(
              {
                id: `components.asyncTasksDialog.item.subtitle.upload.succeeded`,
              },
              {
                queueName: (
                  <Link
                    component={RouterLink}
                    sx={{
                      color: theme => theme.palette.secondary.main,
                      textDecoration: 'none',
                      '&:hover': {
                        color: theme => theme.palette.secondary.main,
                      },
                    }}
                    target="_blank"
                    to={{
                      pathname: '/documents',
                      search: `?filtering=${encodeURI(
                        JSON.stringify({
                          items: [
                            {
                              field: 'queue',
                              value: [getIDFromUrl(importTask.queueUrl)],
                              operator: 'isAnyOf',
                            },
                          ],
                          logicOperator: 'and',
                        })
                      )}&level=queue'`,
                    }}
                  >
                    {importTask.queueName}
                  </Link>
                ),
              }
            )}
          </>
        ) : undefined
      }
      progressLabel=""
      progressIcon={
        status !== 'purged' ? (
          <StatusChip
            dataCy="upload-status-chip"
            status={snakeToCamel(status)}
          />
        ) : undefined
      }
    />
  );
};
