import { Close, Search } from '@rossum/ui/icons';
import {
  CircularProgress,
  IconButton,
  TextField,
  TextFieldProps,
} from '@rossum/ui/material';
import { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';

const DEFAULT_WIDTH = 220;

type SearchInputProps = Omit<TextFieldProps, 'onChange'> & {
  value: string | undefined;
  onChange: (value: string) => void;
  loading?: boolean;
  placeholder?: string;
  withStartAdornment?: boolean;
};

const SearchInput = ({
  loading,
  value,
  onChange,
  placeholder,
  withStartAdornment = true,
  InputProps,
  autoFocus,
  ...props
}: SearchInputProps) => {
  const intl = useIntl();
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const inputEl = inputRef.current;

    if (inputEl && autoFocus) {
      setTimeout(() => inputEl.focus(), 0);
    }
  }, [autoFocus]);

  return (
    <TextField
      size="small"
      variant="outlined"
      inputRef={inputRef}
      sx={{
        width: props.fullWidth ? 1 : DEFAULT_WIDTH,
        fieldset: {
          borderColor: theme =>
            value ? `${theme.palette.primary.main} !important` : undefined,
        },
      }}
      inputProps={{ 'data-cy': 'search-input' }}
      InputProps={{
        startAdornment: withStartAdornment ? (
          <Search sx={{ color: theme => theme.palette.text.secondary }} />
        ) : null,
        endAdornment: loading ? (
          <CircularProgress size={22} sx={{ flexShrink: 0 }} color="inherit" />
        ) : value ? (
          <IconButton
            sx={{ color: theme => theme.palette.text.secondary }}
            size="small"
            onClick={() => {
              onChange('');
            }}
          >
            <Close />
          </IconButton>
        ) : undefined,
        ...InputProps,
      }}
      value={value ?? ''}
      placeholder={
        placeholder ??
        intl.formatMessage({
          id: 'components.dashboard.queues.search',
        })
      }
      // Prevent the event from bubbling up to the parent component
      // f.e. in OrganizationSwitcher component are menu items auto focused when
      // pressed keys are matching the first letter of the menu item
      onKeyDown={e => e.stopPropagation()}
      onChange={e => onChange(e.currentTarget.value)}
      {...props}
    />
  );
};

export default SearchInput;
