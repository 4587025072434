import { config } from 'ace-builds';
import AceEditor from 'react-ace'; // MUST be the first import
import 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/ext-searchbox';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/snippets/json?url';
import 'ace-builds/src-noconflict/theme-tomorrow';
import 'ace-builds/src-noconflict/theme-tomorrow_night';
import { alpha, Box, useTheme } from '@rossum/ui/material';
import workerJsonUrl from 'ace-builds/src-min-noconflict/worker-json?url';
import workerPlainTextUrl from 'ace-builds/src-noconflict/mode-plain_text?url';
import { ChangeEvent, LegacyRef } from 'react';
import ReactAce, { IAceEditorProps } from 'react-ace/lib/ace';

config.setModuleUrl('ace/mode/json_worker', workerJsonUrl);
config.setModuleUrl('ace/mode/plain_text', workerPlainTextUrl);

export type Props = {
  debounceChangePeriod: number;
  forwardRef?: LegacyRef<ReactAce>;
  height: number | undefined;
  name: string;
  onChange?: (_value: string, _event?: ChangeEvent) => void;
  onValidate?: IAceEditorProps['onValidate'];
  value: string;
  readonly?: boolean;
  mode?: 'plain_text' | 'json';
};

const JsonEditor = ({
  debounceChangePeriod,
  forwardRef,
  height,
  name,
  onChange,
  onValidate,
  value,
  readonly = false,
  mode = 'json',
}: Props) => {
  const theme = useTheme();
  const isLightTheme = theme.palette.mode === 'light';

  return (
    <Box
      sx={{
        height: height ?? 1,
        '& .ace_editor': {
          backgroundColor: t => t.palette.background.paper,
          border: t => `1px solid ${t.palette.divider}`,
          borderRadius: 1,
          zIndex: 0,
          '.ace_selection': {
            backgroundColor: t => t.palette.action.selected,
          },
          '.ace_variable': {
            color: t => t.palette.text.primary,
          },
          '.ace_line': {
            color: t => t.palette.text.secondary,
          },
          '.ace_string, .ace_constant': {
            color: t => t.palette.other.defaultPrimary,
          },
          '.ace_paren': {
            color: t => t.palette.action.focus,
          },
          '.ace_print-margin': {
            background: t => t.palette.divider,
          },
          '.ace_gutter': {
            background: t => t.palette.divider,
          },
          '.ace_scroller': {
            backgroundColor: t =>
              alpha(t.palette.common.black, isLightTheme ? 0.04 : 0.12),
          },
          '.ace_active-line, .ace_gutter-active-line': {
            background: t => t.palette.action.hover,
          },
          '.ace_scrollbar-h': {
            visibility: 'hidden',
          },
        },
      }}
    >
      <AceEditor
        scrollMargin={[7, 7, 0, 0]}
        name={name}
        mode={mode}
        theme={isLightTheme ? 'tomorrow' : 'tomorrow_night'}
        width="100%"
        height="100%"
        ref={forwardRef}
        onChange={onChange}
        fontSize={13}
        tabSize={2}
        debounceChangePeriod={debounceChangePeriod}
        highlightActiveLine
        value={value}
        editorProps={{
          $blockScrolling: Infinity,
        }}
        setOptions={{
          useWorker: true,
          hScrollBarAlwaysVisible: false,
          enableLiveAutocompletion: false,
          enableSnippets: false,
          enableBasicAutocompletion: true,
        }}
        onValidate={onValidate}
        readOnly={readonly}
      />
    </Box>
  );
};

export default JsonEditor;
