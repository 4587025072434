import { KeyboardArrowDown } from '@rossum/ui/icons';
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  MenuListProps,
  Select,
  Stack,
  Typography,
} from '@rossum/ui/material';
import { ReactNode } from 'react';
import {
  Control,
  FieldError,
  FieldValues,
  Path,
  useController,
} from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { LocalizationKeys } from '../../i18n';

type DropDownOptions<L> = Array<{
  value: L;
  disabled?: boolean;
}>;

type Props<T extends FieldValues, L> = {
  options: DropDownOptions<L>;
  control: Control<T>;
  name: Path<T>;
  placeholderKey: LocalizationKeys;
  getLabel: (value: L) => ReactNode;
  getDescription?: (value: L) => ReactNode;
  withDescription: boolean;
  getErrorMessage?: (_id: string, _errorMessage: FieldError) => string;
  selectDisabled?: boolean;
  dataCy?: string;
  menuListProps?: MenuListProps & { 'data-cy': string };
};

const SelectItems = <T extends FieldValues, L extends string>({
  control,
  name,
  options,
  getErrorMessage,
  getLabel,
  getDescription,
  placeholderKey,
  withDescription,
  selectDisabled,
  menuListProps,
  dataCy,
}: Props<T, L>) => {
  const {
    field: { value: fieldValue, onChange },
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  return (
    <Stack>
      <Box>
        <FormControl fullWidth error={!!error}>
          {!fieldValue && (
            <InputLabel shrink={false} sx={{ mt: -1, pl: 0, opacity: 0.8 }}>
              <FormattedMessage id={placeholderKey} />
            </InputLabel>
          )}
          <Select
            size="small"
            value={fieldValue ?? ''} // empty string is needed for unset option (null or undefined)
            onChange={e =>
              e.target.value !== 'organization_group_admin'
                ? onChange(e)
                : undefined
            }
            disabled={!!selectDisabled}
            renderValue={getLabel}
            IconComponent={KeyboardArrowDown}
            MenuProps={{
              MenuListProps: menuListProps,
              sx: {
                maxHeight: 400,
              },
            }}
            data-cy={dataCy}
          >
            {options.map(({ value, disabled }, index) => (
              <MenuItem
                key={value}
                value={value}
                data-cy={`${dataCy}-option-${index}`}
                disabled={disabled}
                sx={{
                  '& a': {
                    pointerEvents: 'all',
                  },
                }}
              >
                <Box maxWidth={350}>
                  <Typography
                    variant="body1"
                    fontSize={theme => theme.typography.pxToRem(14)}
                  >
                    {getLabel(value)}
                  </Typography>
                  {withDescription && getDescription ? (
                    <Typography
                      variant="body1"
                      fontSize={theme => theme.typography.pxToRem(14)}
                      whiteSpace="pre-wrap"
                      sx={{ color: theme => theme.palette.text.secondary }}
                    >
                      {getDescription(value)}
                    </Typography>
                  ) : null}
                </Box>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      {error && getErrorMessage && (
        <FormHelperText sx={{ mx: '14px' }} error>
          {getErrorMessage(name, error)}
        </FormHelperText>
      )}
    </Stack>
  );
};

export default SelectItems;
