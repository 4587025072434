import {
  CircularProgress,
  Grid2,
  Stack,
  SvgIcon,
  Typography,
} from '@rossum/ui/material';
import { get, kebabCase } from 'lodash';
import { matchSorter } from 'match-sorter';
import WidgetsIcon from 'mdi-react/WidgetsIcon';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHookTemplatesQuery } from '../../../../business/hooks';
import { PageLayoutV2 } from '../../../../components/PageLayoutV2/PageLayoutV2';
import InfoBox from '../../../../components/UI/InfoBox';
import { HeaderTitle } from '../../../../ui/header/Header';
import SearchInput from '../../../../ui/search-input/SearchInput';
import useDebounce from '../../../../utils/hooks/useDebounce';
import Loader from '../../../Loader';
import ExtensionDrawer, {
  ExtendedHookTemplate,
} from '../../components/ExtensionDrawer';
import { ExtensionsHeader } from '../../ExtensionsHeader';
import { RossumStoreTile } from './RossumStoreTile';
import { StoreHints } from './StoreHints';

export const searchInExtensions = <T extends { name: string; tags: string }>(
  searchValue: string,
  results: T[] | undefined
) => {
  if (!results) return [];

  const normalizedSearchValue = searchValue.trim();

  if (!normalizedSearchValue.length) {
    return results;
  }

  return matchSorter(results, normalizedSearchValue, {
    keys: [
      { threshold: matchSorter.rankings.CONTAINS, key: 'name' },
      { threshold: matchSorter.rankings.CONTAINS, key: 'tags' },
    ],
  });
};

const ExtensionsStore = () => {
  const intl = useIntl();
  const [selectedExtension, setSelectedExtension] =
    useState<ExtendedHookTemplate | null>(null);
  const [searchValue, setSearchValue] = useState<string>('');
  const debouncedSearchValue = useDebounce(searchValue, 500);

  const {
    data: results,
    status: extensionTemplatesStatus,
    isRefetching,
  } = useHookTemplatesQuery();

  const extendedResults = useMemo<ExtendedHookTemplate[] | undefined>(
    () =>
      results?.map(r => ({
        ...r,
        tags: r.storeDescription.match(/<p class="tags">(.+)<\/p>/)?.[1] ?? '',
      })),
    [results]
  );

  const extensionTemplateList = useMemo(() => {
    return searchInExtensions(searchValue, extendedResults);
  }, [searchValue, extendedResults]);

  useEffect(() => {
    if (debouncedSearchValue.length >= 3 && Array.isArray(window.dataLayer)) {
      window.dataLayer.push({
        event: 'rossumStoreSearch',
        searchValue: debouncedSearchValue,
      });
    }
  }, [debouncedSearchValue]);

  return (
    <PageLayoutV2
      fullWidth
      renderHeader={params => (
        <ExtensionsHeader
          {...params}
          value="rossumStore"
          title={hasScrolled => (
            <Stack direction="row" alignItems="center" gap={1}>
              <HeaderTitle
                hasScrolled={hasScrolled}
                title={intl.formatMessage({
                  id: `containers.settings.sidebar.rossumStore`,
                })}
              />
              {isRefetching && <CircularProgress size={20} color="inherit" />}
            </Stack>
          )}
        />
      )}
    >
      <Stack spacing={3} p={4} width="100%" maxWidth={1400} mx="auto" flex={1}>
        <InfoBox
          icon={() => (
            <SvgIcon color="inherit">
              <WidgetsIcon />
            </SvgIcon>
          )}
        >
          <Stack direction="row" spacing={0.5}>
            <Typography variant="subtitle2">
              {intl.formatMessage({
                id: 'containers.settings.extensions.store.instorePrebuilt',
              })}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {intl.formatMessage({
                id: 'containers.settings.extensions.store.instoreImprove',
              })}
            </Typography>
          </Stack>
        </InfoBox>
        <SearchInput
          fullWidth
          value={searchValue}
          onChange={value => setSearchValue(value)}
          sx={{ gridColumn: '1/-1' }}
          placeholder={intl.formatMessage({
            id: 'containers.settings.extensions.store.search.placeholder',
          })}
        />
        {extensionTemplatesStatus === 'success' ? (
          <Grid2 container spacing={2.5}>
            {extensionTemplateList.map(extension => {
              const { name, extensionImageUrl, description, type, config } =
                extension;
              return (
                <Grid2 key={name} size={{ xs: 12, sm: 6, md: 4 }}>
                  <RossumStoreTile
                    key={name}
                    name={name}
                    description={description}
                    imageUrl={extensionImageUrl}
                    handleClick={() => setSelectedExtension(extension)}
                    type={type}
                    runtime={get(config, ['runtime'])}
                    dataCy={`extension-store-${kebabCase(name)}`}
                  />
                </Grid2>
              );
            })}
          </Grid2>
        ) : (
          <Stack sx={{ height: '300px' }}>
            <Loader size={90} />
          </Stack>
        )}
        <StoreHints />
      </Stack>
      <ExtensionDrawer
        extension={selectedExtension}
        onClose={() => setSelectedExtension(null)}
      />
    </PageLayoutV2>
  );
};

export default ExtensionsStore;
