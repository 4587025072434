import { withJsonFormsControlProps } from '@jsonforms/react';
import { Paper, Stack } from '@rossum/ui/material';
import DOMPurify from 'dompurify';

export const PromptResultControl = withJsonFormsControlProps(
  ({
    data: { text },
  }: {
    data: {
      text: string;
    };
  }) => {
    const sanitizer = DOMPurify.sanitize;

    return (
      <Paper elevation={8} sx={{ maxWidth: 600 }}>
        <Stack
          px={2}
          py={1}
          mb={2}
          dangerouslySetInnerHTML={{ __html: sanitizer(text) }}
        />
      </Paper>
    );
  }
);
