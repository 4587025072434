import { Url } from '@rossum/api-client';
import { AnnotationStatus } from '@rossum/api-client/shared';
import { SnakeToCamel } from '@rossum/api-client/utils';
import { GridRenderCellParams } from '@rossum/ui/x-data-grid-pro';
import { DocumentStatus } from '../../../components/UI/DocumentStatus';

const RenderStatus = (
  props: GridRenderCellParams<
    {
      modifier:
        | {
            id: number;
            username: string;
          }
        | string;
      id: number;
      url: Url;
      restricted_access?: boolean;
    },
    SnakeToCamel<AnnotationStatus>
  >
) => {
  const {
    value: status,
    row: { modifier, id, url, restricted_access },
  } = props;

  return (
    <DocumentStatus
      annotationId={id}
      annotationUrl={url}
      status={status}
      modifier={modifier}
      disabled={restricted_access}
    />
  );
};

export { RenderStatus };
