import { IconSearch } from '@rossum/ui/icons/tabler';
import { Stack, SvgIcon, Typography } from '@rossum/ui/material';
import { useIntl } from 'react-intl';

export const EmptyQueueList = () => {
  const intl = useIntl();

  return (
    <Stack
      spacing={1}
      sx={{
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <SvgIcon sx={{ fontSize: 60, color: 'text.secondary' }}>
        <IconSearch />
      </SvgIcon>
      <Typography variant="body2">
        {intl.formatMessage({
          id: 'components.selectQueueModal.noqueues.title',
        })}
      </Typography>
      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
        {intl.formatMessage({
          id: 'components.selectQueueModal.noqueues.text',
        })}
      </Typography>
    </Stack>
  );
};
