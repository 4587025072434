import { Url } from '../../types';
import { hookTemplateSchema } from '../models/hookTemplate.schema';

export const get = (hookTemplateUrl: Url) => {
  return {
    endpoint: hookTemplateUrl,
    method: 'GET',
    responseSchema: hookTemplateSchema,
  } as const;
};
