import { OwnPropsOfRenderer } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { Typography } from '@rossum/ui/material';

type TypographyControlProps = {
  data: { text: string };
} & OwnPropsOfRenderer;

export const TypographyControl = withJsonFormsControlProps(
  ({ data: { text }, uischema }: TypographyControlProps) => {
    return <Typography {...uischema?.options}>{text}</Typography>;
  }
);
