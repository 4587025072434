import {
  Icon,
  IconMail,
  IconPlus,
  IconQuestionMark,
} from '@rossum/ui/icons/tabler';
import { Divider, Paper, Stack, SvgIcon } from '@rossum/ui/material';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { FIRST_EXTENSION_LINK } from '../../../../constants/values';
import { boldText, gray } from '../../../../lib/formaterValues/index';
import { extensionsCreatePath, extensionsStorePath } from '../../helpers';

const HintIcon = ({ icon }: { icon: Icon }) => {
  return (
    <Stack component={Paper} elevation={8} p={0.5} mr={1} borderRadius="50%">
      <SvgIcon color="primary" sx={{ fill: 'none' }} component={icon} />
    </Stack>
  );
};

export const StoreHints = () => (
  <Stack component={Paper} alignItems="center" p={3} mt={3}>
    <Stack maxWidth={520} width={1} gap={2} divider={<Divider />}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" alignItems="center">
          <HintIcon icon={IconPlus} />
          <div>
            <FormattedMessage
              id="containers.settings.extensions.store.hints.createYourOwn.text"
              values={{ gray, boldText }}
            />
          </div>
        </Stack>
        <Link
          data-cy="extension-store-create"
          to={{
            pathname: extensionsCreatePath(),
            state: { backLink: extensionsStorePath() },
          }}
        >
          <FormattedMessage id="containers.settings.extensions.store.hints.createYourOwn.link" />
        </Link>
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" alignItems="center">
          <HintIcon icon={IconQuestionMark} />
          <div>
            <FormattedMessage
              id="containers.settings.extensions.store.hints.howTo.text"
              values={{ gray, boldText }}
            />
          </div>
        </Stack>
        <a
          data-cy="extension-store-read-more"
          href={FIRST_EXTENSION_LINK}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FormattedMessage id="containers.settings.extensions.store.hints.howTo.link" />
        </a>
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" alignItems="center">
          <HintIcon icon={IconMail} />
          <div>
            <FormattedMessage
              id="containers.settings.extensions.store.hints.wantSubmit.text"
              values={{ gray, boldText }}
            />
          </div>
        </Stack>
        <a
          href="mailto:product@rossum.ai?subject=Interested in placing my extension to Rossum Store"
          data-cy="extension-store-contact-us"
        >
          <FormattedMessage id="containers.settings.extensions.store.hints.wantSubmit.link" />
        </a>
      </Stack>
    </Stack>
  </Stack>
);
