 
import {
  isSchemaDatapoint,
  isSchemaDatapointButton,
  isSchemaItem,
  isSchemaSection,
  Schema,
  SchemaDatapoint,
  SchemaItem,
  SchemaSection,
} from '@rossum/api-client/schemas';
import * as R from 'remeda';
import { assertNever } from '../../../../../lib/typeUtils';
import { SECTIONS_DROPPABLE_ID } from '../../components/SectionsReordering';
import { getChildListPath, getSchemaObjectPaths } from '../../helpers';
import { toApiModel } from './toApiModel';
import {
  isSchemaItemChildPath,
  isSchemaItemChildrenPath,
  isSchemaItemPath,
  isSchemaItemsPath,
  isSchemaSectionPath,
  isSchemaSectionsPath,
  lineItemChild,
  lineItemChildren,
  logInvalidSchemaOperation,
  performSafeSwap,
  retrieveFromSchema,
  SchemaAction,
  schemaItem,
  schemaItems,
  SchemaListPath,
  SchemaObjectPath,
  schemaSection,
  schemaSections,
} from './toSchema.utils';

export const editItemInSchema = (
  schema: Schema,
  at: SchemaObjectPath,
  editedItem: SchemaSection | SchemaItem
) => {
  if (isSchemaSectionPath(at) && isSchemaSection(editedItem)) {
    return schemaSection(at[0]).set(schema, editedItem);
  }

  if (isSchemaItemPath(at) && isSchemaItem(editedItem)) {
    return schemaItem(at[0], at[2]).set(schema, editedItem);
  }

  if (isSchemaItemChildPath(at) && isSchemaDatapoint(editedItem)) {
    return lineItemChild(at[0], at[2], at[5]).set(schema, editedItem);
  }

  logInvalidSchemaOperation(
    'Invalid edit operation, item created but did not match provided path',
    {
      schema,
      operation: 'edit',
      path: at,
      additionalArgs: { editedItem },
    }
  );

  return schema;
};

export const addItemToSchema = (
  schema: Schema,
  at: SchemaListPath,
  newItem: SchemaSection | SchemaItem
) => {
  if (isSchemaSectionsPath(at) && isSchemaSection(newItem)) {
    return schemaSections().modify(schema, sections => [...sections, newItem]);
  }

  if (isSchemaItemsPath(at) && isSchemaItem(newItem)) {
    return schemaItems(at[0]).modify(schema, items => [...items, newItem]);
  }

  if (isSchemaItemChildrenPath(at) && isSchemaDatapoint(newItem)) {
    return lineItemChildren(at[0], at[2]).modify(schema, children => [
      ...children,
      newItem,
    ]);
  }

  logInvalidSchemaOperation(
    'Invalid add operation, item created but did not match provided path',
    {
      schema,
      operation: 'edit',
      path: at,
      additionalArgs: { newItem },
    }
  );

  return schema;
};

const deleteItemFromSchema = (schema: Schema, at: SchemaObjectPath) => {
  const item = retrieveFromSchema(schema, at);

  if (!item) {
    logInvalidSchemaOperation('Trying to delete a non-existing item', {
      schema,
      operation: 'delete',
      path: at,
    });

    return schema;
  }

  if (isSchemaSectionPath(at)) {
    return schemaSections().modify(schema, sections =>
      sections.filter((_, index) => index !== at[0])
    );
  }

  if (isSchemaItemPath(at)) {
    return schemaItems(at[0]).modify(schema, items =>
      items.filter((_, index) => index !== at[2])
    );
  }

  if (isSchemaItemChildPath(at)) {
    return lineItemChildren(at[0], at[2]).modify(schema, children =>
      children.filter((_, index) => index !== at[5])
    );
  }

  logInvalidSchemaOperation(
    'Invalid delete operation, received an unexpected path',
    {
      schema,
      operation: 'delete',
      path: at,
    }
  );

  return schema;
};

// TODO: Compact this into smaller code
const moveItemInSchema = (
  schema: Schema,
  from: Readonly<SchemaObjectPath>,
  to: Readonly<SchemaObjectPath>
) => {
  // algo: regardless of whether moving between lists, this works:
  // 1) filter out from the from list
  // 2) insert at index in the new list
  // get list by dropping last id (separatly for from and to, as you might move between different lists)

  // item needs to be present
  const fromItem = retrieveFromSchema(schema, from);

  if (!fromItem) {
    logInvalidSchemaOperation('Trying to move a non-existing item', {
      schema,
      operation: 'move',
      path: [],
      additionalArgs: { from, to },
    });

    return schema;
  }

  // repetitive, but type-safe :thinking:
  if (
    isSchemaSectionPath(from) &&
    isSchemaSectionPath(to) &&
    isSchemaSection(fromItem)
  ) {
    return performSafeSwap(
      schema,
      fromItem,
      schemaSections(),
      R.last(from),
      schemaSections(),
      R.last(to)
    );
  }

  if (
    isSchemaItemPath(from) &&
    isSchemaItemPath(to) &&
    isSchemaItem(fromItem)
  ) {
    return performSafeSwap(
      schema,
      fromItem,
      schemaItems(from[0]),
      R.last(from),
      schemaItems(to[0]),
      R.last(to)
    );
  }

  if (
    isSchemaItemChildPath(from) &&
    isSchemaItemChildPath(to) &&
    isSchemaDatapoint(fromItem)
  ) {
    return performSafeSwap(
      schema,
      fromItem,
      lineItemChildren(from[0], from[2]),
      R.last(from),
      lineItemChildren(to[0], to[2]),
      R.last(to)
    );
  }

  logInvalidSchemaOperation('Moving items with different paths or types', {
    schema,
    operation: 'move',
    path: [],
    additionalArgs: { from, to },
  });

  return schema;
};

type QuickPropUpdater = {
  (newValue: boolean): <T extends SchemaDatapoint | SchemaItem>(item: T) => T;
};

const updateHiddenOnItem: QuickPropUpdater = newValue => item => ({
  ...item,
  hidden: newValue,
});

const updateCanExportOnItem: QuickPropUpdater = newValue => item =>
  isSchemaDatapoint(item) && !isSchemaDatapointButton(item)
    ? { ...item, canExport: newValue }
    : item;

const updateRequiredOnItem: QuickPropUpdater = newValue => item =>
  isSchemaDatapoint(item) && !isSchemaDatapointButton(item)
    ? { ...item, constraints: { ...item.constraints, required: newValue } }
    : item;

const updatePropOnItem = (
  prop: 'hidden' | 'canExport' | 'required',
  newValue: boolean
) => {
  switch (prop) {
    case 'hidden':
      return updateHiddenOnItem(newValue);
    case 'canExport':
      return updateCanExportOnItem(newValue);
    case 'required':
      return updateRequiredOnItem(newValue);
    default:
      return assertNever(prop);
  }
};

const performQuickActionOnSchema = (
  schema: Schema,
  listPath: SchemaListPath,
  items: Record<
    string,
    {
      prop: 'hidden' | 'canExport' | 'required';
      value: boolean;
    }
  >
) => {
  if (isSchemaItemsPath(listPath)) {
    return schemaItems(listPath[0]).modify(
      schema,
      R.map(item => {
        const itemUpdate = items[item.id];
        return itemUpdate
          ? updatePropOnItem(itemUpdate.prop, itemUpdate.value)(item)
          : item;
      })
    );
  }

  if (isSchemaItemChildrenPath(listPath)) {
    return lineItemChildren(listPath[0], listPath[2]).modify(
      schema,
      R.map(item => {
        const itemUpdate = items[item.id];
        return itemUpdate
          ? updatePropOnItem(itemUpdate.prop, itemUpdate.value)(item)
          : item;
      })
    );
  }

  return null;
};

// SECTIONS_DROPPABLE_ID is a special case, as it's a top-level list
const getListPathForParentId = (
  parentId: string,
  objectPaths: Record<string, SchemaObjectPath>
): SchemaListPath | null =>
  R.pipe(
    parentId,
    R.conditional(
      [R.isDeepEqual(SECTIONS_DROPPABLE_ID), R.constant([])],
      [
        R.isString,
        R.piped(
          fieldpParentId => objectPaths[fieldpParentId],
          R.conditional(
            [R.isNonNullish, path => getChildListPath(path)],
            R.conditional.defaultCase(R.constant(null))
          )
        ),
      ],
      R.conditional.defaultCase(R.constant(null))
    )
  );
// TODO: This could use a lot more love, it's a bloody mess
export const toSchemaPatchPayload = (
  schema: Schema | undefined,
  action: SchemaAction,
  addSuggest?: boolean
) => {
  if (!schema) {
    return null;
  }

  const schemaObjectPaths = getSchemaObjectPaths(schema);

  switch (action.op) {
    case 'edit': {
      const itemPath = schemaObjectPaths[action.id];

      if (itemPath) {
        const oldItem = retrieveFromSchema(schema, itemPath);

        // wrong edit attempt, fallback to no-op
        if (!oldItem) {
          logInvalidSchemaOperation('Trying to edit a non-existing item', {
            schema,
            operation: 'edit',
            path: itemPath,
            additionalArgs: { editItem: action.formModel },
          });

          return schema;
        }

        const editedItem = toApiModel(oldItem, action.formModel, addSuggest);

        return editItemInSchema(schema, itemPath, editedItem);
      }

      return null;
    }
    case 'add': {
      const parentPath = action.parentId
        ? schemaObjectPaths[action.parentId]
        : null;

      const listPath = parentPath ? getChildListPath(parentPath) : null;

      const newItem = toApiModel(null, action.formModel, addSuggest);

      return addItemToSchema(schema, listPath ?? [], newItem);
    }
    case 'delete': {
      const itemPath = schemaObjectPaths[action.id];

      if (itemPath) {
        return deleteItemFromSchema(schema, itemPath);
      }

      return null;
    }
    case 'quick-action': {
      const parentPath = schemaObjectPaths[action.parentId];

      const listPath = parentPath ? getChildListPath(parentPath) : null;

      if (listPath) {
        return performQuickActionOnSchema(schema, listPath, action.items);
      }

      return null;
    }
    case 'move': {
      const fromList = getListPathForParentId(
        String(action.from[0]),
        schemaObjectPaths
      );

      const toList = getListPathForParentId(
        String(action.to[0]),
        schemaObjectPaths
      );

      if (fromList && toList) {
        const fromPath = [...fromList, action.from[1]] as const;

        const toPath = [...toList, action.to[1]] as const;

        return moveItemInSchema(schema, fromPath, toPath);
      }

      return null;
    }
    default: {
      return assertNever(action);
    }
  }
};
