import { Location } from 'history';
import React, { ReactNode } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Dispatch } from 'redux';
import { MissingToken } from '../../components/MissingToken';
import { isEmbedded } from '../../constants/config';
import { isUserLoggedIn, LocationWithReferrerState } from '../../lib/auth';
import { organizationSelector } from '../../redux/modules/organization/selectors';
import { organizationGroupSelector } from '../../redux/modules/organizationGroup/selectors';
import {
  FetchUser,
  fetchUser as fetchUserAction,
} from '../../redux/modules/user/actions';
import { groupSelector } from '../../redux/modules/user/selectors';
import { State } from '../../types/state';

type OwnProps = { children: ReactNode };

type StateProps = {
  organizationFetched: boolean;
  orgGroupFetched: boolean;
  groupsFetched: boolean;
  userIsFetched: boolean;
  location: Location;
};

type DispatchProps = { fetchUser: FetchUser };

type Props = OwnProps & StateProps & DispatchProps;

class RequireAuth extends React.Component<Props> {
  isSignedIn = isUserLoggedIn(this.props.location as LocationWithReferrerState);

  componentDidMount() {
    const { userIsFetched, fetchUser } = this.props;
    if (this.isSignedIn && !userIsFetched) fetchUser();
  }

  render() {
    const {
      organizationFetched,
      groupsFetched,
      userIsFetched,
      children,
      location,
      orgGroupFetched,
    } = this.props;

    if (
      userIsFetched &&
      organizationFetched &&
      groupsFetched &&
      orgGroupFetched
    ) {
      return children;
    }

    if (!this.isSignedIn) {
      return isEmbedded() ? (
        <MissingToken />
      ) : (
        <Redirect to={{ pathname: '/', state: { referrer: location } }} />
      );
    }

    return null;
  }
}

const mapStateToProps = (state: State) => ({
  organizationFetched: !!organizationSelector(state).id,
  orgGroupFetched: !!organizationGroupSelector(state),
  groupsFetched: !!groupSelector(state).length,
  location: state.router.location,
  userIsFetched: !!state.user.username,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchUser: () => dispatch(fetchUserAction()),
});

export default connect<StateProps, DispatchProps, OwnProps, State>(
  mapStateToProps,
  mapDispatchToProps
)(RequireAuth);
