import { BoxProps, Fade, ListItemText, Typography } from '@rossum/ui/material';
import update from 'immutability-helper';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useDocumentStore } from '../../features/annotation-view/document-store/DocumentStore';
import { gridScaleFactorSelector } from '../../features/annotation-view/document-store/documentStoreSelectors';
import { GRID_LINE_WIDTH } from './constants';
import { useGridContext } from './GridContext';
import { LabelSelectControl } from './LabelSelectControl';
import { SeparatorContainer } from './SeparatorContainer';
import { SeparatorHandle } from './SeparatorHandle';
import { SeparatorLabelContainer } from './SeparatorLabelContainer';
import { SeparatorLine } from './SeparatorLine';
import { useSeparatorState } from './useSeparatorState';
import { isColumnSafe } from './utils';

export type VerticalSeparatorProps = BoxProps & {
  index: number;
  isHovered: boolean;
  onMoved: (separatorIndex: number) => void;
  onDelete: (index: number) => void;
  onSchemaIdChanged: (index: number, schemaId: string | null) => void;
};

export const VerticalSeparator = ({
  index,
  isHovered,
  onMoved,
  onDelete,
  onSchemaIdChanged,
}: VerticalSeparatorProps) => {
  const intl = useIntl();

  const scaleFactor = useDocumentStore(gridScaleFactorSelector);

  const {
    gridState,
    gridDraftState: [draftState],
    interactionInProgress,
    schemaIdOptions,
    gridUiState: [uiState, setUiState],
    gridFocused,
    gridDisabled,
    gridLoading,
  } = useGridContext();

  const currentGridState = draftState ?? gridState;

  const {
    cssPosition: left,
    handleMouseDown,
    isDragging,
    markedForDeletion: [markedForDeletion, setMarkedForDeletion],
    size: columnWidth,
  } = useSeparatorState('vertical', index, onMoved);

  const willBeDeleted = !isColumnSafe(
    currentGridState,
    currentGridState.columns[index]
  );

  // If RIR returned a schemaId that is hidden, dropdown should act as if no schemaId is selected
  const currentSchemaId = schemaIdOptions.some(
    option => option.id === currentGridState.columns[index].schemaId
  )
    ? currentGridState.columns[index].schemaId
    : null;

  const handleSchemaIdChanged = useCallback(
    (schemaId: string | null) => {
      onSchemaIdChanged(index, schemaId);
    },
    [index, onSchemaIdChanged]
  );

  const handleMenuOpen = useCallback(() => {
    setUiState(newUIState =>
      update(newUIState, { activeColumnIndex: { $set: index } })
    );
  }, [index, setUiState]);

  const handleMenuClose = useCallback(() => {
    setUiState(newUIState =>
      update(newUIState, { activeColumnIndex: { $set: null } })
    );
  }, [setUiState]);

  const isActive =
    isDragging || isHovered || uiState.activeColumnIndex === index;

  const isInteractive =
    !gridDisabled &&
    !gridLoading &&
    gridFocused &&
    (!interactionInProgress ||
      uiState.activeColumnIndex === index ||
      isDragging);

  return (
    <SeparatorContainer
      orientation="vertical"
      style={{
        left,
      }}
    >
      <SeparatorLine
        orientation="vertical"
        deleteButtonHovered={markedForDeletion}
        willBeDeletedByResize={willBeDeleted}
        isActive={isActive}
        isDragging={isDragging}
        interactive={isInteractive}
        disabled={gridDisabled || !gridFocused || gridLoading}
        onMouseDownCapture={isInteractive ? handleMouseDown : undefined}
        sx={{ display: index === 0 ? 'none' : 'block' }}
        data-cy={`vs-line-${currentGridState.page}-${index}`}
      />
      {index > 0 ? (
        <SeparatorHandle
          orientation="vertical"
          showButton={markedForDeletion}
          isInteractive={
            !gridDisabled &&
            !gridLoading &&
            gridFocused &&
            !interactionInProgress
          }
          onMouseOver={() => setMarkedForDeletion(true)}
          onMouseOut={() => setMarkedForDeletion(false)}
          onClick={() => {
            onDelete(index);
          }}
        />
      ) : null}
      <SeparatorLabelContainer
        orientation="vertical"
        isActive={isActive}
        gridHovered={uiState.documentHovered}
        interactionInProgress={interactionInProgress}
        size={columnWidth * scaleFactor - GRID_LINE_WIDTH}
      >
        <Fade in={currentSchemaId !== null || isHovered} timeout={150}>
          {/* TODO: forwardRef would be better but the generics make it difficult */}
          <div
            style={{
              maxWidth: '100%',
              width: 'min-content',
              maxHeight: '100%',
              height: 22,
            }}
          >
            <LabelSelectControl
              highlighted={isActive}
              disabled={gridDisabled || gridLoading || !gridFocused}
              options={schemaIdOptions}
              value={currentSchemaId ?? null}
              onChange={handleSchemaIdChanged}
              onMenuOpen={handleMenuOpen}
              onMenuClose={handleMenuClose}
              renderOption={option => (
                <>
                  <ListItemText primaryTypographyProps={{ variant: 'body2' }}>
                    {option.label}
                  </ListItemText>
                  {option.id && (
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        ml: 2,
                        // so the space is always taken
                        visibility: currentGridState.columns.find(
                          col => col.schemaId === option.id
                        )
                          ? 'visible'
                          : 'hidden',
                      }}
                    >
                      {intl.formatMessage({
                        id: 'components.magicGrid.schemaIds.used',
                      })}
                    </Typography>
                  )}
                </>
              )}
              dataCy={`vs-label-${currentGridState.page}-${index}`}
            />
          </div>
        </Fade>
      </SeparatorLabelContainer>
    </SeparatorContainer>
  );
};
