import { LinearProgress } from '@rossum/ui/material';
import clsx from 'clsx';
import deepEqual from 'fast-deep-equal/es6/react';
import { flow, isNumber, round } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Dispatch } from 'redux';
import { fromEvent } from 'rxjs';
import { filter } from 'rxjs/operators';
import { DataCaptureTourDialog } from '../../components/productTour/DataCaptureTourDialog';
import ValidationKeyboardHandler from '../../decorators/ValidationKeyboardHandler';
import { ValidationDocumentDrawer } from '../../features/annotation-view/document-drawer/ValidationDocumentDrawer';
import { DocumentStore } from '../../features/annotation-view/document-store/DocumentStore';
import {
  DocumentContext,
  DocumentContextProvider,
} from '../../features/annotation-view/DocumentContext';
import { DataCaptureSurvey } from '../../features/surveys/DataCaptureSurvey/DataCaptureSurvey';
import { getCurrentAnnotationId, parse } from '../../lib/url';
import { exitAnnotation as exitAnnotationAction } from '../../redux/modules/annotation/actions';
import { annotationSideloadsSelector } from '../../redux/modules/annotation/selectors';
import { closeSelectMenu as closeSelectMenuAction } from '../../redux/modules/ui/actions';
import { ensureArray } from '../../redux/modules/utils';
import { HelmetComponent } from '../../routes/HelmetComponent';
import { ID } from '../../types/basic';
import { State as ReduxState } from '../../types/state';
import { useFeatureFlag } from '../../unleash/useFeatureFlag';
import { DocumentV2 } from '../Document/DocumentV2';
import Sidebar from '../Sidebar';
import { DocumentMetrics } from './DocumentMetrics';
import styles from './style.module.sass';
import { DrawerConfig, ValidationEmailDrawer } from './ValidationEmailDrawer';

type StateProps = {
  currentDatapointPath: Array<number>;
  annotationId: number;
  editModeActive: boolean;
  progressPercentage: number | undefined;
  selectMenuIsOpen: boolean;
  documentName: string | undefined;
};

type OwnProps = RouteComponentProps;

type DispatchProps = {
  closeSelectMenu: () => void;
  exitAnnotation: (annotationId: ID) => void;
};

type DocumentValidationProps = StateProps & OwnProps & DispatchProps;

const DocumentValidation = React.memo(
  ({
    editModeActive,
    progressPercentage,
    selectMenuIsOpen,
    exitAnnotation,
    closeSelectMenu,
    annotationId,
    documentName,
  }: DocumentValidationProps) => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [drawerConfig, setDrawerConfig] = useState<DrawerConfig>({});

    const isNewSidebarEnabled = useFeatureFlag('ac-5499-new-sidebar');

    const displayProgressBar =
      !editModeActive && isNumber(progressPercentage) && !isNewSidebarEnabled;

    useEffect(() => {
      const closeSelectMenuSubscription = fromEvent(
        window.document,
        'mousedown'
      )
        .pipe(
          filter(() => selectMenuIsOpen),
          // FIXME: remove this filter when 'react-select' will be removed from project
          filter(({ srcElement }) => {
            const className = (srcElement as Element).getAttribute('class');

            if (!className) {
              return true;
            }

            return !className.includes('react-select');
          }),
          // avoid to close select when user click inside select
          filter(({ srcElement }) => {
            const className = (srcElement as Element).getAttribute('class');

            if (!className) {
              return true;
            }

            return !className.includes('select_box');
          })
        )
        .subscribe(() => closeSelectMenu());

      return () => closeSelectMenuSubscription.unsubscribe();
    }, [selectMenuIsOpen, closeSelectMenu]);

    useEffect(() => {
      return () => {
        const annotationIdFromUrl = getCurrentAnnotationId(
          window.location.pathname
        );
        const stayingOnSameAnnotation =
          annotationIdFromUrl && annotationIdFromUrl === annotationId;
        // When redirecting to the new edit mode, the component unmounts,
        // but we don't want to exit annotation
        if (!stayingOnSameAnnotation) {
          exitAnnotation(annotationId);
        }
      };
    }, [annotationId, exitAnnotation]);

    // For some god forsaken reason, the old sidebar re-rendered WRONG with outdated props if this function had a stable reference
    // Don't asky me why, let's burn that piece of :poop: to the ground
    const onEmailThreadOpenOld = (newDrawerConfig?: DrawerConfig) => {
      setDrawerOpen(true);
      setDrawerConfig(newDrawerConfig || {});
    };

    // Keep properly memoized version for the new sidebar tho :swag:
    const onEmailThreadOpen = useCallback((newDrawerConfig?: DrawerConfig) => {
      setDrawerOpen(true);
      setDrawerConfig(newDrawerConfig || {});
    }, []);

    const handleDrawerClose = useCallback(() => {
      setDrawerOpen(false);
    }, []);

    return (
      <>
        <HelmetComponent
          dynamicName={documentName}
          translationKey="features.routes.pageTitles.document"
        />

        <DocumentContextProvider>
          <DocumentContext.Consumer>
            {documentContextValue =>
              documentContextValue ? (
                <div
                  className={styles.DocumentValidationWrapper}
                  data-page-title="document-validation"
                >
                  {displayProgressBar && (
                    <LinearProgress
                      variant="determinate"
                      value={progressPercentage}
                      sx={{
                        flex: '0 0 4px',
                        zIndex: 100,
                      }}
                    />
                  )}
                  <div
                    className={clsx(
                      styles.DocumentValidationContainer,
                      displayProgressBar && styles.DocumentValidationIndentation
                    )}
                  >
                    <DocumentStore key={annotationId}>
                      <ValidationDocumentDrawer />
                      <DocumentMetrics />
                      <DataCaptureTourDialog />
                      {!documentContextValue.isNewSidebarEnabled ? (
                        <Sidebar onEmailThreadOpen={onEmailThreadOpenOld} />
                      ) : null}
                      <DocumentV2 onEmailThreadOpen={onEmailThreadOpen} />
                    </DocumentStore>
                  </div>
                </div>
              ) : null
            }
          </DocumentContext.Consumer>
        </DocumentContextProvider>
        {/* The key is important - re-mounts data capture survey to track stuff from scratch. */}
        <DataCaptureSurvey annotationId={annotationId} key={annotationId} />
        <ValidationEmailDrawer
          DrawerProps={{
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
          {...drawerConfig}
        />
      </>
    );
  },
  (prevProps, nextProps) => deepEqual(prevProps, nextProps)
);

const mapStateToProps = (state: ReduxState): StateProps => {
  const {
    stack,
    router: {
      location: { pathname },
    },
  } = state;

  const annotationId = getCurrentAnnotationId(pathname);
  const datapointPath = ensureArray(
    parse(state.router.location.search).datapointPath
  );
  const documentName =
    annotationSideloadsSelector(state).document?.originalFileName ?? undefined;

  return {
    documentName,
    currentDatapointPath: datapointPath,
    annotationId,
    editModeActive: state.ui.editModeActive,
    progressPercentage: stack.includes(annotationId)
      ? round((stack.indexOf(annotationId) / stack.length) * 100)
      : undefined,
    selectMenuIsOpen: state.ui.selectMenuIsOpen,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  closeSelectMenu: () => dispatch(closeSelectMenuAction()),
  exitAnnotation: (id: number) => dispatch(exitAnnotationAction(id)),
});

const decorate = flow(
  connect<StateProps, DispatchProps, OwnProps, ReduxState>(
    mapStateToProps,
    mapDispatchToProps
  ),
  ValidationKeyboardHandler,
  withRouter
);

export default decorate(DocumentValidation);
