import { SortableContext } from '@dnd-kit/sortable';
import { Chip, Stack, Typography } from '@rossum/ui/material';
import { forwardRef, ReactNode } from 'react';
import { useIntl } from 'react-intl';
import SortableWrapper, {
  DragHandleProps,
} from '../../components/Dnd/SortableWrapper';
import { snakeToCamel } from '../../lib/keyConvertor';
import StatusChip from '../../ui/status/StatusChip';
import { EditedPage } from './EditedPage';
import { EditedPartActions } from './EditedPartActions';
import {
  DispatchEdit,
  EditDocumentConfig,
  hasBeenEdited,
  isEditable,
  Page,
  Part,
} from './editState';
import { FilenameWorkspaceQueueInfo } from './FilenameWorkspaceQueueInfo';
import { UiStateTuple } from './uiState';

const SortablePages = ({
  dragAndDropEnabled,
  pages,
  render,
}: {
  dragAndDropEnabled: boolean;
  pages: Page[];
  render: (
    page: Page,
    index: number,
    dragHandleProps?: DragHandleProps
  ) => ReactNode;
}) => {
  if (dragAndDropEnabled) {
    return (
      <SortableContext items={pages.map(p => ({ id: String(p.pageNumber) }))}>
        {pages.map((page, i) => (
          <SortableWrapper
            key={page.pageNumber}
            id={String(page.pageNumber)}
            render={dragHandleProps => render(page, i, dragHandleProps)}
          />
        ))}
      </SortableContext>
    );
  }

  return <>{pages.map((page, i) => render(page, i))}</>;
};

export const EditedPart = forwardRef<
  HTMLDivElement,
  {
    part: Part;
    index: number;
    dispatchEdit: DispatchEdit;
    isFollowedByEditablePart: boolean;
    uiState: UiStateTuple;
    config: EditDocumentConfig;
    suggestedSplitsActive: boolean;
  }
>(
  (
    {
      part,
      dispatchEdit,
      index,
      isFollowedByEditablePart,
      uiState: [uiState, setUiState],
      config,
      suggestedSplitsActive,
    },
    ref
  ) => {
    const intl = useIntl();
    const { pages } = part;

    const canBeEditedByUser = isEditable(part);
    const editable = canBeEditedByUser && !uiState.confirmInProgress;

    const edited = hasBeenEdited(part, config);

    return (
      <Stack
        spacing={2}
        sx={{
          scrollMargin: '32px',
        }}
        ref={ref}
      >
        <Stack
          direction="row"
          sx={{
            justifyContent: 'space-between',
          }}
        >
          <Stack spacing={0.5}>
            <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
              {/* lineHeight ensures that showing a chip won't increase the height of the Stack  */}
              <Typography variant="h6" sx={{ lineHeight: 1.5 }}>
                {intl.formatMessage(
                  { id: 'containers.editDocument.part.title' },
                  {
                    partNumber: index + 1,
                  }
                )}
              </Typography>
              {part.annotationType === 'accessible' &&
                // TODO remove this in type definition, we won't receive purged annotations
                part.annotation.status !== 'purged' &&
                // Every started annotation would show reviewing status,
                // so we don't need to show it
                !part.annotation.started && (
                  <StatusChip status={snakeToCamel(part.annotation.status)} />
                )}
              {!canBeEditedByUser ? (
                <Chip
                  label={intl.formatMessage({
                    id: 'containers.editDocument.part.editDisabledChip',
                  })}
                  sx={{ px: 0.25 }}
                  color="warning"
                />
              ) : suggestedSplitsActive ? (
                <Chip
                  label={intl.formatMessage({
                    id: 'containers.editDocument.part.splitSuggestionChip',
                  })}
                  sx={{ px: 0.25 }}
                  color="info"
                />
              ) : edited ? (
                <Chip
                  label={intl.formatMessage({
                    id: 'containers.editDocument.part.editedChip',
                  })}
                  sx={{ px: 0.25 }}
                  color="info"
                />
              ) : null}
            </Stack>
            <FilenameWorkspaceQueueInfo
              filename={
                // Don't show filename for edited parts,
                // since the filename will change after confirmation,
                // so it could be confusing.
                part.annotationType !== 'none' && !edited
                  ? part.annotation.filename
                  : null
              }
              queueUrl={
                part.annotationType !== 'inaccessible'
                  ? part.targetQueueUrl
                  : null
              }
            />
          </Stack>
          <EditedPartActions
            part={part}
            dispatchEdit={dispatchEdit}
            index={index}
          />
        </Stack>
        <Stack direction="row" sx={{ flexWrap: 'wrap' }}>
          <SortablePages
            pages={pages}
            dragAndDropEnabled={editable}
            render={(page, i, dragHandleProps) => (
              <EditedPage
                page={page}
                followingPageNumber={pages[i + 1]?.pageNumber}
                isLastPage={i === pages.length - 1}
                isFollowedByEditablePart={isFollowedByEditablePart}
                partIndex={index}
                dragHandleAttributes={dragHandleProps?.attributes}
                dragHandleListeners={dragHandleProps?.listeners}
                dispatchEdit={dispatchEdit}
                uiState={[uiState, setUiState]}
                mode={
                  page.pageNumber === uiState.draggedPage?.number
                    ? 'placeholder-for-dragged-page'
                    : 'not-dragged-page'
                }
                editable={editable}
                config={config}
              />
            )}
          />
        </Stack>
      </Stack>
    );
  }
);

EditedPart.displayName = 'EditedPart';
