import { yupResolver } from '@hookform/resolvers/yup';
import {
  ExtensionEvent,
  extensionFunctionType,
  extensionWebhookType,
  HookType,
} from '@rossum/api-client/hooks';
import { Button, CircularProgress, Paper, Stack } from '@rossum/ui/material';
import { useState } from 'react';
import { useController, useForm } from 'react-hook-form';
import { IntlShape, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { useCreateHook } from '../../../../../business/hooks/useCreateHook';
import { PageLayoutV2 } from '../../../../../components/PageLayoutV2/PageLayoutV2';
import { UnpaidFeatureOverlay } from '../../../../../features/pricing/components/UnpaidFeatureOverlay';
import { useExtensionsFeatureSubscription } from '../../../../../features/pricing/hooks/useExtensionsFeatureSubscription';
import { Users } from '../../../../../types/users';
import { Header } from '../../../../../ui/header/Header';
import {
  defaultCode,
  defaultNodejsRuntime,
  defaultPythonRuntime,
} from '../../../../Extension/config';
import { webhookUrlValidation } from '../../../../Extension/helpers';
import { ExtensionsBreadcrumbs } from '../../../ExtensionsBreadcrumbs';
import {
  extensionCodePath,
  extensionDetailPath,
  myExtensionsPath,
} from '../../../helpers';
import { validateCronString } from '../../../lib/helpers';
import { useJSONfield } from '../../../lib/useJSONfield';
import AdvancedSettings from './AdvancedSettings';
import {
  GeneralCreateSettings,
  NewExtensionData,
} from './GeneralCreateSettings';

const CREATE_EXTENSION_FORM_ID = 'create-extension-form';

type Props = {
  backLink?: string;
  defaultExtensionType: HookType | undefined;
  organizationUsers: Users;
};

const createExtensionValidationSchema = (intl: IntlShape) =>
  yup.object().shape({
    name: yup.string().required(
      intl.formatMessage({
        id: 'containers.settings.extensions.createExtension.name.required',
      })
    ),
    description: yup
      .string()
      .max(
        1000,
        intl.formatMessage({
          id: 'containers.settings.extensions.createExtension.description.tooLong',
        })
      )
      .notRequired(),
    events: yup
      .array()
      .min(1)
      .required(
        intl.formatMessage({
          id: 'containers.settings.extensions.createExtension.events.required',
        })
      ),
    schedule: yup
      .string()
      .when('events', (events: ExtensionEvent[], schema: yup.StringSchema) =>
        events.includes('invocation.scheduled')
          ? schema.test(
              'wrongFormat',
              intl.formatMessage({
                id: 'containers.settings.extensions.createExtension.schedule.wrongFormat',
              }),
              validateCronString
            )
          : schema
      ),
    queues: yup.array(),
    type: yup.string().required('required'),
    runtime: yup.string().when('type', {
      is: extensionFunctionType,
      then: yup.string().required(),
      otherwise: yup.string().nullable().notRequired(),
    }),
    url: yup.string().when('type', {
      is: extensionWebhookType,
      then: webhookUrlValidation(intl),
      otherwise: yup.string().nullable().notRequired(),
    }),
    secret: yup.string().nullable().notRequired(),
    sideload: yup.array(),
    tokenOwner: yup.string().nullable(),
    settings: yup.object(),
  });

const CreateExtension = ({
  defaultExtensionType,
  organizationUsers,
}: Props) => {
  const intl = useIntl();
  const history = useHistory<{ backLink?: string } | undefined>();

  const { mutate, isLoading: submitting } = useCreateHook();
  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);

  const { purchased: isFeatureEnabled } = useExtensionsFeatureSubscription();

  const defaultFormExtensionType =
    defaultExtensionType ?? extensionFunctionType;

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
    setError,
    trigger,
  } = useForm<NewExtensionData>({
    mode: 'onChange',
    defaultValues: {
      name: '',
      description: '',
      events: [],
      queues: [],
      sideload: [],
      tokenOwner: null,
      settings: {},
      secrets: {},
      type: defaultFormExtensionType,
      runtime: defaultPythonRuntime,
      url: '',
      secret: '',
      schedule: '',
    },

    resolver: yupResolver(createExtensionValidationSchema(intl)),
  });

  const {
    field: { value: watchType },
  } = useController({ name: 'type', control });

  const onExtensionUpdate = (data: NewExtensionData) => {
    switch (data.type) {
      case extensionWebhookType:
        return onSubmitWebhook(data);
      default:
        return onSubmitFunction(data);
    }
  };

  const onSubmitFunction = (data: NewExtensionData) => {
    const shouldUseDefaultSchemaSideload =
      data.sideload.length === 0 &&
      data.events.some(event => event.startsWith('annotation_content.'));

    mutate(
      {
        type: extensionFunctionType,
        name: data.name,
        description: data.description,
        config: {
          code: data.runtime
            ? defaultCode[data.runtime]
            : defaultCode[defaultNodejsRuntime],
          runtime: data.runtime || defaultNodejsRuntime,
          schedule: { cron: data.schedule },
          payloadLoggingEnabled: !!data.payloadLoggingEnabled,
        },
        events: data.events,
        queues: data.queues,
        sideload: shouldUseDefaultSchemaSideload ? ['schemas'] : data.sideload,
        tokenOwner: data.tokenOwner,
        settings: data.settings,
        secrets: data.secrets,
      },
      {
        onSuccess: hook => {
          history.push({
            pathname:
              hook.type === 'function'
                ? extensionCodePath(hook.id)
                : extensionDetailPath(hook.id),
          });
        },
      }
    );
  };

  const onSubmitWebhook = (data: NewExtensionData) => {
    mutate({
      type: extensionWebhookType,
      name: data.name,
      description: data.description,
      config: {
        url: data.url,
        secret: data.secret || null,
        schedule: { cron: data.schedule },
        payloadLoggingEnabled: !!data.payloadLoggingEnabled,
      },
      events: data.events,
      queues: data.queues,
      sideload: data.sideload,
      tokenOwner: data.tokenOwner,
      settings: data.settings,
      secrets: data.secrets,
    });
  };

  const settingsJSONField = useJSONfield<NewExtensionData>({
    name: 'settings',
    trigger,
    setError,
    control,
  });

  const secretsJSONField = useJSONfield<NewExtensionData>({
    name: 'secrets',
    trigger,
    setError,
    control,
  });

  const returnLink = history.location?.state?.backLink || myExtensionsPath();

  return (
    <PageLayoutV2
      renderHeader={params => (
        <Header
          {...params}
          title={intl.formatMessage({
            id: `containers.settings.extensions.createExtension`,
          })}
          onBackButtonClicked={() => {
            history.push(returnLink);
          }}
          description={null}
          breadcrumbs={
            <ExtensionsBreadcrumbs
              breadcrumbs={[
                {
                  label: intl.formatMessage({
                    id: `containers.settings.extensions.createExtension`,
                  }),
                },
              ]}
            />
          }
          buttons={[
            <Button
              key="create-extension"
              variant="contained"
              type="submit"
              form={CREATE_EXTENSION_FORM_ID}
              startIcon={
                submitting ? (
                  <CircularProgress color="inherit" size={14} />
                ) : null
              }
              data-cy="extensions-confirm-button"
              disabled={!isValid || submitting}
            >
              {intl.formatMessage({
                id: `containers.settings.extensions.createExtension.button.${watchType === extensionFunctionType ? 'createFunction' : 'createWebhook'}`,
              })}
            </Button>,
          ]}
        />
      )}
    >
      {isFeatureEnabled ? (
        <Stack py={4}>
          <Paper
            sx={{ px: 30, py: 4 }}
            component="form"
            id={CREATE_EXTENSION_FORM_ID}
            onSubmit={handleSubmit(onExtensionUpdate)}
          >
            <Stack>
              <GeneralCreateSettings control={control} errors={errors} />
              <AdvancedSettings<NewExtensionData>
                allOrgUsers={organizationUsers.list}
                control={control}
                users={organizationUsers.list}
                settingsJSONField={settingsJSONField}
                secretsJSONField={secretsJSONField}
                setShowAdvancedSettings={setShowAdvancedSettings}
                showAdvancedSettings={showAdvancedSettings}
                showSecretsSection
              />
            </Stack>
          </Paper>
        </Stack>
      ) : (
        <Stack component={Paper} m={4} p={4} justifyContent="center">
          <UnpaidFeatureOverlay
            title={intl.formatMessage({
              id: 'features.pricing.unpaidFeatureOverlay.title.extensionsCreate',
            })}
            dataCy="unpaid-overlay-create-new-extension"
          />
        </Stack>
      )}
    </PageLayoutV2>
  );
};

export default CreateExtension;
