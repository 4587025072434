 
import { Ace } from 'ace-builds';
import { config, Draft, Draft2019, JsonError } from 'json-schema-library';
import { unique } from 'remeda';
import helpers from './schemaValidationHelpers';

// TODO: Should not be handled globaly
config.strings.TypeError = '{{value}} must be a {{expected}}';
config.strings.NoAdditionalPropertiesError =
  '{{value}} should NOT have additional property: {{property}}';
config.strings.EnumError = '"{{value}}" should be equal to one of: {{values}}';

export const JSON_FIELD_ANNOTATION_SOURCE = 'jsonFieldEditor';

export const getSchemaValidationAnnotations = ({
  schema,
  valueAsObject,
  valueAsString,
}: {
  schema?: object;
  valueAsObject: object;
  valueAsString: string;
}) => {
  const jsonSchema: Draft = new Draft2019(schema);
  const errors: JsonError[] = jsonSchema.validate(valueAsObject);

  if (!schema) {
    return [];
  }

  if (jsonSchema.isValid(valueAsObject)) {
    return [];
  }

  const errorLocations = helpers.getLocationsForPaths(
    valueAsString,
    unique(errors.map(err => err.data.pointer.replace('#', '')))
  );

  return errorLocations
    .map(errorLocation => {
      const validationErrors = errors.filter(
        e => e.data.pointer.replace('#', '') === errorLocation.path
      );

      const message = validationErrors.map(({ message }) => message).join('\n');
      if (message) {
        return {
          row: errorLocation.line,
          column: errorLocation.column,
          // I would go with hardcoded English only for now
          text: `Schema validation error${
            validationErrors.length !== 1 ? 's' : ''
          }: \n${message}`,
          type: 'error',
          source: JSON_FIELD_ANNOTATION_SOURCE,
        };
      }

      return null;
    })
    .filter(
      (m): m is Required<Ace.Annotation> & { source: string } => m !== null
    );
};
