import { camelCase } from 'lodash';
import { Reducer } from 'redux';
import * as R from 'remeda';
import { getType } from 'typesafe-actions';
import { defaultWorkspaceSorting } from '../../../constants/values';
import { convertKeys } from '../../../lib/keyConvertor';
import { User } from '../../../types/user';
import { RootActionType } from '../../rootActions';
import { fetchMembershipTokenFulfilled, signOut } from '../auth/actions';
import { fetchUserFulfilled, updateUiSettings, updateUser } from './actions';
import { excludeUserUISettingsKeysFromConversion } from './epics';

const initialState: User = {
  authType: 'password',
  dateJoined: '',
  email: '',
  firstName: '',
  groups: [],
  id: -1,
  isActive: false,
  lastLogin: '',
  lastName: '',
  oidcId: undefined,
  organization: '',
  queues: [],
  uiSettings: {
    dashboard: {
      workspacesSorting: defaultWorkspaceSorting,
    },
    productTours: {},
    showConfidenceScore: false,
  },
  url: '',
  username: '',
  deleted: false,
  phoneNumber: null,
  emailVerified: false,
};

const reducer: Reducer<typeof initialState, RootActionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case getType(fetchUserFulfilled):
      return R.mergeDeep(state, action.payload);

    case getType(fetchMembershipTokenFulfilled):
    case getType(signOut):
      return initialState;

    case getType(updateUser):
      return R.mergeDeep(state, convertKeys(camelCase)(action.payload));

    case getType(updateUiSettings):
      return {
        ...state,
        uiSettings: R.mergeDeep(
          state.uiSettings,
          convertKeys(
            camelCase,
            excludeUserUISettingsKeysFromConversion
          )(action.payload)
        ),
      };

    default:
      return state;
  }
};

export default reducer;
