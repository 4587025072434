import { useState } from 'react';
import ReduxModal from '../../containers/Modals';
import { ConfirmTypeT, ModalKey } from '../../containers/Modals/types';

type OpenModalParams = {
  confirmType?: ConfirmTypeT;
  textId: ModalKey;
  values?: Record<string, unknown>;
  onConfirm: () => void;
};

/**
 * Replacement for openModal redux action, which uses MUI Dialog.
 * @returns
 */
export const useOpenModal = () => {
  const [open, setOpen] = useState(false);
  const [params, setParams] = useState<OpenModalParams>();

  const handleCancel = () => setOpen(false);
  const handleConfirm = () => {
    setOpen(false);
    params?.onConfirm();
  };

  const DialogElement = params ? (
    <ReduxModal
      isOpen={open}
      onCancel={handleCancel}
      onConfirm={handleConfirm}
      textId={params.textId}
      values={params.values}
      confirmType={params.confirmType}
    />
  ) : null;

  return [
    DialogElement,
    (newParams: OpenModalParams) => {
      setOpen(true);
      setParams(newParams);
    },
  ] as const;
};
