import { yupResolver } from '@hookform/resolvers/yup';
import { User } from '@rossum/api-client/users';
import { PersonAddAlt } from '@rossum/ui/icons';
import {
  Button,
  CircularProgress,
  DialogContent,
  FormLabel,
  Stack,
  Typography,
} from '@rossum/ui/material';
import { get } from 'lodash';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'redux-first-history';
import * as yup from 'yup';
import { useCreateUser } from '../../../../business/users/useCreateUser';
import SingleCheckboxControl from '../../../../components/ReactHookForm/controls/SingleCheckboxControl';
import TextFieldControl from '../../../../components/ReactHookForm/controls/TextFieldControl';
import { approvalWorkflowsFeatureSelector } from '../../../../features/pricing/selectors';
import {
  getRoleName,
  getRoleOptions,
} from '../../../../redux/modules/groups/helpers';
import { throwInfo } from '../../../../redux/modules/messages/actions';
import { splitFullName } from '../../../../redux/modules/user/helpers';
import { fetchAdminUsers } from '../../../../redux/modules/users/actions';
import { State } from '../../../../types/state';
import RoleSelect from '../../../User/components/RoleSelect';
import RoleTooltip from '../../../User/components/RoleTooltip';
import { userDetailsAccessPath } from '../../helpers';

type AddUserDialogContentProps = {
  onCancel: () => void;
};

type AddUserFormState = {
  name: string;
  email: string;
  role: string;
  assignQueueCheckbox: boolean;
  canApproveCheckbox: boolean;
};

const AddUserDialogContent = ({ onCancel }: AddUserDialogContentProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const enableApproverRole = useSelector(approvalWorkflowsFeatureSelector);

  const roleOptions = useSelector((state: State) =>
    getRoleOptions(state.groups, intl)
  );

  const roleOptionsWithFlag = roleOptions.filter(role =>
    enableApproverRole ? role : role.name !== 'approver'
  );

  const roles = useSelector((state: State) => state.groups);

  const defaultValues: AddUserFormState = {
    name: '',
    email: '',
    role: '',
    assignQueueCheckbox: false,
    canApproveCheckbox: false,
  };

  const addUserValidationFormSchema = yup.object().shape({
    name: yup.string().required(
      intl.formatMessage({
        id: 'containers.settings.users.addUser.name.error',
      })
    ),
    email: yup
      .string()
      .required(
        intl.formatMessage({
          id: 'containers.settings.users.addUser.email.required',
        })
      )
      .email(
        intl.formatMessage({
          id: 'containers.settings.users.addUser.email.error',
        })
      ),
    role: yup.string().required(
      intl.formatMessage({
        id: 'containers.settings.users.addUser.role.error',
      })
    ),
    assignQueueCheckbox: yup.boolean().optional(),
    canApproveCheckbox: yup.boolean().optional(),
  });

  const { handleSubmit, control, setError, watch } = useForm({
    mode: 'onSubmit',
    defaultValues,
    resolver: yupResolver(addUserValidationFormSchema),
  });

  const watchValues = watch();

  const handleOnSuccess = (user: User) => {
    const { id, groups } = user;

    dispatch(throwInfo('userCreated'));
    dispatch(push(userDetailsAccessPath(id)));

    if (getRoleName(groups, roles) === 'admin') {
      dispatch(fetchAdminUsers());
    }
  };

  const { mutate, isLoading, error, apiErrorMessage } = useCreateUser(
    handleOnSuccess,
    'welcome'
  );

  useEffect(() => {
    if (error || apiErrorMessage) {
      const message =
        apiErrorMessage ??
        intl.formatMessage({
          id: 'containers.settings.users.addUser.email.apiError',
        });

      setError('email', { message });
    }
  }, [error, apiErrorMessage, intl, setError]);

  return (
    <DialogContent sx={{ px: 6, my: 4, pb: 0, overflowY: 'visible' }}>
      <form
        onSubmit={handleSubmit(
          ({ name, email, role, assignQueueCheckbox, canApproveCheckbox }) => {
            const { firstName, lastName } = splitFullName(name);

            return mutate({
              firstName,
              lastName,
              email,
              role: canApproveCheckbox
                ? [
                    get(
                      roleOptions.find(option => option.name === role),
                      'value',
                      ''
                    ),
                    get(
                      roleOptions.find(option => option.name === 'approver'),
                      'value',
                      ''
                    ),
                  ]
                : [
                    get(
                      roleOptions.find(option => option.name === role),
                      'value',
                      ''
                    ),
                  ],
              addQueues: assignQueueCheckbox,
            });
          }
        )}
      >
        <Stack spacing={2}>
          <Stack spacing={2}>
            <TextFieldControl
              type="string"
              ControllerProps={{ control, name: 'name' }}
              label={intl.formatMessage({
                id: 'containers.settings.users.addUser.name.label',
              })}
              placeholder={intl.formatMessage({
                id: 'containers.settings.users.addUser.name.placeholder',
              })}
              inputProps={{
                'data-cy': 'add-user-modal-name-input',
              }}
            />
            <TextFieldControl
              type="string"
              ControllerProps={{ control, name: 'email' }}
              label={intl.formatMessage({
                id: 'containers.settings.users.addUser.email.label',
              })}
              placeholder={intl.formatMessage({
                id: 'containers.settings.users.addUser.email.placeholder',
              })}
              inputProps={{
                'data-cy': 'add-user-modal-email-input',
              }}
            />
            <Stack>
              <FormLabel sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  fontWeight="bold"
                  color="text.primary"
                  sx={{ mr: 1 }}
                >
                  {intl.formatMessage({
                    id: 'containers.settings.users.addUser.role.label',
                  })}
                </Typography>
                <RoleTooltip
                  roles={roleOptionsWithFlag.map(({ name }) => name)}
                />
              </FormLabel>
              <RoleSelect<AddUserFormState>
                name="role"
                control={control}
                roleOptions={roleOptionsWithFlag}
                getErrorMessage={(_, fieldError) => fieldError.message || ''}
              />
            </Stack>
            <Stack>
              {!['admin', 'approver'].includes(watchValues.role) &&
                enableApproverRole && (
                  <SingleCheckboxControl
                    ControllerProps={{ control, name: 'canApproveCheckbox' }}
                    FieldLabelProps={{ layout: 'none' }}
                    label={intl.formatMessage({
                      id: 'containers.settings.users.addUser.canApprove',
                    })}
                    data-cy="add-user-modal-can-approve-checkbox"
                  />
                )}
              <SingleCheckboxControl
                ControllerProps={{ control, name: 'assignQueueCheckbox' }}
                FieldLabelProps={{ layout: 'none' }}
                label={intl.formatMessage({
                  id: 'containers.settings.users.addUser.addToQueues',
                })}
                data-cy="add-user-modal-assigne-queue-checkbox"
              />
            </Stack>
          </Stack>
          <Stack direction="row" spacing={2} justifyContent="center">
            <Button
              variant="outlined"
              onClick={() => onCancel()}
              color="secondary"
              data-cy="add-user-modal-cancel-button"
            >
              {intl.formatMessage({
                id: 'containers.settings.users.addUser.cancel',
              })}
            </Button>
            <Button
              type="submit"
              variant="contained"
              startIcon={
                isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  <PersonAddAlt />
                )
              }
              data-cy="add-user-modal-submit-button"
            >
              {intl.formatMessage({
                id: 'containers.settings.sidebar.users.addUser',
              })}
            </Button>
          </Stack>
        </Stack>
      </form>
    </DialogContent>
  );
};

export default AddUserDialogContent;
