import { SectionData } from '../../../annotations/models/annotationData';
import { SchemaSection } from '../../../schemas/models/schemaSection';
import { Message } from '../../../shared/models/message';
import { SchemaAction, SchemaRule } from '../../../rules/models/schemaRule';
import {
  evaluateRulesResponseSchema,
  evaluateRulesPayloadSchema,
} from './evaluateRules.schema';

export type EvaluateRulesPayload = {
  schemaContent: SchemaSection[];
  annotationContent: Array<SectionData>;
  schemaRules: Array<Partial<SchemaRule>>;
};

export type EvaluateRulesResponse = {
  conditionValues: Array<boolean | Array<boolean>>;
  messages: Array<Message>;
  actions: Array<SchemaAction>;
};

export const evaluateRules = (payload: EvaluateRulesPayload) => {
  return {
    endpoint: `internal/rules/evaluate_rules`,
    method: 'POST',
    responseSchema: evaluateRulesResponseSchema,
    payloadSchema: evaluateRulesPayloadSchema,
    payload,
  } as const;
};
