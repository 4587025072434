import { endpoints } from '@rossum/api-client';
import { HooksListQuery } from '@rossum/api-client/hooks';
import { useQuery } from '@tanstack/react-query';
import { api } from '../../lib/apiClient';

export const QUERY_KEY_HOOKS = 'hooks';

export const useHooks = (
  query: HooksListQuery = {},
  { enabled }: { enabled?: boolean } = {}
) => {
  return useQuery({
    queryKey: [QUERY_KEY_HOOKS, 'list', query] as const,
    queryFn: ({ queryKey }) => api.request(endpoints.hooks.list(queryKey[2])),
    keepPreviousData: true,
    enabled,
  });
};
