import { Button, Stack, Typography } from '@rossum/ui/material';
import { ExtensionInteropUi } from '../../features/extension-interop/ExtensionInteropUi';
import { useProcessIntent } from '../../features/extension-interop/hooks/useProcessIntent';

export const TestIntent = () => {
  const { processIntent } = useProcessIntent();

  return (
    <Stack
      mt={2}
      p={3}
      alignItems="center"
      justifyContent="center"
      gap={3}
      m="auto"
      textAlign="center"
    >
      <ExtensionInteropUi launchHooks={false} />

      <Stack alignItems="center" justifyContent="center" gap={2}>
        <Typography variant="h5">Testing intent</Typography>
        <Typography variant="body1">
          You are testing a front-end integration.
        </Typography>

        <Stack direction="row" spacing={1}>
          <Button
            id="intent-cancel"
            type="button"
            variant="outlined"
            color="secondary"
          >
            Close
          </Button>

          <Button
            variant="contained"
            color="primary"
            id="intent-submit"
            type="button"
            onClick={() => {
              if ('__intent' in window) {
                processIntent(window.__intent);
              }
            }}
          >
            Invoke
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};
