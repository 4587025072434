import {
  FormControl,
  FormGroup,
  FormLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@rossum/ui/material';
import { useCallback } from 'react';
import { Control, useController } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { FormValues, sidebarNavigationOptions } from './formValues';

type NavigationControlProps = {
  control: Control<FormValues>;
};

export const NavigationControl = ({ control }: NavigationControlProps) => {
  const intl = useIntl();

  const {
    field: { disabled, value, onChange },
  } = useController({ name: 'navigation', control });

  const handleChange = useCallback(
    (e: SelectChangeEvent) => {
      onChange(e.target.value);
    },
    [onChange]
  );

  return (
    <FormControl>
      <FormLabel>
        <Typography
          variant="h6"
          color={disabled ? 'text.disabled' : 'text.primary'}
        >
          {intl.formatMessage({
            id: 'components.lineItemsSettings.sidebarNavigation.title',
          })}
        </Typography>
      </FormLabel>
      <FormGroup>
        <Select
          size="small"
          fullWidth
          value={value}
          renderValue={selectValue =>
            intl.formatMessage({
              id: `components.lineItemsSettings.${selectValue}.title`,
            })
          }
          onChange={handleChange}
        >
          {sidebarNavigationOptions.map(option => (
            <MenuItem key={option} value={option}>
              <Stack pr={2}>
                <Typography variant="body2" pt={1}>
                  {intl.formatMessage({
                    id: `components.lineItemsSettings.${option}.title`,
                  })}
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  {intl.formatMessage({
                    id: `components.lineItemsSettings.${option}.description`,
                  })}
                </Typography>
              </Stack>
            </MenuItem>
          ))}
        </Select>
      </FormGroup>
    </FormControl>
  );
};
