import { Organization } from '@rossum/api-client/organization';
import { IconBuilding, IconTestPipe2 } from '@rossum/ui/icons/tabler';
import {
  Box,
  Divider,
  Grow,
  MenuItem,
  Paper,
  Stack,
  SvgIcon,
  Typography,
} from '@rossum/ui/material';
import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import { partition } from 'remeda';
import { switchOrganization } from '../../../redux/modules/auth/actions';
import SearchInput from '../../../ui/search-input/SearchInput';
import { filterUserOrganizations } from '../helpers';

type OrganizationItemProps = {
  currentOrganization: Organization;
  organization: Organization;
  style?: React.CSSProperties;
};

const OrganizationItem = ({
  organization,
  currentOrganization,
  style,
}: OrganizationItemProps) => {
  const dispatch = useDispatch();
  return (
    <MenuItem
      sx={{
        py: 1,
        backgroundColor: t =>
          organization.id === currentOrganization.id
            ? t.palette.action.hover
            : 'transparent',
        ...style,
      }}
      onClick={() =>
        dispatch(switchOrganization(organization.url, organization.name))
      }
      data-cy="organizations-list-item"
    >
      <Stack direction="row" alignItems="center" spacing={1.5} width={1}>
        <SvgIcon
          sx={{ fill: 'none' }}
          color={
            organization.id === currentOrganization.id ? 'success' : 'action'
          }
          fontSize="small"
        >
          {organization.sandbox ? <IconTestPipe2 /> : <IconBuilding />}
        </SvgIcon>
        <Stack
          width={1}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography
            variant="body2"
            sx={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              maxWidth: 250,
            }}
          >
            {organization.name}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {`ID ${organization.id}`}
          </Typography>
        </Stack>
      </Stack>
    </MenuItem>
  );
};

const VirtualizedOrganizationItem = ({
  data: { organizations, currentOrganization },
  index,
  style,
}: ListChildComponentProps<{
  organizations: Organization[];
  currentOrganization: Organization;
}>) => {
  const item = organizations[index];

  return (
    <OrganizationItem
      key={item.id}
      currentOrganization={currentOrganization}
      organization={item}
      style={style}
    />
  );
};

const MENU_WIDTH = 400;
const MENU_HEIGHT = 350;

type OrganizationsMenuProps = {
  isMenuOpen: boolean;
  currentOrganization: Organization;
  organizations: Organization[];
};

export const OrganizationsMenu = ({
  isMenuOpen,
  currentOrganization,
  organizations,
}: OrganizationsMenuProps) => {
  const intl = useIntl();
  const [searchValue, setSearchValue] = useState<string>('');

  const [filteredOrganizations, setFilteredOrganizations] =
    useState(organizations);

  const [sandboxOrganizations, prodOrganizations] = useMemo(
    () => partition(filteredOrganizations ?? [], org => org.sandbox),
    [filteredOrganizations]
  );

  const onValueChange = (value: string) => {
    setFilteredOrganizations(
      filterUserOrganizations(value, organizations ?? [])
    );
    return setSearchValue(value);
  };

  const truncateSearchQuery = useMemo(
    () =>
      searchValue.length > 20 ? `${searchValue.slice(0, 20)}...` : searchValue,
    [searchValue]
  );

  return (
    <Grow unmountOnExit in={isMenuOpen}>
      <Box
        sx={{
          position: 'absolute',
          top: '0',
          left: -MENU_WIDTH,
          width: MENU_WIDTH,
        }}
      >
        <Paper elevation={8} sx={{ borderRadius: 1 }}>
          <Stack>
            <Stack p={2} spacing={0.5}>
              <SearchInput
                onChange={onValueChange}
                value={searchValue}
                sx={{ width: '100%' }}
              />
              <Grow in={searchValue.length > 0} unmountOnExit>
                <Typography color="text.secondary" variant="caption">
                  {intl.formatMessage(
                    { id: 'features.userPanel.organizations.results' },
                    { searchQuery: truncateSearchQuery }
                  )}
                </Typography>
              </Grow>
            </Stack>
            {sandboxOrganizations.length > 0 || prodOrganizations.length > 0 ? (
              <Stack>
                {sandboxOrganizations.map(so => (
                  <OrganizationItem
                    key={so.id}
                    currentOrganization={currentOrganization}
                    organization={so}
                  />
                ))}
                {sandboxOrganizations.length > 0 ? (
                  <Box>
                    <Divider />
                  </Box>
                ) : null}
                <FixedSizeList
                  itemData={{
                    organizations: prodOrganizations,
                    currentOrganization,
                  }}
                  height={MENU_HEIGHT}
                  width="100%"
                  itemCount={prodOrganizations.length}
                  itemSize={36}
                >
                  {VirtualizedOrganizationItem}
                </FixedSizeList>
              </Stack>
            ) : (
              <Stack alignItems="center" pb={5} pt={3} px={4} spacing={1}>
                <Typography
                  color="text.secondary"
                  variant="h6"
                  textAlign="center"
                >
                  {intl.formatMessage(
                    { id: 'features.userPanel.organizations.noResults' },
                    { searchQuery: truncateSearchQuery }
                  )}
                </Typography>
                <Typography
                  color="text.secondary"
                  variant="body2"
                  textAlign="center"
                >
                  {intl.formatMessage({
                    id: 'features.userPanel.organizations.noResultsDescription',
                  })}
                </Typography>
              </Stack>
            )}
          </Stack>
        </Paper>
      </Box>
    </Grow>
  );
};
