import { Control, FieldError, FieldValues, Path } from 'react-hook-form';
import { useIntl } from 'react-intl';
import SelectItems from '../../../components/ReactHookForm/SelectItems';
import { docsLinks } from '../../../constants/values';
import { link } from '../../../lib/formaterValues';
import { GroupRole } from '../../../types/group';

export type RoleOptions = Array<{
  label: string;
  name: GroupRole;
  value: string;
}>;

type RoleSelectProps<T extends FieldValues> = {
  control: Control<T>;
  roleOptions: RoleOptions;
  name: Path<T>;
  getErrorMessage: (_id: string, _errorMessage: FieldError) => string;
  disabled?: boolean;
};

const RoleSelect = <T extends FieldValues>({
  control,
  roleOptions,
  name,
  getErrorMessage,
  disabled,
}: RoleSelectProps<T>) => {
  const intl = useIntl();

  const options = roleOptions.map(roleOption => ({
    value: roleOption.name as GroupRole,
    disabled: roleOption.name === 'organization_group_admin',
  }));

  return (
    <SelectItems<T, GroupRole>
      withDescription
      name={name}
      control={control}
      options={options}
      getLabel={value =>
        intl.formatMessage({
          id: `containers.settings.users.roles.${value}`,
        })
      }
      getDescription={value =>
        intl.formatMessage(
          {
            id: `containers.settings.users.roles.${value}.description`,
          },
          {
            link:
              value === 'organization_group_admin' && link(docsLinks.userRole),
          }
        )
      }
      placeholderKey="containers.settings.users.addUser.role.placeholder"
      getErrorMessage={getErrorMessage}
      dataCy="user-role-select"
      menuListProps={{
        'data-cy': 'dropdown-items-shown',
      }}
      selectDisabled={disabled}
    />
  );
};

export default RoleSelect;
