import { useEffect, useRef } from 'react';
import { fromEvent } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import styles from '../style.module.sass';

type Props = {
  startDrag: () => void;
  endDrag: () => void;
};

const DragHandler = ({ startDrag, endDrag }: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref.current) return undefined;

    const dragStart$ = fromEvent<MouseEvent>(ref.current, 'mousedown');
    const dragEnd$ = dragStart$.pipe(
      switchMap(() => fromEvent<MouseEvent>(document, 'mouseup').pipe(first()))
    );

    const dragStartSubscription = dragStart$.subscribe(() => startDrag());

    const dragEndSubscription = dragEnd$.subscribe(() => endDrag());

    return () => {
      dragStartSubscription.unsubscribe();
      dragEndSubscription.unsubscribe();
    };
     
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div className={styles.DragHandler} ref={ref} />;
};

export default DragHandler;
