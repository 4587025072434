import { Stack, Typography } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { HelpPanel } from '../../features/help-panel';

export const MissingToken = () => {
  const intl = useIntl();

  return (
    <Stack
      direction="column"
      sx={{
        position: 'absolute',
        border: theme => `1px solid ${theme.palette.text.secondary}`,
        alignItems: 'center',
        justifyContent: 'space-between',
        color: 'text.secondary',
        p: 6,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
      spacing={3}
    >
      <Typography variant="h3">
        {intl.formatMessage({ id: 'components.missingtoken.title' })}
      </Typography>
      <Typography variant="body1">
        {intl.formatMessage({ id: 'components.missingtoken.text' })}
      </Typography>
      <HelpPanel />
    </Stack>
  );
};
