import { Close } from '@rossum/ui/icons';
import { Box, IconButton, Stack, Typography } from '@rossum/ui/material';
import { ReactNode } from 'react';
import { Route, useRouteMatch } from 'react-router';
import { fullscreenConfigAppPath } from '../helpers';

type Props = {
  children: ReactNode;
  name: string;
  handleClose: () => void;
};

const ConfigAppRoute = ({ handleClose, name, children }: Props) => {
  const match = useRouteMatch();

  return (
    <Route path={`${match.path}${fullscreenConfigAppPath}`}>
      <Stack
        sx={{
          position: 'fixed',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          zIndex: theme => theme.zIndex.drawer,
          background: theme => theme.palette.background.paper,
        }}
      >
        <Stack
          spacing={1}
          direction="row"
          sx={{
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            px: 2,
            py: 1,
          }}
        >
          <Typography variant="h6">{name}</Typography>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Stack>
        <Box sx={{ height: '100%', position: 'relative' }}>{children}</Box>
      </Stack>
    </Route>
  );
};

export default ConfigAppRoute;
