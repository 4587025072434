import { OwnPropsOfRenderer } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { Box } from '@rossum/ui/material';
import { DataGridPro, GridColDef } from '@rossum/ui/x-data-grid-pro';
import { useMemo } from 'react';
import { commonDataGridStyles } from '../../../ui/data-grid/styles';

type TableControlProps = {
  data: Array<Record<string, string>>;
} & OwnPropsOfRenderer;

export const TableControl = withJsonFormsControlProps(
  ({ data, uischema }: TableControlProps) => {
    const columns = useMemo<GridColDef[]>(() => {
      const first = data[0];
      return first
        ? Object.keys(first).map(key => ({ field: key, flex: 1 }))
        : [];
    }, [data]);

    return (
      <Box
        sx={{
          position: 'relative',
          height: 600,
          width: 1,
          py: 1,
          px: 2,
        }}
      >
        <DataGridPro
          columns={columns}
          rows={data}
          sx={{
            ...commonDataGridStyles,
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            ...uischema?.options?.sx,
          }}
          disableColumnSelector
          disableColumnPinning
          {...uischema?.options}
        />
      </Box>
    );
  }
);
