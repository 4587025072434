import { HooksListQuery } from '@rossum/api-client/hooks';
import { HookTemplate } from '@rossum/api-client/hookTemplates';
import { useHooks } from '../../../business/hooks/useHooks';

const resolveState = ({
  isInstallable,
  isFetching,
  isAlreadyInstalled,
}: {
  isInstallable: boolean;
  isFetching: boolean;
  isAlreadyInstalled: boolean;
}) => {
  if (isInstallable) {
    if (isFetching) {
      return 'installable-checking' as const;
    }
    return isAlreadyInstalled
      ? ('installable-open' as const)
      : ('installable-install' as const);
  }

  return 'not-installable' as const;
};

export const useCheckIfAlreadyInstalled = (
  hookTemplate: HookTemplate | null
) => {
  // let's assume 'fullscreen' apps as installable for now,
  // in future it would be handy to have a separate hook.type or hook_template.install_action
  const isInstallable = hookTemplate?.config.app?.displayMode === 'fullscreen';

  const configAppUrl = hookTemplate?.config.app?.url;

  const enabled = !!configAppUrl && isInstallable;

  const hookListQuery: HooksListQuery | undefined = enabled
    ? { configAppUrl, pageSize: 1 }
    : undefined;

  const { data, isFetching } = useHooks(hookListQuery, { enabled });

  const installedHookId: number | undefined = isFetching
    ? undefined
    : data?.results[0]?.id;

  const hookTemplateStatus = resolveState({
    isInstallable,
    isFetching,
    isAlreadyInstalled: !!installedHookId,
  });

  return {
    installedHookId,
    hookTemplateStatus,
  };
};
