import type { FieldLabelProps } from '@rossum/ui';
import { FieldLabel } from '@rossum/ui';
import { alpha, TextField, TextFieldProps } from '@rossum/ui/material';
import {
  ControllerFieldState,
  ControllerRenderProps,
  FieldPath,
  FieldValues,
  UseFormStateReturn,
} from 'react-hook-form';
import { LabeledFieldProps } from '../utils';

export type CustomTextFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = TextFieldProps &
  LabeledFieldProps & {
    FieldProps: {
      field: ControllerRenderProps<TFieldValues, TName>;
      fieldState: ControllerFieldState;
      formState: UseFormStateReturn<TFieldValues>;
    };
  };

export const CustomTextField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  FieldProps,
  FieldLabelProps,
  ...textFieldProps
}: CustomTextFieldProps<TFieldValues, TName>) => {
  const {
    helperText,
    id,
    label,
    disabled,
    SelectProps,
    InputProps,
    ...restTextFieldProps
  } = textFieldProps;

  const resolvedFieldLabelProps: FieldLabelProps = {
    htmlFor: id ?? FieldProps.field.name,
    required: restTextFieldProps.required,
    label,
    ...FieldLabelProps,
  };

  const {
    field: { ref, ...field },
    fieldState,
    formState,
  } = FieldProps;

  return (
    <FieldLabel {...resolvedFieldLabelProps}>
      <TextField
        {...field}
        value={field.value}
        inputRef={ref}
        id={id ?? field.name}
        size="small"
        error={fieldState.invalid}
        label={
          resolvedFieldLabelProps.layout === 'floating'
            ? resolvedFieldLabelProps.label
            : undefined
        }
        helperText={
          fieldState.invalid && fieldState.error
            ? fieldState.error.message
            : helperText ?? ''
        }
        disabled={disabled || formState.isSubmitting}
        SelectProps={
          SelectProps
            ? {
                ...SelectProps,
                renderValue:
                  field.value.length === 0
                    ? () => ''
                    : SelectProps.renderValue
                      ? SelectProps.renderValue
                      : undefined,
              }
            : undefined
        }
        InputProps={{
          ...InputProps,
          sx: {
            ...InputProps?.sx,
            input: {
              '&:-webkit-autofill': {
                WebkitBackgroundClip: 'text',
                WebkitBoxShadow: t =>
                  `0 0 0 100px ${alpha(t.palette.primary.main, 0.2)} inset`,
              },
            },
          },
        }}
        {...restTextFieldProps}
      />
    </FieldLabel>
  );
};
