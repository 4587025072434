import { WarningAmber } from '@rossum/ui/icons';
import { CircularProgress } from '@rossum/ui/material';
import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { snakeToCamel } from '../../../../../lib/keyConvertor';
import { confirmAnnotation } from '../../../../../redux/modules/annotation/actions';
import { queueHasWorkflowsSelector } from '../../../../../redux/modules/annotation/selectors';
import { pauseValidation } from '../../../../../redux/modules/beforeLeave/actions';
import {
  datapointMessagesSelector,
  datapointPathSelector,
  isDeleteRecommendationInPathSelector,
  validationInProgressSelector,
} from '../../../../../redux/modules/datapoints/selector';
import { openModal } from '../../../../../redux/modules/modal/actions';
import { Annotation } from '../../../../../types/annotation';
import { State } from '../../../../../types/state';

export const useConfirmButton = (annotation: Annotation) => {
  const intl = useIntl();

  const dispatch = useDispatch();

  const isConfirming = useSelector((state: State) => state.ui.confirming);

  const isReadOnly = useSelector((state: State) => state.ui.readOnly);

  const failedExport = snakeToCamel(annotation.status) === 'failedExport';

  const validationInProgress = useSelector(validationInProgressSelector);

  const queueHasWorkflows = useSelector(queueHasWorkflowsSelector);

  const datapointMessages = useSelector(datapointMessagesSelector);

  const datapointsAreValid = !datapointMessages.error;

  const currentDatapointPath = useSelector(datapointPathSelector);

  const hasDeleteRecommendationInPath = useSelector(
    isDeleteRecommendationInPathSelector
  );

  const confirmButtonDisabled =
    isConfirming ||
    isReadOnly ||
    validationInProgress ||
    failedExport ||
    !datapointsAreValid;

  const confirmButtonStartIcon = useMemo(
    () =>
      isConfirming || (validationInProgress && !isReadOnly) ? (
        <CircularProgress size={16} color="inherit" />
      ) : null,
    [isConfirming, validationInProgress, isReadOnly]
  );

  const confirmButtonFocused =
    datapointsAreValid &&
    !currentDatapointPath.length &&
    !hasDeleteRecommendationInPath;

  const confirmButtonLabel = useMemo(
    () =>
      isConfirming ? (
        ''
      ) : failedExport ? (
        <WarningAmber />
      ) : isReadOnly ? (
        intl.formatMessage({
          id: 'components.documentValidation.sidebar.readOnlyDocument',
        })
      ) : validationInProgress ? (
        intl.formatMessage({
          id: 'components.documentValidation.sidebar.validating',
        })
      ) : (
        intl.formatMessage({
          id: queueHasWorkflows
            ? 'components.documentValidation.sidebar.submitDocument'
            : 'components.documentValidation.sidebar.confirmDocument',
        })
      ),
    [
      failedExport,
      intl,
      isConfirming,
      isReadOnly,
      queueHasWorkflows,
      validationInProgress,
    ]
  );

  const handleConfirmClick = useCallback(() => {
    dispatch(
      pauseValidation({
        nextAction: confirmAnnotation(annotation.url),
        trigger: 'confirmAnnotation',
        reason: 'surveys',
      })
    );
  }, [annotation.url, dispatch]);

  const handleConfirmKeyDown: React.KeyboardEventHandler<HTMLButtonElement> =
    useCallback(
      e => {
        if (e.key === 'Enter') {
          e.preventDefault();
          e.stopPropagation();
          dispatch(
            openModal({
              textId: 'annotationConfirm',
              onConfirm: handleConfirmClick,
            })
          );
        }
      },
      [dispatch, handleConfirmClick]
    );

  const result = useMemo(
    () => ({
      disabled: confirmButtonDisabled,
      focused: confirmButtonFocused,
      label: confirmButtonLabel,
      startIcon: confirmButtonStartIcon,
      onClick: handleConfirmClick,
      onKeyDown: handleConfirmKeyDown,
      datapointMessages,
    }),
    [
      datapointMessages,
      confirmButtonDisabled,
      confirmButtonFocused,
      confirmButtonLabel,
      confirmButtonStartIcon,
      handleConfirmClick,
      handleConfirmKeyDown,
    ]
  );

  return result;
};
