import { Url } from '../../utils/codecUtils';
import { hookSchema } from '../models/hook.schema';
import { ExtensionEvent } from '../models/hookUtils';
import { createHookFromTemplatePayloadSchema } from './createFromTemplate.schema';

export type CreateHookFromTemplatePayload = {
  name: string;
  hookTemplate: Url;
  events: ExtensionEvent[];
  queues: Url[];
  tokenOwner: Url | null;
};

export const createFromTemplate = (payload: CreateHookFromTemplatePayload) => {
  return {
    endpoint: `/hooks/create`,
    method: 'POST',
    responseSchema: hookSchema,
    payload,
    payloadSchema: createHookFromTemplatePayloadSchema,
  } as const;
};
