import { snakeToCamel } from '@rossum/api-client/utils';
import {
  IconCalendar,
  IconCircleDot,
  IconCirclePlus,
  IconFolder,
  IconFolders,
  IconSTurnRight,
  IconTag,
} from '@rossum/ui/icons/tabler';
import {
  Button,
  Collapse,
  Popover,
  Stack,
  SvgIcon,
  Typography,
} from '@rossum/ui/material';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'redux-first-history';
import { getAnnotationBacklink } from '../../../../../components/AnnotationInformation/components/useAnnotationBacklink';
import { useShowWorkflowActivity } from '../../../../../components/AnnotationInformation/components/useShowWorkflowActivity';
import { DocumentStatus } from '../../../../../components/UI/DocumentStatus';
import { isEmbedded } from '../../../../../constants/config';
import { refetchAnnotation } from '../../../../../redux/modules/annotation/actions';
import { annotationSideloadsSelector } from '../../../../../redux/modules/annotation/selectors';
import { pauseValidation } from '../../../../../redux/modules/beforeLeave/actions';
import { leaveValidation } from '../../../../../redux/modules/ui/actions';
import { Annotation } from '../../../../../types/annotation';
import {
  AnnotationsLabelsList,
  OnLabelAction,
} from '../../../../labels/AnnotationsLabelsList';
import { useRequestLabelsApply } from '../../../../labels/hooks/useRequestLabelsApply';
import { labelsFeatureSelector } from '../../../../pricing/selectors';
import { notify } from '../../../../toaster';
import { useDocumentStore } from '../../../document-store/DocumentStore';
import { SidebarItemLabel } from '../../shared/SidebarItemLabel';
import { SidebarItemLayout } from '../../shared/SidebarItemLayout';
import { SidebarSectionTitle } from '../../shared/SidebarSectionTitle';
import { VerticalContainer } from '../../shared/VerticalContainer';
import { Messages } from '../../sidebar-items/shared/Messages';
import { groupMessagesByType } from '../automation-blockers/helpers';
import { DateDetail } from './DateDetail';
import { LabelsList } from './LabelsList';

type AnnotationDataProps = {
  annotation: Annotation | undefined;
};

const AnnotationData = React.memo(({ annotation }: AnnotationDataProps) => {
  const intl = useIntl();

  const annotationDataVisible = useDocumentStore(
    state => state.sidebarState.annotationDataVisible
  );

  const containerRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    if (containerRef.current && annotationDataVisible) {
      containerRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [annotationDataVisible]);

  // TODO: Why do we keep needing to use selectors and why are sideloads spearated in types when they are in redux :thinking:
  const annotationSideloads = useSelector(annotationSideloadsSelector);

  const modifier = annotationSideloads.modifier?.user?.email ?? '-';

  // for compatibility with a component displaying the chip
  const documentStatusModifier = annotationSideloads.modifier?.user
    ? {
        id: annotationSideloads.modifier.user.id,
        username: annotationSideloads.modifier.user.email ?? '-',
      }
    : undefined;

  // Confirmed/exported by
  const { confirmedBy, exportedBy } = annotationSideloads;

  // Workflows
  const showWorkflowActivity = useShowWorkflowActivity(annotation?.id);

  const openDrawer = useDocumentStore(state => state.openDrawer);

  // Labels
  const labelsEnabled = useSelector(labelsFeatureSelector);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const openLabelsPopover = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    []
  );

  const closeLabelsPopover = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const { mutate: onApplyLabel } = useRequestLabelsApply();

  const dispatch = useDispatch();

  const handleLabelsChanged: OnLabelAction = useCallback(
    ({ payload }) => {
      onApplyLabel(payload, {
        onSuccess: () => {
          if (annotation) {
            dispatch(refetchAnnotation(annotation.url));
          }

          notify.success({
            title: intl.formatMessage({
              id: 'components.sidebarV2.annotationData.labelsList.apply.success',
            }),
          });

          closeLabelsPopover();
        },
        onError: () => {
          notify.error({
            title: intl.formatMessage({
              id: 'components.sidebarV2.annotationData.labelsList.apply.error',
            }),
          });
        },
      });
    },
    [annotation, closeLabelsPopover, dispatch, intl, onApplyLabel]
  );

  const handleLabelDelete = useCallback(
    (labelUrl: string) => {
      if (annotation) {
        handleLabelsChanged({
          payload: {
            operations: {
              remove: [labelUrl],
            },
            objects: {
              annotations: [annotation?.url],
            },
          },
        });
      }
    },
    [annotation, handleLabelsChanged]
  );

  const annotationMessages = useMemo(() => {
    const { errorMessages, warningMessages, infoMessages } =
      groupMessagesByType(annotation?.messages);
    return [...errorMessages, ...warningMessages, ...infoMessages];
  }, [annotation?.messages]);

  // TODO: Change how this works when deleting old sidebar
  const leaveAnnotation = useCallback(() => {
    if (isEmbedded()) {
      dispatch(leaveValidation());
    } else {
      const backArrowLink = getAnnotationBacklink();
      dispatch(
        pauseValidation({
          nextAction: push(backArrowLink),
          trigger: 'cancelAnnotation',
          reason: 'surveys',
        })
      );
    }
  }, [dispatch]);

  return (
    <Collapse in={annotationDataVisible} ref={containerRef}>
      <SidebarSectionTitle
        title={intl.formatMessage({
          id: 'components.sidebarV2.annotationData.title',
        })}
      />
      <Stack sx={{ pl: 2, pr: 1 }} color="text.secondary">
        {!isEmbedded() ? (
          <>
            <SidebarItemLayout
              iconSlot={
                <SvgIcon
                  fontSize="small"
                  sx={{
                    display: 'block',
                  }}
                >
                  <IconFolders />
                </SvgIcon>
              }
              labelSlot={
                <SidebarItemLabel
                  label={intl.formatMessage({
                    id: 'components.sidebarV2.annotationData.labels.workspace',
                  })}
                />
              }
              valueSlot={
                <VerticalContainer>
                  <Typography variant="body2" color="text.secondary">
                    {annotationSideloads.workspace?.name}
                  </Typography>
                </VerticalContainer>
              }
            />
            <SidebarItemLayout
              iconSlot={
                <SvgIcon
                  fontSize="small"
                  sx={{
                    display: 'block',
                  }}
                >
                  <IconFolder />
                </SvgIcon>
              }
              labelSlot={
                <SidebarItemLabel
                  label={intl.formatMessage({
                    id: 'components.sidebarV2.annotationData.labels.queue',
                  })}
                />
              }
              valueSlot={
                <VerticalContainer>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ cursor: 'pointer' }}
                    onClick={leaveAnnotation}
                  >
                    {annotationSideloads.queue?.name}
                  </Typography>
                </VerticalContainer>
              }
            />
          </>
        ) : null}
        {annotation && annotationSideloads ? (
          <SidebarItemLayout
            iconSlot={
              <SvgIcon
                fontSize="small"
                sx={{
                  display: 'block',
                }}
              >
                <IconCircleDot />
              </SvgIcon>
            }
            labelSlot={
              <SidebarItemLabel
                label={intl.formatMessage({
                  id: 'components.sidebarV2.annotationData.labels.status',
                })}
              />
            }
            valueSlot={
              <VerticalContainer>
                <span>
                  <DocumentStatus
                    annotationId={annotation.id}
                    annotationUrl={annotation.url}
                    status={snakeToCamel(annotation.status)}
                    modifier={documentStatusModifier}
                    disabled={annotation.restrictedAccess}
                    showSplit
                  />
                </span>
              </VerticalContainer>
            }
          />
        ) : null}
        <SidebarItemLayout
          iconSlot={
            <SvgIcon
              fontSize="small"
              sx={{
                display: 'block',
              }}
            >
              <IconCalendar />
            </SvgIcon>
          }
          labelSlot={
            <SidebarItemLabel
              label={intl.formatMessage({
                id: 'components.sidebarV2.annotationData.labels.created',
              })}
            />
          }
          valueSlot={
            <VerticalContainer>
              <DateDetail dateString={annotation?.createdAt} />
            </VerticalContainer>
          }
        />
        <SidebarItemLayout
          iconSlot={
            <SvgIcon
              fontSize="small"
              sx={{
                display: 'block',
              }}
            >
              <IconCalendar />
            </SvgIcon>
          }
          labelSlot={
            <SidebarItemLabel
              label={intl.formatMessage({
                id: 'components.sidebarV2.annotationData.labels.edited',
              })}
            />
          }
          valueSlot={
            <VerticalContainer>
              <DateDetail dateString={annotation?.modifiedAt} />
              <Typography variant="caption" color="text.secondary">
                {modifier}
              </Typography>
            </VerticalContainer>
          }
        />
        {annotation?.confirmedAt ? (
          <SidebarItemLayout
            iconSlot={
              <SvgIcon
                fontSize="small"
                sx={{
                  display: 'block',
                }}
              >
                <IconCalendar />
              </SvgIcon>
            }
            labelSlot={
              <SidebarItemLabel
                label={intl.formatMessage({
                  id: 'components.sidebarV2.annotationData.labels.confirmed',
                })}
              />
            }
            valueSlot={
              <VerticalContainer>
                <DateDetail dateString={annotation?.confirmedAt} />
                <Typography variant="caption" color="text.secondary">
                  {confirmedBy?.email ?? '-'}
                </Typography>
              </VerticalContainer>
            }
          />
        ) : null}
        {annotation?.exportedAt ? (
          <SidebarItemLayout
            iconSlot={
              <SvgIcon
                fontSize="small"
                sx={{
                  display: 'block',
                }}
              >
                <IconCalendar />
              </SvgIcon>
            }
            labelSlot={
              <SidebarItemLabel
                label={intl.formatMessage({
                  id: 'components.sidebarV2.annotationData.labels.exported',
                })}
              />
            }
            valueSlot={
              <VerticalContainer>
                <DateDetail dateString={annotation?.exportedAt} />
                <Typography variant="caption" color="text.secondary">
                  {exportedBy?.email ?? '-'}
                </Typography>
              </VerticalContainer>
            }
          />
        ) : null}
        {showWorkflowActivity ? (
          <SidebarItemLayout
            iconSlot={
              <SvgIcon fontSize="small" sx={{ display: 'block' }}>
                <IconSTurnRight />
              </SvgIcon>
            }
            labelSlot={
              <SidebarItemLabel
                label={intl.formatMessage({
                  id: 'components.sidebarV2.annotationData.labels.workflows',
                })}
              />
            }
            valueSlot={
              <VerticalContainer>
                <Button
                  variant="text"
                  size="small"
                  color="secondary"
                  sx={{
                    alignSelf: 'flex-start',
                    // TODO: @ui put this into theme?
                    minWidth: 'unset',
                    px: 0.5,
                    py: 0.5,
                  }}
                  onClick={
                    annotation
                      ? () =>
                          openDrawer({
                            drawer: 'activities',
                            annotationId: annotation.id,
                          })
                      : undefined
                  }
                >
                  {intl.formatMessage({
                    id: 'components.sidebarV2.annotationData.buttons.open',
                  })}
                </Button>
              </VerticalContainer>
            }
          />
        ) : null}
        {labelsEnabled ? (
          <SidebarItemLayout
            iconSlot={
              <SvgIcon
                fontSize="small"
                sx={{
                  display: 'block',
                }}
              >
                <IconTag />
              </SvgIcon>
            }
            labelSlot={
              <SidebarItemLabel
                label={intl.formatMessage({
                  id: 'components.sidebarV2.annotationData.labels.labels',
                })}
              />
            }
            valueSlot={
              <VerticalContainer spacing={1}>
                <LabelsList
                  labels={annotationSideloads.labels}
                  onLabelDelete={handleLabelDelete}
                />
                <Button
                  variant="text"
                  size="small"
                  color="secondary"
                  startIcon={
                    <SvgIcon fontSize="small">
                      <IconCirclePlus />
                    </SvgIcon>
                  }
                  sx={{
                    alignSelf: 'flex-start',
                  }}
                  onClick={openLabelsPopover}
                >
                  {intl.formatMessage({
                    id: 'components.sidebarV2.annotationData.labels.labels.addLabel',
                  })}
                </Button>
                <Popover
                  open={!!anchorEl}
                  onClose={closeLabelsPopover}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    horizontal: 'left',
                    vertical: 'bottom',
                  }}
                  // PaperProps={{
                  //   sx: {
                  //     maxHeight: MENU_MAX_HEIGHT,
                  //     minHeight: MENU_MIN_HEIGHT,
                  //     width: MENU_WIDTH,
                  //   },
                  // }}
                  // TransitionProps={{
                  //   onExited: () => {
                  //     if (isExiting) setIsExiting(false);
                  //   },
                  // }}
                >
                  <AnnotationsLabelsList
                    annotations={annotation ? [annotation] : []}
                    onClose={closeLabelsPopover}
                    onLabelsChanged={handleLabelsChanged}
                  />
                </Popover>
              </VerticalContainer>
            }
          />
        ) : null}
        {annotationMessages.length > 0 ? (
          <VerticalContainer>
            <Messages messages={annotationMessages} />
          </VerticalContainer>
        ) : null}
      </Stack>
    </Collapse>
  );
});

AnnotationData.displayName = 'AnnotationData';

export { AnnotationData };
