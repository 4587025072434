import { isIncludedIn } from 'remeda';
import { IntlLocale, intlLocales, markValuesWithLineNumbers } from '.';
import embedded_en from './en/embedded_en.json';
import en from './en/en.json';

const toFileName = (locale: IntlLocale) => {
  if (locale === 'pt-br') {
    return 'pt_BR' as const;
  }
  if (locale === 'zh') {
    return 'zh_CN' as const;
  }
  return locale;
};

export const getTranslationsByLocale = async (locale: IntlLocale) => {
  const fileName = toFileName(locale);
  const translations: { default: Record<string, string> } = isIncludedIn(
    locale,
    intlLocales
  )
    ? await import(`./${fileName}/${fileName}.json`)
    : { default: {} };
  const translationsEmbedded: { default: Record<string, string> } =
    await import(`./${fileName}/embedded_${fileName}.json`);

  return {
    ...markValuesWithLineNumbers(en),
    ...markValuesWithLineNumbers(embedded_en),
    ...markValuesWithLineNumbers(translations.default),
    ...markValuesWithLineNumbers(translationsEmbedded.default),
  };
};
