import { IconDotsVertical } from '@rossum/ui/icons/tabler';
import {
  IconButton,
  Menu,
  MenuItem,
  Stack,
  SvgIcon,
  Tooltip,
  Typography,
} from '@rossum/ui/material';
import { useState } from 'react';
import { DrawerConfig } from '../../../../../containers/DocumentValidation/ValidationEmailDrawer';
import { Annotation } from '../../../../../types/annotation';
import { SidebarFooterActionIcon } from './SidebarFooterActionIcon';
import { useSidebarFooterActions } from './useSidebarFooterActions';

type SidebarFooterActionsProps = {
  visibleButtonsCount: number;
  annotation: Annotation;
  onEmailThreadOpen: (drawerConfig?: DrawerConfig) => void;
};

export const SidebarFooterActions = ({
  visibleButtonsCount,
  annotation,
  onEmailThreadOpen,
}: SidebarFooterActionsProps) => {
  const {
    actions: [visibleActions, contextActions],
    postponeAndReject: { modal: postponeAndRejectModal },
    move: { modal: moveModal },
    link: { modal: linkModal },
  } = useSidebarFooterActions(
    visibleButtonsCount,
    annotation,
    onEmailThreadOpen
  );

  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);

  return (
    <>
      <Stack
        direction="row"
        spacing={1}
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {contextActions.length ? (
          <>
            <IconButton
              size="medium"
              color="secondary"
              onClick={e => setAnchorEl(e.currentTarget)}
            >
              <SvgIcon component={IconDotsVertical} fontSize="small" />
            </IconButton>
            <Menu
              open={!!anchorEl}
              anchorEl={anchorEl}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              onClose={() => setAnchorEl(null)}
            >
              {contextActions.map(action => (
                <MenuItem
                  key={action.id}
                  onClick={() => {
                    action.onClick();
                    setAnchorEl(null);
                  }}
                  disabled={!!action.disabled}
                  data-cy={action.dataCy}
                >
                  <Stack direction="row" spacing={1} alignItems="center">
                    <SidebarFooterActionIcon
                      actionId={action.id}
                      fontSize="small"
                    />
                    <Typography>{action.label}</Typography>
                  </Stack>
                </MenuItem>
              ))}
            </Menu>
          </>
        ) : null}
        <Stack direction="row-reverse" spacing={1}>
          {visibleActions.map(action => (
            <Tooltip key={action.id} title={action.label}>
              <span>
                <IconButton
                  size="medium"
                  color="secondary"
                  onClick={action.onClick}
                  disabled={!!action.disabled}
                  data-cy={action.dataCy}
                >
                  <SidebarFooterActionIcon
                    actionId={action.id}
                    fontSize="small"
                  />
                </IconButton>
              </span>
            </Tooltip>
          ))}
        </Stack>
      </Stack>
      {/* Modals and stuff, to be refactored */}
      {postponeAndRejectModal}
      {moveModal}
      {linkModal}
    </>
  );
};
