import {
  add,
  addMinutes,
  endOfDay,
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  format,
  isEqual,
  isSameDay,
  isSameMonth,
  isSameYear,
  isValid,
  parse,
  startOfDay,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
} from 'date-fns';
import { capitalize, get } from 'lodash';
import { range } from 'remeda';
import { DateFnsLocale, dateFnsLocales } from '../../i18n/dateLocales';

export const FIRST_DAY_OF_WEEK = 1;
export const getToday = () => new Date(Date.now());

const getThisQuarterStart = () => startOfQuarter(getToday());
export const getThisMonthStart = () => startOfMonth(getToday());
export const getThisWeekStart = () =>
  startOfWeek(getToday(), { weekStartsOn: FIRST_DAY_OF_WEEK });
export const getYesterday = () => add(getToday(), { days: -1 });
export const getLastWeekStart = () => add(getThisWeekStart(), { days: -7 });
export const getLastWeekEnd = () =>
  add(endOfWeek(getToday(), { weekStartsOn: FIRST_DAY_OF_WEEK }), {
    days: -7,
  });
export const getLastMonthStart = () => add(getThisMonthStart(), { months: -1 });
export const getLastMonthEnd = () =>
  endOfMonth(add(getToday(), { months: -1 }));

export const CUSTOM = 'custom';

const customShortcut = {
  key: CUSTOM,
} as const;

export const predefinedShortcuts = [
  {
    from: () => startOfDay(getToday()),
    to: () => endOfDay(getToday()),
    key: 'today',
  },
  {
    from: () => startOfDay(getYesterday()),
    to: () => endOfDay(getYesterday()),
    key: 'yesterday',
  },
  {
    from: () => startOfDay(getThisWeekStart()),
    to: () => endOfDay(getToday()),
    key: 'thisWeek',
  },
  {
    from: () => startOfDay(getLastWeekStart()),
    to: () => endOfDay(getLastWeekEnd()),
    key: 'lastWeek',
  },
  {
    from: () => startOfDay(getThisMonthStart()),
    to: () => endOfDay(getToday()),
    key: 'thisMonth',
  },
  {
    from: () => startOfDay(getLastMonthStart()),
    to: () => endOfDay(getLastMonthEnd()),
    key: 'lastMonth',
  },
  {
    from: () => startOfDay(getThisQuarterStart()),
    to: () => endOfDay(getToday()),
    key: 'thisQuarter',
  },
  {
    from: () => startOfDay(add(getThisQuarterStart(), { months: -3 })),
    to: () => endOfDay(add(endOfQuarter(getToday()), { months: -3 })),
    key: 'lastQuarter',
  },
] as const;

export const getShortcuts = () => [...predefinedShortcuts, customShortcut];

export const DATE_FORMAT = 'dd/MM/yyyy';
export const TIME_FORMAT = 'HH:mm';
export const TIME_FORMAT_WITH_SECONDS = 'HH:mm:ss';
export const QUERY_FORMAT_WITH_SECONDS = 'yyyy-MM-dd HH:mm:ss';

export const formatDateInput = (date: Date) =>
  isValid(date) ? format(date, DATE_FORMAT) : '01/01/1993';

export const formatHoursInput = (date: Date, dateFormat = TIME_FORMAT) =>
  format(
    isValid(date) ? date : new Date('1993-01-01T00:00:00.000Z'),
    dateFormat
  );
export const formatDateHoursSecondsToQuery = (date: Date) =>
  format(date, QUERY_FORMAT_WITH_SECONDS);
export const parseDateInput = (
  date: string,
  refDate: Date,
  formatString = DATE_FORMAT
) => parse(date, formatString, refDate);

export const parseHoursInput = (
  hours: string,
  refDate: Date,
  formatString = TIME_FORMAT
) => parse(hours, formatString, refDate);

type LocalizeMethod = keyof Required<Locale>['localize'];

const getLocalizedTranslations =
  (localeModule: Locale) =>
  (arr: Array<number>, method: LocalizeMethod, options?: unknown) =>
    arr.map(i => {
      const localizeMethod = localeModule.localize?.[method];
      const value: string = localizeMethod?.(i, options);
      return capitalize(value);
    });

export const getTranslations = (locale: string) => {
  const localeModule: Locale | undefined = get(dateFnsLocales, locale);

  if (!localeModule) return {};

  const days = range(0, 7);
  const months = range(0, 12);

  const localize = getLocalizedTranslations(localeModule);

  return {
    months: localize(months, 'month'),
    weekdaysLong: localize(days, 'day'),
    weekdaysShort: localize(days, 'day', { width: 'short' }),
  };
};

type RangeType = {
  from: string;
  to: string;
};

type FormatingOptionsKey = 'sameDay' | 'sameMonth' | 'sameYear' | 'default';
type FormatingTimeOptionsKey = 'isEqual' | 'default';

type DatepickerFormats = Record<FormatingOptionsKey, RangeType>;

type DateTimeSecondsPickerFormats = Record<FormatingTimeOptionsKey, RangeType>;

const datepickerFormats: Record<string, DatepickerFormats> = {
  ja: {
    sameDay: { from: 'yo MMM do', to: 'yo MMM do' }, // 2000y 7 1d
    sameMonth: {
      // 2000y 6m 10d-15d
      from: 'yo MMM do',
      to: 'do',
    },
    sameYear: {
      // 2000y 6m 10d-7m 5d
      from: 'yo MMM do',
      to: 'MMM do',
    },
    default: { from: 'yo MMM do', to: 'yo MMM do' }, // 2000y 7 1d
  },
};

const defaultDatepickerFormats: DatepickerFormats = {
  sameDay: { from: 'd MMM, yyyy', to: 'd MMM, yyyy' }, // 1 Jan, 2020
  sameMonth: { from: 'd', to: 'd MMM, yyyy' }, // 1–31 Jan, 2020
  sameYear: { from: 'd MMM', to: 'd MMM, yyyy' }, // 1 Jan–29 Feb, 2020
  default: { from: 'd MMM, yyyy', to: 'd MMM, yyyy' }, // 24 Dec, 2019–15 Jan, 2020
};

const daterangeOptions: Array<{
  key: FormatingOptionsKey;
  condition: (from: Date, to: Date) => boolean;
}> = [
  { key: 'sameDay', condition: isSameDay },
  { key: 'sameMonth', condition: isSameMonth },
  { key: 'sameYear', condition: isSameYear },
];

const dateTimeRangeOptions: Array<{
  key: FormatingTimeOptionsKey;
  condition: (from: Date, to: Date) => boolean;
}> = [{ key: 'isEqual', condition: isEqual }];

export const baseFormat = (
  date: Date,
  options?: Parameters<typeof format>[2],
  formatString = 'd MMM, yyyy'
) => format(date, formatString, options).split(' ').map(capitalize).join(' '); // 1 Jan, 2020

export const formatDatepickerValue = (from: Date, to: Date, lang: string) => {
  const locale = get(dateFnsLocales, lang);
  if (!isValid(from) || !isValid(to)) {
    return '';
  }

  const formats = datepickerFormats[lang] || defaultDatepickerFormats;
  const key =
    daterangeOptions.find(daterangeOption =>
      daterangeOption.condition(from, to)
    )?.key ?? 'default';

  const fromFormatted = baseFormat(from, { locale }, formats[key].from);
  const toFormatted = baseFormat(to, { locale }, formats[key].to);

  return fromFormatted === toFormatted
    ? fromFormatted
    : `${fromFormatted}-${toFormatted}`;
};

const defaultDateTimeSecondsPickerFormats: DateTimeSecondsPickerFormats = {
  isEqual: {
    from: 'd MMM, yyyy HH:mm:ss',
    to: 'd MMM, yyyy HH:mm:ss',
  },
  default: {
    from: 'd MMM, yyyy HH:mm:ss',
    to: 'd MMM, yyyy HH:mm:ss',
  },
};

export const formatDateTimeWithSecondsPickerValue = (
  from: Date,
  to: Date,
  lang: string
) => {
  const locale = get(dateFnsLocales, lang);
  if (!isValid(from) || !isValid(to)) {
    return '';
  }

  const formats = defaultDateTimeSecondsPickerFormats;
  const key =
    dateTimeRangeOptions.find(dateTimeRangeOption =>
      dateTimeRangeOption.condition(from, to)
    )?.key ?? 'default';

  const fromFormatted = baseFormat(from, { locale }, formats[key].from);
  const toFormatted = baseFormat(to, { locale }, formats[key].to);

  return fromFormatted === toFormatted
    ? fromFormatted
    : `${fromFormatted}-${toFormatted}`;
};

export const monthTitleFormats: Partial<Record<DateFnsLocale, string>> = {
  ja: 'yo MMM',
};

export const formatMonthTitle = (lang: DateFnsLocale) => (date: Date) =>
  baseFormat(date, { locale: dateFnsLocales[lang] }, monthTitleFormats[lang]);

export const convertToUTC = (date: Date) =>
  addMinutes(date, date.getTimezoneOffset());

export const convertFromUTC = (date: Date) =>
  addMinutes(date, -date.getTimezoneOffset());

export const completeHoursFormat = (hours: string, withSeconds: boolean) =>
  hours
    .split(':')
    .concat(['00'], ['00'])
    .slice(0, withSeconds ? 3 : 2)
    .join(':');

export const addSecondsToDate = (date: Date, seconds: number) => {
  const newDate = new Date(date);
  newDate.setSeconds(date.getSeconds() + seconds);

  return newDate;
};
