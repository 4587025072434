import { Dialog } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import DialogTitle from '../../../../ui/dialog-title/DialogTitle';
import { TokenOwnerDialogContent } from './TokenOwnerDialogContent';

const DIALOG_MAX_WIDTH = 448;

type TokenOwnerDialogProps = {
  open: boolean;
  onClose: () => void;
  onSubmit: (tokenOwner: string) => void;
  submitting: boolean;
};

export const TokenOwnerDialog = ({
  open,
  onClose,
  onSubmit,
  submitting,
}: TokenOwnerDialogProps) => {
  const intl = useIntl();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      slotProps={{
        paper: {
          sx: { width: DIALOG_MAX_WIDTH, position: 'fixed' },
          elevation: 2,
        },
      }}
    >
      {open && (
        <>
          <DialogTitle
            title={intl.formatMessage({
              id: `components.tokenOwnerDialog.title`,
            })}
            onClose={onClose}
          />
          <TokenOwnerDialogContent
            onSubmit={onSubmit}
            submitting={submitting}
            onClose={onClose}
          />
        </>
      )}
    </Dialog>
  );
};
