import {
  IconLogout,
  IconReceipt,
  IconUserCircle,
} from '@rossum/ui/icons/tabler';
import {
  Divider,
  IconButton,
  Menu,
  Stack,
  Typography,
} from '@rossum/ui/material';
import { get } from 'lodash';
import { ComponentType, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { isEmbedded } from '../../constants/config';
import { LocalizationKeys } from '../../i18n';
import { logoutUser } from '../../redux/modules/auth/actions';
import { MenuLinkItem } from '../../ui/menu-link-item/MenuLinkItem';
import { useBillingEnabled } from '../billing';
import { HelpPanel } from '../help-panel';
import { hasSubscriptionPlanExistSelector } from '../pricing/selectors';
import { BeamerToggle } from './components/BeamerToggle';
import { OrganizationSwitcher } from './components/OrganizationSwitcher';
import { UserAvatar } from './components/UserAvatar';
import { useCurrentOrganization } from './hooks/useCurrentOrganization';
import { useUnpaginatedOrganizationsWithMembership } from './hooks/useUnpaginatedOrganizationsWithMembership';
import { useUser } from './hooks/useUser';

// TODO: how to handle type for uiSettings and potential clash with old/redux type?
export type TmpOrganizationBranding = {
  top_right?: string;
  support_menu?: Array<{
    type: 'link';
    label: string;
    url: string;
  }>;
};

type StaticMenuItem = {
  key: LocalizationKeys;
  Icon: ComponentType;
  dataCy: string;
  href?: string;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
};

export const UserPanel = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const isOpen = Boolean(anchorEl);
  const closeUserMenu = () => setAnchorEl(null);

  const { data: user } = useUser();
  const { data: organizations, isLoading: organizationsAreLoading } =
    useUnpaginatedOrganizationsWithMembership();
  const { data: currentOrganization } = useCurrentOrganization();

  const intl = useIntl();
  const dispatch = useDispatch();

  const isPricingActive = useSelector(hasSubscriptionPlanExistSelector);
  const billingPath = `/billing/${isPricingActive ? 'my-plan' : 'overview'}`;

  const billingEnabled = useBillingEnabled().isEnabled;

  const staticMenuItems: Array<StaticMenuItem> = [
    {
      dataCy: 'personal-settings-link',
      Icon: IconUserCircle,
      href: '/account/personalInfo',
      key: 'containers.personalInfo.title',
    },
    ...(billingEnabled
      ? [
          {
            dataCy: 'user-panel-billing',
            Icon: IconReceipt,
            href: billingPath,
            key: 'containers.billing.title' as const,
          },
        ]
      : []),

    {
      dataCy: 'logout-button',
      Icon: IconLogout,
      key: 'components.dashboard.user.signOut' as const,
      onClick: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        dispatch(logoutUser());
      },
    },
  ];

  const email = user?.email ?? user?.username ?? '';
  const firstName = user?.firstName ?? '';
  const lastName = user?.lastName ?? '';

  const userInitials = `${(firstName || user?.username || email)[0]}${
    lastName[0] || ''
  }`.toUpperCase();

  const organizationBranding = get(
    currentOrganization,
    'uiSettings.branding'
  ) as TmpOrganizationBranding | undefined;

  const topRightLogo = organizationBranding?.top_right;
  const supportMenuItems = organizationBranding?.support_menu ?? undefined;

  return (
    <Stack spacing={1} direction="row" alignItems="center" sx={{ pr: 0.5 }}>
      <HelpPanel supportMenuItems={supportMenuItems} />
      <BeamerToggle />
      {currentOrganization && user ? (
        isEmbedded() ? (
          topRightLogo ? (
            <UserAvatar avatarUrl={topRightLogo} userInitials="" />
          ) : null
        ) : (
          <Stack>
            <Menu
              open={isOpen}
              anchorEl={anchorEl}
              onClose={closeUserMenu}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              slotProps={{
                list: {
                  sx: {
                    width: 334,
                  },
                },
                paper: {
                  sx: {
                    overflow: 'visible',
                  },
                },
              }}
            >
              <Stack px={2} py={1} direction="row" alignItems="center">
                <UserAvatar
                  avatarUrl={topRightLogo}
                  userInitials={userInitials}
                />
                <Stack sx={{ marginLeft: 1 }}>
                  <Typography variant="subtitle2" color="text.primary" noWrap>
                    {`${firstName} ${lastName}`}
                  </Typography>
                  <Typography
                    variant="caption"
                    color="text.secondary"
                    noWrap
                    data-cy="email"
                  >
                    {email}
                  </Typography>
                </Stack>
              </Stack>
              <Divider />
              <OrganizationSwitcher
                organization={currentOrganization}
                organizations={organizations}
                organizationsAreLoading={organizationsAreLoading}
              />
              <Divider />
              {staticMenuItems.map(({ key, href, Icon, dataCy, onClick }) => (
                <MenuLinkItem
                  key={key}
                  href={href ?? '#'}
                  dataCy={dataCy}
                  Icon={Icon}
                  label={intl.formatMessage({ id: key })}
                  onClick={e => {
                    closeUserMenu();
                    if (onClick) onClick(e);
                  }}
                />
              ))}
            </Menu>
            <IconButton
              size="small"
              aria-controls={isOpen ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={isOpen ? 'true' : undefined}
              data-cy="userpanel"
              onClick={e => setAnchorEl(e.currentTarget)}
            >
              <UserAvatar
                avatarUrl={topRightLogo}
                userInitials={userInitials}
              />
            </IconButton>
          </Stack>
        )
      ) : null}
    </Stack>
  );
};
