import { stringify } from 'query-string';
import { useCallback, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';
import { isEmpty } from 'remeda';
import * as z from 'zod';
import { parseQueryStringPreprocessor } from '../../lib/parseQueryStringPreprocessor';
import { HOOK_LIST_QUERY } from '../../redux/modules/localStorage/actions';

const extensionQueryParser = z.preprocess(
  parseQueryStringPreprocessor,
  z
    .object({
      pageSize: z.coerce.number(),
      page: z.coerce.number(),
      name: z.coerce.string(),
      queues: z.coerce.number(),
      events: z.enum([
        'annotation_content.confirm',
        'annotation_content.export',
        'annotation_content.initialize',
        'annotation_content.started',
        'annotation_content.updated',
        'annotation_content.user_update',
        'annotation_status.changed',
        'upload.created',
        'email.received',
        'invocation.manual',
        'invocation.scheduled',
      ]),
      view: z.enum(['list', 'graph']),
    })
    .partial()
    .optional()
    .catch(undefined)
);

export const useExtensionSearchParams = () => {
  const { replace } = useHistory();
  const { search } = useLocation();

  const parsedParams = useMemo(
    () => extensionQueryParser.parse(search) ?? {},
    [search]
  );

  useEffect(() => {
    const storedQueryString = localStorage.getItem(HOOK_LIST_QUERY);
    const storedQuery = extensionQueryParser.parse(storedQueryString);

    if (storedQueryString && storedQuery && isEmpty(parsedParams)) {
      replace({ search: storedQueryString });
    }
  }, [parsedParams, replace]);

  const updateParsedParams = useCallback(
    (state: Partial<typeof parsedParams>) => {
      const locationQuery = stringify({ ...parsedParams, ...state });
      localStorage.setItem(HOOK_LIST_QUERY, locationQuery);
      replace({ search: locationQuery });
    },
    [parsedParams, replace]
  );

  const parsedParamsWithDefaults = {
    pageSize: parsedParams.pageSize || 15,
    page: parsedParams.page || 1,
    name: parsedParams.name || '',
    queues: parsedParams.queues,
    events: parsedParams.events,
    view: parsedParams.view || 'list',
  };

  return [parsedParamsWithDefaults, updateParsedParams] as const;
};
