import { Button } from '@rossum/ui/material';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { validate } from '../../redux/modules/datapoints/actions';
import { openPopup } from '../../redux/modules/popup/actions';
import { AnyDatapointDataST } from '../../types/datapoints';
import { ButtonDatapointSchema } from '../../types/schema';
import { State } from '../../types/state';

type OwnProps = {
  inFooter: boolean;
  schema: ButtonDatapointSchema;
  data: AnyDatapointDataST;
};

type DispatchProps = {
  onClick: () => void;
};

type Props = OwnProps & DispatchProps;

const ButtonDatapoint = ({ schema: { label }, onClick, inFooter }: Props) => (
  <Button
    variant="contained"
    data-cy="button-datapoint"
    onClick={() => onClick()}
    size={inFooter ? 'small' : 'medium'}
    sx={{ flex: 1, color: theme => theme.palette.common.white }}
  >
    {label}
  </Button>
);

const mapDispatchToProps = (
  dispatch: Dispatch,
  { schema: { popupUrl, canObtainToken }, data: { id } }: OwnProps
) => ({
  onClick: () =>
    dispatch(
      popupUrl
        ? openPopup(popupUrl, canObtainToken === true)
        : validate([id], undefined, ['user_update', 'updated'])
    ),
});

export default connect<unknown, DispatchProps, OwnProps, State>(
  null,
  mapDispatchToProps
)(ButtonDatapoint);
