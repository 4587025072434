import { Redirect, Route, Switch, useRouteMatch } from 'react-router';
import {
  ExtensionLogsGuard,
  ExtensionsCreateGuard,
  ExtensionsGuard,
} from '../../components/Restrictors';
import { Logs } from '../../features/extensions/logs';
import ExtensionSettings from '../Extension/containers/ExtensionSettings';
import { ExtensionEditorRoute } from '../Settings/ExtensionEditorRoute';
import Extensions from '.';
import CreateExtension from './containers/CreateExtension';
import ExtensionsStore from './containers/Store';
import {
  extensionCodeRoute,
  extensionDetailsRoute,
  ExtensionParams,
  extensionsCreatePath,
  extensionsLogsPath,
  extensionsStorePath,
  myExtensionsPath,
} from './helpers';

export const ExtensionsRoutes = () => {
  const extensionDetailMatch = useRouteMatch<ExtensionParams>(
    extensionDetailsRoute
  );

  return (
    <Switch>
      <Route
        exact
        path={myExtensionsPath()}
        render={() => (
          <ExtensionsGuard>
            <Extensions />
          </ExtensionsGuard>
        )}
      />
      <Route path={extensionsStorePath()} render={() => <ExtensionsStore />} />
      <Route
        path={extensionsCreatePath()}
        render={() => (
          <ExtensionsCreateGuard>
            <CreateExtension />
          </ExtensionsCreateGuard>
        )}
      />
      <Route
        path={extensionsLogsPath()}
        render={() => (
          <ExtensionLogsGuard>
            <Logs />
          </ExtensionLogsGuard>
        )}
      />
      {extensionDetailMatch ? (
        <Route path={extensionDetailsRoute}>
          <Switch>
            <Route
              exact
              path={extensionCodeRoute}
              render={() => (
                <ExtensionEditorRoute
                  extensionId={Number(extensionDetailMatch.params.extensionId)}
                />
              )}
            />
            <Route
              path={extensionDetailsRoute}
              render={() => (
                <ExtensionSettings
                  detailUrl={extensionDetailMatch.url}
                  extensionId={Number(extensionDetailMatch.params.extensionId)}
                />
              )}
            />
          </Switch>
        </Route>
      ) : (
        <Redirect to={myExtensionsPath()} />
      )}
    </Switch>
  );
};
