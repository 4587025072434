import { Stack } from '@rossum/ui/material';
import { ReactNode } from 'react';

type Props = { children: ReactNode };

const PageLayout = ({ children }: Props) => (
  <Stack
    direction="column"
    sx={{
      width: '100%',
      height: '100%',
      position: 'relative',
    }}
  >
    {children}
  </Stack>
);

export default PageLayout;
