import { Hook } from '@rossum/api-client/hooks';
import { DetailDrawerProps } from '@rossum/ui';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { QUERY_KEY_HOOKS } from '../../../../business/hooks/useHooks';
import { useOpenModal } from '../../../../utils/hooks/useOpenModal';
import ConfigAppDrawer from './components/ConfigAppDrawer';
import ConfigAppRoute from './components/ConfigAppRoute';
import ConfigAppContainer from './ConfigAppContainer';
import { useExtensionConfigAppContext } from './context/configAppContext';

type Props = {
  configAppUrl: string;
  DrawerProps: Omit<DetailDrawerProps, 'onClose'> & { onClose?: () => void };
  selectedExtension: Hook;
};

const ConfigApp = ({ DrawerProps, configAppUrl, selectedExtension }: Props) => {
  const queryClient = useQueryClient();
  const [isIFrameLoaded, setIsIFrameLoaded] = useState(false);

  const [shouldLeaveSafely, setShouldLeaveSafely] = useState(false);
  const [ModalDialog, openModal] = useOpenModal();

  const { setTimestamp } = useExtensionConfigAppContext();
  const [shouldRefetch, setShouldRefetch] = useState(true);

  const handleClose = () => {
    const onCloseCallback = () => {
      setTimestamp(Date.now());
      DrawerProps.onClose?.();
      setIsIFrameLoaded(false);
      setShouldRefetch(true);
    };

    if (shouldLeaveSafely)
      return openModal({
        onConfirm: onCloseCallback,
        textId: 'confirmCloseDrawer',
        confirmType: 'Danger',
      });

    if (shouldRefetch && isIFrameLoaded) {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY_HOOKS] });
    }

    return onCloseCallback();
  };

  const isFullscreen =
    selectedExtension.config.app?.displayMode === 'fullscreen';

  const Wrapper = isFullscreen ? ConfigAppRoute : ConfigAppDrawer;

  return (
    <Wrapper
      handleClose={handleClose}
      name={selectedExtension.name}
      id={selectedExtension.id}
      open={!!DrawerProps.open}
    >
      <ConfigAppContainer
        selectedExtension={selectedExtension}
        configAppUrl={configAppUrl}
        onLoad={setIsIFrameLoaded}
        isLoaded={isIFrameLoaded}
        setShouldRefetch={setShouldRefetch}
        onClose={handleClose}
        setShouldLeaveSafely={setShouldLeaveSafely}
      />
      {ModalDialog}
    </Wrapper>
  );
};

export default ConfigApp;
