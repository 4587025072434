// Generated by ts-to-zod
import { z } from 'zod';
import { sectionDataSchema } from '../../../annotations/models/annotationData.schema';
import { schemaSectionSchema } from '../../../schemas/models/schemaSection.schema';
import { messageSchema } from '../../../shared/models/message.schema';
import {
  schemaActionSchema,
  schemaRuleSchema,
} from '../../../rules/models/schemaRule.schema';

export const evaluateRulesPayloadSchema = z.object({
  schemaContent: z.array(schemaSectionSchema),
  annotationContent: z.array(sectionDataSchema),
  schemaRules: z.array(schemaRuleSchema.partial()),
});

export const evaluateRulesResponseSchema = z.object({
  conditionValues: z.array(z.union([z.boolean(), z.array(z.boolean())])),
  messages: z.array(messageSchema),
  actions: z.array(schemaActionSchema),
});
