import { endpoints } from '@rossum/api-client';
import { SchemaAction, SchemaSection } from '@rossum/api-client/schemas';
import { GridCellParams, GridColDef } from '@rossum/ui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';
import { ReactNode, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { api } from '../../../../lib/apiClient';
import { FORMULA_PREVIEW_KEY } from '../../keys';
import { ActionCell } from '../cells/ActionCell';
import { ConditionCell } from '../cells/ConditionCell';
import {
  datapointCellRenderer,
  formulaPreviewClassName,
} from '../cells/DatapointCell';
import { DocumentCell } from '../cells/DocumentCell';
import { HeaderCell } from '../cells/HeaderCell';
import {
  actionsColumn,
  columnsPrefix,
  conditionsColumn,
  documentsColumn,
  globals,
  RowModel,
} from '../rows/helpers';
import { useColumnWidths } from './useColumnWidths';
import { getSchemaIconTypeMap } from './utils';

export const COLUMNS_QUERY_KEY = [
  FORMULA_PREVIEW_KEY,
  'formulas-info',
] as const;

type UseColumnsParams = {
  currentFormulaId: string;
  schemaForPreview: SchemaSection[];
  type: 'rule' | 'formula';
  renderAction?: (action: SchemaAction) => ReactNode;
};

export const useColumns = ({
  currentFormulaId,
  schemaForPreview,
  type,
  renderAction,
}: UseColumnsParams): GridColDef<RowModel>[] => {
  const intl = useIntl();

  const formulasInfoQuery = useQuery({
    queryKey: [...COLUMNS_QUERY_KEY, schemaForPreview],
    queryFn: () =>
      api.request(
        endpoints.internal.schemas.formulasInfo({
          // using getter to use the current schema concept when invalidated query with unsaved changes
          schemaContent: schemaForPreview,
        })
      ),
    enabled: schemaForPreview.length > 0,
    cacheTime: 0,
  });

  const columnWidths = useColumnWidths();

  const currentFormulaInfo = formulasInfoQuery.data?.results.find(
    d => d.id === currentFormulaId
  );

  const formulaType = currentFormulaInfo?.type ?? 'formula';

  const formulaDependenciesColumns = useMemo(() => {
    const schemaIconsMap = formulasInfoQuery.dataUpdatedAt
      ? getSchemaIconTypeMap(schemaForPreview)
      : {};

    const dependencies = currentFormulaInfo?.dependencies ?? [];

    return dependencies.flatMap(dependencySchemaId => {
      const field = `${columnsPrefix}.${dependencySchemaId}`;

      return dependencySchemaId === currentFormulaId
        ? []
        : {
            field,
            headerName: dependencySchemaId,
            width: columnWidths[field] ?? 200,
            minWidth: 120,
            sortable: false,
            renderCell: datapointCellRenderer,
            renderHeader: () => (
              <HeaderCell
                headerName={dependencySchemaId}
                iconType={schemaIconsMap[dependencySchemaId] ?? null}
              />
            ),
          };
    });
  }, [
    formulasInfoQuery.dataUpdatedAt,
    schemaForPreview,
    currentFormulaInfo?.dependencies,
    currentFormulaId,
    columnWidths,
  ]);

  const currentFormulaColumn = `${columnsPrefix}.${currentFormulaId}`;

  return [
    ...(type === 'rule'
      ? [
          {
            field: conditionsColumn,
            headerName: currentFormulaId,
            width: 80,
            sortable: false,
            resizable: false,
            renderCell: ({ row }: GridCellParams<RowModel>) => (
              <ConditionCell value={row[conditionsColumn]} />
            ),
            cellClassName: formulaPreviewClassName,
            renderHeader: () => (
              <HeaderCell
                headerName={intl.formatMessage({
                  id: 'features.formulas.when',
                })}
              />
            ),
          },
          {
            field: actionsColumn,
            headerName: currentFormulaId,
            width: 70,
            resizable: false,
            sortable: false,
            renderCell: ({ row }: GridCellParams<RowModel>) => {
              return (
                <ActionCell
                  actions={
                    row[conditionsColumn] ? row[globals].actions ?? [] : []
                  }
                  renderAction={renderAction}
                />
              );
            },
            cellClassName: formulaPreviewClassName,
            renderHeader: () => (
              <HeaderCell
                headerName={intl.formatMessage({
                  id: 'features.formulas.then',
                })}
              />
            ),
          },
        ]
      : [
          {
            field: currentFormulaColumn,
            headerName: currentFormulaId,
            width: columnWidths[currentFormulaColumn] ?? 200,
            minWidth: 120,
            sortable: false,
            renderCell: datapointCellRenderer,
            cellClassName: formulaPreviewClassName,
            renderHeader: () => (
              <HeaderCell
                headerName={currentFormulaId}
                iconType={formulaType}
              />
            ),
          },
        ]),
    ...formulaDependenciesColumns,
    {
      field: documentsColumn,
      headerName: intl.formatMessage({
        id: 'features.queueSettings.fields.formulaPreview.table.document',
      }),
      width: columnWidths[documentsColumn] ?? 200,
      minWidth: 200,
      sortable: false,
      renderHeader: p => <HeaderCell headerName={p.colDef.headerName} />,
      renderCell: p => (
        <DocumentCell
          annotationUrl={p.row.annotationUrl}
          value={p.value}
          // passing globals to document column due to the inability to have dynamic width of columns
          // based on number of icons, could be tackled by `autosizeOptions` in DataGrid v7 probably
          globals={p.row[globals]}
        />
      ),
    },
  ];
};
