import { JsonSchema, UISchemaElement } from '@jsonforms/core';
import { materialCells } from '@jsonforms/material-renderers';
import { JsonForms } from '@jsonforms/react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
} from '@rossum/ui/material';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useFeatureFlag } from '../../unleash/useFeatureFlag';
import { renderers } from './custom-controls/renderers';
import { useInvokeHook } from './hooks/useInvokeHook';
import { useStartupHook } from './hooks/useStartupHook';
import { useExtensionInteropStore } from './store/ExtensionInteropStore';
import { FormEvent } from './store/formSlice';

const ExtensionInteropUiForm = ({
  formEvent,
  onClose,
}: {
  formEvent: FormEvent;
  onClose: () => void;
}) => {
  const [data, setData] = useState(formEvent.form.defaultValue);
  const [errors, setErrors] = useState<unknown[] | undefined>([]);
  const { mutate: invoke, isLoading } = useInvokeHook();
  const { hook } = formEvent.form;
  const intl = useIntl();

  return (
    <Stack
      component="form"
      direction="column"
      overflow="hidden"
      onSubmit={
        hook
          ? e => {
              e.preventDefault();
              return invoke({
                hook,
                payload: { form: data },
              });
            }
          : undefined
      }
    >
      <DialogContent>
        <Stack spacing={2} sx={{ width: formEvent.form.width }}>
          <JsonForms
            schema={formEvent.form.schema as JsonSchema}
            uischema={formEvent.form.uiSchema as UISchemaElement}
            data={data}
            validationMode="NoValidation"
            renderers={renderers}
            cells={materialCells}
            onChange={state => {
              setData(state.data);
              setErrors(state.errors);
            }}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={1} p={1} alignSelf="end">
          <Button
            type="button"
            variant="outlined"
            color="secondary"
            onClick={onClose}
          >
            {intl.formatMessage({ id: 'features.extensionInterop.close' })}
          </Button>
          {hook ? (
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isLoading || !errors || errors.length > 0}
              startIcon={isLoading ? <CircularProgress size={18} /> : null}
            >
              {intl.formatMessage({ id: 'features.extensionInterop.submit' })}
            </Button>
          ) : null}
        </Stack>
      </DialogActions>
    </Stack>
  );
};

export const ExtensionInteropUi = (
  { launchHooks }: { launchHooks?: boolean } = { launchHooks: true }
) => {
  const formEvent = useExtensionInteropStore(state => state.formEvent);
  const closeForm = useExtensionInteropStore(state => state.closeForm);

  const commandsEnabled = useFeatureFlag('ac-5730-fe-exe-interop');
  useStartupHook({ enabled: commandsEnabled && launchHooks === true });

  return commandsEnabled ? (
    <Dialog
      open={formEvent !== undefined}
      onClose={() => closeForm()}
      PaperProps={{
        elevation: 2,
        sx: {
          maxWidth: '100%',
        },
      }}
    >
      {formEvent ? (
        <ExtensionInteropUiForm
          formEvent={formEvent}
          key={formEvent.uuid}
          onClose={() => closeForm()}
        />
      ) : null}
    </Dialog>
  ) : null;
};
