import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Stack } from '@rossum/ui/material';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import * as yup from 'yup';
import { useResetPassword } from '../../business/auth/useResetPassword';
import TextFieldControl from '../../components/ReactHookForm/controls/TextFieldControl';
import InfoScreenHeader from '../../components/UI/InfoScreenHeader';
import PasswordContainer from './components/PasswordContainer';
import Retry from './components/Retry';

const Reset = () => {
  const intl = useIntl();

  const resetFormValidationSchema = yup.object().shape({
    email: yup
      .string()
      .email(
        intl.formatMessage({
          id: 'components.user.recovery.email.invalid',
        })
      )
      .required(
        intl.formatMessage({
          id: 'components.user.recovery.email.required',
        })
      ),
  });

  const { handleSubmit, control, reset, watch } = useForm({
    mode: 'onSubmit',
    defaultValues: { email: '' },
    resolver: yupResolver(resetFormValidationSchema),
  });

  const { email } = watch();
  const { mutate: resetPassword, isSuccess } = useResetPassword();

  const title = isSuccess
    ? intl.formatMessage({ id: 'components.user.recovery.submitted.title' })
    : intl.formatMessage({ id: 'components.user.recovery.unsubmitted.title' });

  const subTitle = isSuccess
    ? intl.formatMessage(
        { id: 'components.user.recovery.submitted.text' },
        { email }
      )
    : intl.formatMessage({ id: 'components.user.recovery.unsubmitted.text' });

  return (
    <PasswordContainer dataPageTitle="recovery">
      <InfoScreenHeader title={title} subTitle={subTitle} />
      {isSuccess ? (
        <Retry
          onRetry={() => {
            reset({}, { keepValues: true });
          }}
        />
      ) : (
        <Stack justifyContent="center" sx={{ width: '100%' }}>
          <form
            onSubmit={handleSubmit(formValues =>
              resetPassword({ email: formValues.email })
            )}
          >
            <Stack spacing={2}>
              <TextFieldControl
                FieldLabelProps={{ layout: 'floating' }}
                ControllerProps={{ control, name: 'email' }}
                placeholder={intl.formatMessage({
                  id: 'components.user.login.email',
                })}
                label={intl.formatMessage({
                  id: 'components.user.login.email',
                })}
              />
              <Button variant="contained" type="submit">
                {intl.formatMessage({ id: 'components.user.recovery.reset' })}
              </Button>
            </Stack>
          </form>
        </Stack>
      )}
    </PasswordContainer>
  );
};

export default Reset;
