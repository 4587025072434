import { Ace } from 'ace-builds';
import { CloseFullscreen as CloseFullscreenIcon } from '@rossum/ui/icons';
import { Error as AlertCircleIcon } from '@rossum/ui/icons';
import { FormatAlignLeft as FormatAlignLeftIcon } from '@rossum/ui/icons';
import { OpenInFull as OpenInFullIcon } from '@rossum/ui/icons';
import { SettingsBackupRestore as SettingsBackupRestoreIcon } from '@rossum/ui/icons';
import {
  Box,
  Dialog,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@rossum/ui/material';
import clsx from 'clsx';
import { isEqual } from 'lodash';
import ContentSave from 'mdi-react/ContentSaveOutlineIcon';
import { ReactNode, useEffect, useRef, useState } from 'react';
import ReactAce from 'react-ace/lib/ace';
import { FormattedMessage, useIntl } from 'react-intl';
import JsonEditor from '../../../../../components/UI/Editor/components/JsonEditor';
import { JSON_FIELD_ANNOTATION_SOURCE } from '../../../lib/schemaValidation';
import { useJSONfield } from '../../../lib/useJSONfield';
import styles from '../style.module.sass';

type JsonInputProps = {
  name: string;
  height: number;
  jsonFieldProps: ReturnType<typeof useJSONfield>;
  title?: ReactNode;
  expandable?: boolean;
};

const ICON_SIZE_FULL_SCREEN = 24;
const ICON_SIZE_MINIMIZED = 16;

const JsonInputBase = ({
  name,
  height,
  jsonFieldProps: {
    onChange,
    value,
    error,
    schemaErrorAnnotations,
    onPrettify,
    canPrettify,
    onSave,
    canSave,
    onReset,
    canReset,
    onValidate,
  },
  onOpenInFullToggle,
  isOpenInFull,
  title,
  expandable,
}: JsonInputProps & {
  onOpenInFullToggle: () => void;
  isOpenInFull: boolean;
}) => {
  const intl = useIntl();
  const hasError = !!error;

  // This is a workaround for setting the annotations to ACE editor.
  // Ideally we would only need to pass annotations as prop to component
  // but there is a bug that overwrites annotations from worker
  // https://github.com/securingsincity/react-ace/issues/483
  const editor = useRef<ReactAce | null>(null);
  useEffect(() => {
    const editorSession = editor.current?.editor.getSession();
    const onChangeHandler = () => {
      const annotations: Array<Ace.Annotation & { source?: string }> =
        editorSession?.getAnnotations() ?? [];
      const jsonEditorAnnotations = annotations.filter(
        ({ source }) => source === JSON_FIELD_ANNOTATION_SOURCE
      );
      // we will never have messages from worker and schema validation at the same time
      if (
        editorSession &&
        !isEqual(jsonEditorAnnotations, schemaErrorAnnotations)
      ) {
        editorSession.setAnnotations(schemaErrorAnnotations);
      }
    };

    if (editorSession) {
      editorSession.on(
        // @ts-expect-error used to bypass wrong typing from library
        'changeAnnotation',
        onChangeHandler
      );
    }

    return () => {
      if (editorSession) {
        editorSession.removeEventListener('changeAnnotation', onChangeHandler);
      }
    };
  }, [editor, schemaErrorAnnotations]);

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent={isOpenInFull ? 'space-between' : 'flex-end'}
        sx={{
          px: 1,
          py: 1,
          backgroundColor: theme =>
            theme.palette.mode === 'light'
              ? 'action.selected'
              : 'background.default',
          borderBottom: theme => `1px solid ${theme.palette.divider}`,
          borderRadius: isOpenInFull
            ? 0
            : theme => {
                const br = theme.shape.borders.medium;
                return `${br}px ${br}px 0 0`;
              },
        }}
      >
        {isOpenInFull && (
          <>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Tooltip
                placement="top"
                title={intl.formatMessage({
                  id: 'components.editor.tooltips.prettify',
                })}
              >
                <span>
                  <IconButton
                    disabled={!canPrettify}
                    color="inherit"
                    size="small"
                    onClick={onPrettify}
                  >
                    <FormatAlignLeftIcon
                      sx={{ fontSize: ICON_SIZE_FULL_SCREEN }}
                    />
                  </IconButton>
                </span>
              </Tooltip>
              {onSave && (
                <Tooltip
                  placement="top"
                  title={intl.formatMessage({
                    id: 'components.editor.tooltips.save',
                  })}
                >
                  <span>
                    <IconButton
                      disabled={!canSave}
                      color="inherit"
                      size="small"
                      onClick={onSave}
                    >
                      <ContentSave />
                    </IconButton>
                  </span>
                </Tooltip>
              )}
              {onReset && (
                <Tooltip
                  placement="top"
                  title={intl.formatMessage({
                    id: 'components.editor.tooltips.reset',
                  })}
                >
                  <span>
                    <IconButton
                      disabled={!canReset}
                      color="inherit"
                      size="small"
                      onClick={onReset}
                    >
                      <SettingsBackupRestoreIcon
                        sx={{ fontSize: ICON_SIZE_FULL_SCREEN }}
                      />
                    </IconButton>
                  </span>
                </Tooltip>
              )}
            </Stack>

            <Typography variant="body2">{title}</Typography>
          </>
        )}

        <Tooltip
          placement="top"
          title={intl.formatMessage({
            id: isOpenInFull
              ? 'components.editor.inputBox.minimize'
              : 'components.editor.inputBox.extend',
          })}
        >
          <span
            style={{
              visibility: expandable ? 'visible' : 'hidden',
              pointerEvents: expandable ? 'auto' : 'none',
            }}
          >
            <IconButton
              color="inherit"
              size="small"
              onClick={onOpenInFullToggle}
              disabled={!expandable}
            >
              {isOpenInFull ? (
                <CloseFullscreenIcon
                  sx={{
                    fontSize:
                      // adjust the size of the close icon to visually match with other icons
                      ICON_SIZE_FULL_SCREEN - 4,
                  }}
                />
              ) : (
                <OpenInFullIcon sx={{ fontSize: ICON_SIZE_MINIMIZED }} />
              )}
            </IconButton>
          </span>
        </Tooltip>
      </Stack>

      <JsonEditor
        name={name}
        onChange={onChange}
        value={value}
        forwardRef={editor}
        // debounce is not needed anymore - but when omitted, the editor in JSON mode
        // is quiet slow - 0 seems to be solving the issue
        debounceChangePeriod={0}
        height={isOpenInFull ? undefined : height}
        onValidate={onValidate}
      />
      {hasError && (
        <div className={styles.InputErrors}>
          <div className={clsx(styles.InputError, styles.InputErrorCount)}>
            <AlertCircleIcon />
            <FormattedMessage id="components.editor.inputBox.errorMessage" />
          </div>
        </div>
      )}
    </>
  );
};

const JsonInput = (props: JsonInputProps) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <Box sx={{ position: 'relative' }}>
      <JsonInputBase
        {...props}
        onOpenInFullToggle={() => setIsDialogOpen(true)}
        isOpenInFull={false}
      />
      <Dialog
        open={isDialogOpen}
        fullScreen
        maxWidth="lg"
        PaperProps={{
          elevation: 2,
          sx: {
            height: '100%',
          },
        }}
      >
        <JsonInputBase
          {...props}
          onOpenInFullToggle={() => setIsDialogOpen(false)}
          isOpenInFull
        />
      </Dialog>
    </Box>
  );
};

export default JsonInput;
