import { LinearProgress, Paper, Stack, Typography } from '@rossum/ui/material';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import { replace } from 'redux-first-history';
import {
  contactSupportLink,
  statusPageURL,
  termsAndConditionsURL,
} from '../../constants/values';
import { link } from '../../lib/formaterValues';
import ElisHead from './components/ElisHead';

type Props = { refresh: () => void };
type MessageKey = 'maintenance' | 'throttleError';
export type MaintenaceRouterState = {
  initialCountdown?: number;
  messageKey: MessageKey;
};

const Maintenance = ({ refresh }: Props) => {
  const location = useLocation<MaintenaceRouterState>();

  const { initialCountdown = 10, messageKey = 'maintenance' } =
    location.state ?? {};

  const [countdown, setCountdown] = useState(initialCountdown);

  const intl = useIntl();

  useEffect(() => {
    const interval = setInterval(
      () => setCountdown(n => Math.max(n - 1, 0)),
      1000
    );
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (countdown <= 0) refresh();
  }, [countdown, refresh]);

  const progressPercentage =
    (100 / initialCountdown) * (initialCountdown - countdown);

  return (
    <Stack
      spacing={1}
      sx={{
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      component={Paper}
      elevation={1}
      data-page-title="maintenance"
    >
      <ElisHead />
      <Typography
        variant="h5"
        sx={{ letterSpacing: '3.26px', textTransform: 'uppercase' }}
      >
        {intl.formatMessage({ id: 'containers.maintenance.title' })}
      </Typography>
      <Typography variant="body2">
        {intl.formatMessage(
          { id: `containers.${messageKey}.text` },
          {
            policyLink: link(termsAndConditionsURL, {
              color: 'inherit',
            }),
          }
        )}
      </Typography>
      {/* <div className={styles.LoaderBar}>
        <div
          style={{ width: `${progressPercentage}%` }}
          className={styles.Loader}
        />
      </div> */}
      <LinearProgress
        sx={{ width: '200px', height: '5px' }}
        variant="determinate"
        value={progressPercentage}
      />
      <Typography variant="caption" color="text.secondary">
        {countdown}
        {intl.formatMessage({ id: 'containers.maintenance.smallText' })}
      </Typography>
      <Typography variant="body2" sx={{ color: 'text.secondary', mt: '105px' }}>
        {intl.formatMessage(
          { id: 'containers.maintenance.badge.text1' },
          {
            contactLink: link(contactSupportLink, {
              color: 'inherit',
            }),
            statusLink: link(statusPageURL, { color: 'inherit' }),
          }
        )}
      </Typography>
    </Stack>
  );
};

export default connect<unknown, { refresh: () => void }>(null, {
  refresh: () => replace('/'),
})(Maintenance);
