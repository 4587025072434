import { alpha, darken, lighten, Theme } from '@rossum/ui/material';
import { DataGridProProps, gridClasses } from '@rossum/ui/x-data-grid-pro';

const {
  columnHeader,
  cellCheckbox,
  columnHeaderCheckbox,
  cell,
  pinnedColumnHeaders,
  iconSeparator,
  pinnedColumns,
  virtualScrollerContent,
  main,
  virtualScroller,
  columnSeparator,
  virtualScrollerRenderZone,
  row,
  columnHeadersInner,
  columnHeaders,
} = gridClasses;

export const HEADER_COLUMN_HEIGHT = 56;
export const ROW_HEIGHT = 48;

export const virtualScrollerOnResizeStyles: DataGridProProps['sx'] = {
  // fix the missing border when resizing columns
  [`.${virtualScrollerRenderZone}`]: {
    width: '100%',
  },
  [`.${pinnedColumnHeaders}`]: {
    backgroundColor: (theme: Theme) => theme.palette.background.paper,
  },
  [`.${row}`]: {
    position: 'relative',
    minWidth: '100%',
    '&::after': {
      content: '""',
      position: 'absolute',
      height: '1px',
      borderBottom: '1px solid',
      borderColor: theme => {
        if (theme.palette.mode === 'light') {
          return lighten(alpha(theme.palette.divider, 1), 0.88);
        }
        return darken(alpha(theme.palette.divider, 1), 0.68);
      },
      width: '100%',
      bottom: 0,
    },
  },
};

const transparentBorderStyles = {
  borderColor: 'transparent',
  '&': {
    // custom border on row and remove border on cell is needed to prevent wrong line height calculation
    [`.${row}`]: {
      borderBottom: (t: Theme) => `1px solid ${t.palette.divider}`,
      [`.${cell}`]: {
        border: 'none',
      },
    },
    [`.${columnHeader}:focus, .${cell}:focus, .${cellCheckbox}:focus-within, .${columnHeaderCheckbox}:focus-within`]:
      {
        outline: 'none',
      },
  },
};

const pinnedColumnsDataGridStyles = {
  [`& .${pinnedColumns}, .${pinnedColumnHeaders}`]: {
    backgroundImage: 'none',
    boxShadow: 'none',
    backgroundColor: 'inherit',
  },
  // hide separators on pinned columns
  [`.${gridClasses['pinnedColumnHeaders--right']} .${columnSeparator}:not(.${gridClasses['columnSeparator--resizable']}) .${iconSeparator}`]:
    {
      display: 'none',
    },
};

const backgroundInheritanceStyles = {
  backgroundColor: 'inherit',
  [`.${columnHeadersInner}, .${columnHeaders}`]: {
    backgroundColor: 'inherit',
  },
  [`& .${virtualScrollerContent}, .${main}, .${virtualScroller}`]: {
    // This css property prevents f.e. browser navigation with swipe gesture when user reaches the end of the container
    overscrollBehaviorX: 'contain',
    backgroundColor: 'inherit',
  },
};

const clearedCellOutlineStyles = {
  [`&.${gridClasses.root} .${gridClasses.cell}`]: {
    outline: 'none',
  },
};

export const commonDataGridStyles = {
  ...clearedCellOutlineStyles,
  ...backgroundInheritanceStyles,
  ...pinnedColumnsDataGridStyles,
  ...transparentBorderStyles,
};

export const restrictedRowClass = 'restricted-row';

export const restrictedRowStyles: DataGridProProps['sx'] = {
  [`& .${restrictedRowClass}`]: {
    [`& .${gridClasses.cell}`]: {
      border: 'none',
    },
  },
};
