import { ScorePasswordResponse } from '@rossum/api-client/authentication';
import { PasswordStrengthProgress } from '@rossum/ui';
import { FieldLabel } from '@rossum/ui';
import { Visibility, VisibilityOff } from '@rossum/ui/icons';
import { IconButton, Stack, TextField } from '@rossum/ui/material';
import { useState } from 'react';
import { Controller, FieldPath, FieldValues } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { ControlledFieldProps } from '../ReactHookForm/utils';

type PasswordValidationFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
  data: ScorePasswordResponse | undefined;
} & ControlledFieldProps<TFieldValues, TName>;

const progressByScore = [
  {
    progress: 0,
    color: 'error',
    message: 'containers.personalInfo.confirmPassword.weak',
  },
  {
    progress: 30,
    color: 'error',
    message: 'containers.personalInfo.confirmPassword.weak',
  },
  {
    progress: 60,
    color: 'warning',
    message: 'containers.personalInfo.confirmPassword.soSo',
  },
  {
    progress: 90,
    color: 'success',
    message: 'containers.personalInfo.confirmPassword.good',
  },
  {
    progress: 100,
    color: 'success',
    message: 'containers.personalInfo.confirmPassword.strong',
  },
] as const;

const PasswordValidationField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  data,
  ControllerProps,
}: PasswordValidationFieldProps<TFieldValues, TName>) => {
  const { control, name } = ControllerProps;
  const [showPassword, setShowPassword] = useState(false);
  const intl = useIntl();

  const progressObject = data ? progressByScore[data.score] : undefined;
  const color = progressObject?.color ?? 'error';

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, formState, fieldState }) => {
        return (
          <Stack spacing={field.value.length > 0 ? 1 : 0}>
            <FieldLabel htmlFor="newPassword" label="" layout="none">
              <TextField
                {...field}
                type={showPassword ? 'text' : 'password'}
                id="newPassword"
                error={
                  fieldState.isTouched &&
                  (color !== 'success' || fieldState.invalid)
                }
                placeholder={intl.formatMessage({
                  id: 'containers.personalInfo.newPassword.placeholder',
                })}
                disabled={formState.isSubmitting}
                data-cy="new-password-input"
                autoComplete="new-password"
                helperText={
                  fieldState.invalid &&
                  fieldState.error &&
                  fieldState.error.message
                }
                size="small"
                InputProps={{
                  endAdornment:
                    field.value !== '' ? (
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                        data-cy="show-password-toggler"
                        sx={{
                          '&:hover': {
                            backgroundColor: 'transparent',
                          },
                        }}
                      >
                        {showPassword ? (
                          <VisibilityOff
                            fontSize="small"
                            sx={{
                              color: 'text.secondary',
                              '&:hover': {
                                color: 'text.primary',
                                transition: 'smooth',
                              },
                            }}
                            data-cy="eye-off"
                          />
                        ) : (
                          <Visibility
                            fontSize="small"
                            sx={{
                              color: 'text.secondary',
                              '&:hover': {
                                color: 'text.primary',
                                transition: 'smooth',
                              },
                            }}
                            data-testid="eye-on"
                          />
                        )}
                      </IconButton>
                    ) : null,
                }}
              />
            </FieldLabel>

            <PasswordStrengthProgress
              value={field.value}
              messages={data?.messages ?? []}
              progress={progressObject?.progress ?? 0}
              scoreTranslation={
                progressObject?.message
                  ? intl.formatMessage({
                      id: progressObject.message,
                    })
                  : ''
              }
              scoreColor={color}
            />
          </Stack>
        );
      }}
    />
  );
};

export default PasswordValidationField;
