import {
  extensionFunctionType,
  extensionWebhookType,
  HookType,
} from '@rossum/api-client/hooks';
import { Add } from '@rossum/ui/icons';
import { Button } from '@rossum/ui/material';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import firstFunctionIcon from '../../../assets/svg/extensions-function.svg';
import firstWebhookIcon from '../../../assets/svg/extensions-webhook.svg';
import { FUNCTIONS_LINK, WEBHOOKS_LINK } from '../../../constants/values';
import { linebreak, link } from '../../../lib/formaterValues';
import { extensionsCreatePath } from '../helpers';
import style from './style.module.sass';

type FirstExtensionProps = {
  extensionType: HookType;
};

const bannerImages: Record<HookType, { src: string; alt: string }> = {
  [extensionFunctionType]: {
    src: firstFunctionIcon,
    alt: 'first-function-icon',
  },
  [extensionWebhookType]: { src: firstWebhookIcon, alt: 'first-webhook-icon' },
};

const FirstExtension = ({ extensionType }: FirstExtensionProps) => (
  <div className={style.FirstExtensionWrapper}>
    {bannerImages[extensionType] && (
      <img
        src={bannerImages[extensionType].src}
        alt={bannerImages[extensionType].alt}
        className={style.FirstExtensionBannerImage}
      />
    )}
    <div
      className={clsx(
        style.FirstExtensionDescription,
        extensionType === extensionWebhookType &&
          style.FirstExtensionDescriptionWebhook
      )}
    >
      <span className={style.FirstExtensionDescriptionTitle}>
        <FormattedMessage
          id={`containers.settings.extensions.intro.firstExtension.description.title.${extensionType}`}
        />
      </span>
      <span className={style.FirstExtensionDescriptionText}>
        <FormattedMessage
          id={`containers.settings.extensions.intro.firstExtension.description.text.${extensionType}`}
          values={{ linebreak }}
        />
      </span>
    </div>
    <div className={style.FirstExtensionFooter}>
      <span className={style.FirstExtensionReadMore}>
        <FormattedMessage
          id={`containers.settings.extensions.intro.firstExtension.readMore.${extensionType}`}
          values={{
            link: link(
              extensionType === extensionFunctionType
                ? FUNCTIONS_LINK
                : WEBHOOKS_LINK
            ),
          }}
        />
      </span>

      <Link to={`${extensionsCreatePath()}#${extensionType}`}>
        <Button
          variant="outlined"
          color="secondary"
          startIcon={<Add />}
          data-cy={`extensions-create-first-button-intro-banner-${extensionType}`}
          size="small"
        >
          <FormattedMessage
            id={`containers.settings.extensions.intro.firstExtension.createButton.${extensionType}`}
          />
        </Button>
      </Link>
    </div>
  </div>
);

type Props = {
  dataCy?: string;
};

const FirstExtensionBanner = ({ dataCy }: Props) => (
  <div className={style.FirstExtensionBanner} data-cy={dataCy}>
    <span className={style.FirstExtensionBannerTitle}>
      <FormattedMessage id="containers.settings.extensions.intro.firstExtension.title" />
    </span>
    <span className={style.FirstExtensionBannerText}>
      <FormattedMessage id="containers.settings.extensions.intro.firstExtension.text" />
    </span>
    <div className={style.FirstExtensionContent}>
      <FirstExtension extensionType={extensionFunctionType} />
      <FirstExtension extensionType={extensionWebhookType} />
    </div>
  </div>
);

export default FirstExtensionBanner;
