import { HookTemplate } from '@rossum/api-client/hookTemplates';
import { useSelector } from 'react-redux';
import { useExtensionsFeatureSubscription } from '../../../../../features/pricing/hooks/useExtensionsFeatureSubscription';
import {
  duplicateHandlingFeatureSelector,
  mailboxFeatureSelector,
} from '../../../../../features/pricing/selectors';

const DUPLICATE_HANDLING_NAME = 'Duplicate Handling';
const mailboxExtensions = ['Advanced Email Filtering', 'Email Notifications'];

export const useIsHookTemplateRestricted = (
  hookTemplate: HookTemplate | null
) => {
  const extensionsSubscription = useExtensionsFeatureSubscription();
  const isDuplicateFeaturePurchased = useSelector(
    duplicateHandlingFeatureSelector
  );
  const isMailboxFeaturePurchased = useSelector(mailboxFeatureSelector);

  const restrictDuplicateHandling =
    hookTemplate &&
    !isDuplicateFeaturePurchased &&
    hookTemplate.name === DUPLICATE_HANDLING_NAME;

  const restrictMailboxExtensions =
    hookTemplate &&
    !isMailboxFeaturePurchased &&
    mailboxExtensions.includes(hookTemplate.name);

  return (
    !extensionsSubscription.purchased ||
    restrictDuplicateHandling ||
    restrictMailboxExtensions
  );
};
