import { getIDFromUrl } from '@rossum/api-client';
import { Add, Close } from '@rossum/ui/icons';
import {
  Button,
  Chip,
  IconButton,
  Pagination,
  Paper,
  Stack,
  Tooltip,
} from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { useHooks } from '../../business/hooks/useHooks';
import { PageLayoutV2 } from '../../components/PageLayoutV2/PageLayoutV2';
import HelpLinkTooltip from '../../components/UI/HelpLinkTooltip';
import InfoBox from '../../components/UI/InfoBox/index';
import PageSizeSelector from '../../components/UI/PageSizeSelector';
import { FUNCTIONS_LINK, WEBHOOKS_LINK } from '../../constants/values';
import { UnpaidFeatureOverlay } from '../../features/pricing/components/UnpaidFeatureOverlay';
import { useExtensionsFeatureSubscription } from '../../features/pricing/hooks/useExtensionsFeatureSubscription';
import { useWorkspacesWithQueues } from '../../features/queues/hooks/useWorkspacesWithQueues';
import { QueueSelect } from '../../features/queues/select-queue/QueueSelect';
import { ResearchCallSurvey } from '../../features/surveys/ResearchCallSurvey/ResearchCallSurvey';
import { link } from '../../lib/formaterValues';
import { isTrialSelector } from '../../redux/modules/organization/selectors';
import { updateUiSettings } from '../../redux/modules/user/actions';
import { displayExtensionsIntroBannerSelector } from '../../redux/modules/user/selectors';
import { State } from '../../types/state';
import { HeaderTitle } from '../../ui/header/Header';
import SearchInput from '../../ui/search-input/SearchInput';
import EventsSelect from './components/EventsSelect';
import IntroToExtensions from './components/IntroToExtensions';
import ViewSwitcher from './components/ViewSwitcher';
import DependenciesGraph from './DependenciesGraph';
import { useGraphHooks } from './DependenciesGraph/hooks';
import { ExtensionsHeader } from './ExtensionsHeader';
import ExtensionsList from './ExtensionsList';
import { extensionsCreatePath } from './helpers';
import { ExtensionEventAction, webhookNormalizedEvents } from './lib/helpers';
import { useExtensionSearchParams } from './useExtensionSearchParams';

type StateProps = {
  displayIntroBanner: boolean;
  isTrial: boolean;
};

type DispatchProps = {
  setHideIntroBanner: () => void;
};

type Props = StateProps & DispatchProps;

const Extensions = ({
  displayIntroBanner,
  isTrial,
  setHideIntroBanner,
}: Props) => {
  const { data: activeHooks } = useHooks({
    active: true,
  });

  const activeExtensionsCount = activeHooks?.pagination.total;

  const [parsedParams, updateParsedParams] = useExtensionSearchParams();

  const hookQuery = {
    pageSize: parsedParams.pageSize,
    page: parsedParams.page,
    name: parsedParams.name,
    queue: parsedParams.queues,
    events: parsedParams.events,
  };

  const { data: extensions, isSuccess: extensionsLoaded } = useHooks(hookQuery);

  // Technically, we don't need all queues but they will be loaded in the select component anyways.
  const { queues, workspacesWithQueues } = useWorkspacesWithQueues({
    enableQueries: true,
  });

  const filtersActive =
    parsedParams.queues !== undefined ||
    parsedParams.events !== undefined ||
    parsedParams.name !== '';

  const displaySearch =
    parsedParams.view === 'graph'
      ? false
      : !!extensions?.results.length || filtersActive || !extensionsLoaded;

  const extensionsSubscription = useExtensionsFeatureSubscription();
  const isFeatureEnabled = extensionsSubscription.canAccess;

  const intl = useIntl();

  const {
    isLoading,
    isFetching,
    graphDataGroupedPerEvents,
    originalExtensions,
  } = useGraphHooks({
    queueId: parsedParams.queues,
    enabled: parsedParams.view === 'graph',
  });

  return (
    <PageLayoutV2
      fullWidth={isFeatureEnabled}
      renderHeader={params => (
        <ExtensionsHeader
          {...params}
          value="myExtensions"
          title={hasScrolled => (
            <Stack direction="row" alignItems="center" gap={1}>
              <HeaderTitle
                hasScrolled={hasScrolled}
                title={intl.formatMessage({
                  id: `containers.settings.sidebar.myExtensions`,
                })}
              />
              {activeExtensionsCount !== undefined && (
                <Chip
                  label={`${activeExtensionsCount}`}
                  data-cy="extensions-active-count"
                />
              )}
              <HelpLinkTooltip
                id="containers.settings.extensions.helpTooltip"
                values={{
                  webhooksLink: link(WEBHOOKS_LINK),
                  functionsLink: link(FUNCTIONS_LINK),
                }}
              />
            </Stack>
          )}
          buttons={
            isFeatureEnabled
              ? [
                  <ViewSwitcher
                    key="view-switcher"
                    activeView={parsedParams.view}
                    onViewChange={value =>
                      updateParsedParams({ view: value, page: 1 })
                    }
                  />,
                  <Stack
                    direction="row"
                    alignItems="center"
                    key="create-extension"
                  >
                    <Tooltip
                      title={
                        extensionsSubscription.purchased
                          ? ''
                          : intl.formatMessage({
                              id: 'containers.settings.extensions.createExtensionDisabled',
                            })
                      }
                    >
                      <span>
                        <Button
                          component={Link}
                          disabled={!extensionsSubscription.purchased}
                          to={extensionsCreatePath()}
                          variant="contained"
                          startIcon={<Add />}
                          data-cy="extensions-add-extension"
                          sx={{
                            '&:hover': {
                              color: theme =>
                                theme.palette.primary.contrastText,
                            },
                          }}
                        >
                          {intl.formatMessage({
                            id: 'containers.settings.extensions.createExtension',
                          })}
                        </Button>
                      </span>
                    </Tooltip>
                  </Stack>,
                ]
              : []
          }
        />
      )}
      renderFooter={() => {
        if (parsedParams.view !== 'list' || !isFeatureEnabled) return null;
        return (
          <Stack
            direction="row"
            alignItems="center"
            spacing={3}
            p={2.5}
            alignSelf="flex-end"
          >
            <PageSizeSelector
              setPageSize={(pageSize: string) =>
                updateParsedParams({ pageSize: Number(pageSize), page: 1 })
              }
              pageSize={`${parsedParams.pageSize}`}
            />
            {extensions && (
              <Pagination
                page={parsedParams.page}
                count={extensions.pagination.totalPages}
                onChange={(_, page: number) => updateParsedParams({ page })}
              />
            )}
          </Stack>
        );
      }}
    >
      {isFeatureEnabled ? (
        <>
          <Stack spacing={3} p={4}>
            <Stack direction="row" justifyContent="space-between" spacing={2}>
              <Stack direction="row" spacing={2}>
                <Stack minWidth={300}>
                  <QueueSelect
                    onChange={queueUrl => {
                      updateParsedParams({
                        queues: queueUrl ? getIDFromUrl(queueUrl) : undefined,
                        page: 1,
                      });
                    }}
                    value={
                      queues?.find(queue => queue.id === parsedParams.queues)
                        ?.url ?? null
                    }
                  />
                </Stack>
                {parsedParams.view === 'list' && (
                  <EventsSelect
                    value={parsedParams.events ?? 'all'}
                    onChange={({ target: { value } }) =>
                      updateParsedParams({
                        events: value as ExtensionEventAction,
                        page: 1,
                      })
                    }
                    options={['all', ...webhookNormalizedEvents] as const}
                    closeIcon={
                      parsedParams.events && (
                        <IconButton
                          size="small"
                          onClick={() =>
                            updateParsedParams({ events: undefined, page: 1 })
                          }
                          sx={{ position: 'absolute', right: 30 }}
                        >
                          <Close fontSize="small" />
                        </IconButton>
                      )
                    }
                  />
                )}
              </Stack>
              {displaySearch && (
                <SearchInput
                  placeholder={intl.formatMessage({
                    id: 'containers.settings.extensions.search',
                  })}
                  value={parsedParams.name}
                  onChange={search =>
                    updateParsedParams({ name: search || undefined, page: 1 })
                  }
                  inputProps={{
                    'data-cy': 'search-input',
                  }}
                />
              )}
            </Stack>
            {isTrial && (
              <InfoBox
                title="containers.settings.extensions.upgrade.title"
                text="containers.settings.extensions.upgrade.text"
              />
            )}
            {displayIntroBanner && (
              <IntroToExtensions
                dataCy="extensions-intro-banner"
                onClose={setHideIntroBanner}
              />
            )}
            {parsedParams.view === 'list' ? (
              <ExtensionsList
                hookQuery={hookQuery}
                extensions={extensions?.results ?? []}
                extensionsLoaded={extensionsLoaded}
                filtersActive={filtersActive}
                workspacesWithQueues={workspacesWithQueues}
              />
            ) : (
              <DependenciesGraph
                isLoading={isLoading}
                isFetching={isFetching}
                graphDataGroupedPerEvents={graphDataGroupedPerEvents}
                selectedQueueId={parsedParams.queues}
                originalExtensions={originalExtensions}
              />
            )}
          </Stack>
          <ResearchCallSurvey surveyPlacement="extensions" />
        </>
      ) : (
        <Stack component={Paper} m={4} p={4} justifyContent="center">
          <UnpaidFeatureOverlay
            title={intl.formatMessage({
              id: 'features.pricing.unpaidFeatureOverlay.title.extensions',
            })}
            dataCy="unpaid-overlay-extensions"
          />
        </Stack>
      )}
    </PageLayoutV2>
  );
};

const mapStateToProps = (state: State): StateProps => ({
  displayIntroBanner: displayExtensionsIntroBannerSelector(state),
  isTrial: !!isTrialSelector(state),
});

const mapDispatchToProps: DispatchProps = {
  setHideIntroBanner: () =>
    updateUiSettings({
      hideExtensionsIntroBanner: true,
    }),
};

export default connect<StateProps, DispatchProps, Record<string, never>, State>(
  mapStateToProps,
  mapDispatchToProps
)(Extensions);
