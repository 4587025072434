import { withJsonFormsControlProps } from '@jsonforms/react';
import { Card, CardContent, CardMedia, Typography } from '@rossum/ui/material';

export const ImageControl = withJsonFormsControlProps(
  ({
    data: { text, src },
  }: {
    data: {
      text: string;
      src: string;
    };
  }) => (
    <Card sx={{ maxWidth: 245 }}>
      <CardMedia component="img" alt={text} image={src} />

      <CardContent>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {text}
        </Typography>
      </CardContent>
    </Card>
  )
);
