import { Schema } from '@rossum/api-client/schemas';
import { Url } from '@rossum/api-client/utils';
import {
  Control,
  useController,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import TextFieldControl from '../../../components/ReactHookForm/controls/TextFieldControl';
import { ContentGroup } from '../../../ui/content-group/ContentGroup';
import { QueueSelect } from '../../queues/select-queue/QueueSelect';
import { ThenSection } from '../../rules/ThenSection';
import { RuleFormType } from '../../rules/types';
import { TriggerSection } from '../../rules/WhenSection/TriggerSection';

type RuleTemplateFormProps = {
  control: Control<RuleFormType>;
  schema: Schema | undefined;
  queueUrl: Url | null;
  setQueueUrl: (u: Url | null) => void;
};

export const RuleTemplateForm = ({
  control,
  schema,
  queueUrl,
  setQueueUrl,
}: RuleTemplateFormProps) => {
  const intl = useIntl();
  const triggerConditionField = useController({
    control,
    name: 'triggerCondition',
  });

  // @ts-expect-error This can be partial in RHF.
  const currentRuleConcept: RuleFormType = useWatch({ control });

  const {
    formState: {
      errors: { triggerCondition: triggerConditionValidationError },
    },
  } = useFormContext();

  return (
    <>
      <ContentGroup
        description={intl.formatMessage({
          id: 'features.ruleTemplates.previewQueues.description',
        })}
      >
        <QueueSelect
          id="ruleTemplate.queue"
          onChange={newQueue => setQueueUrl(newQueue)}
          value={queueUrl}
          disableClearable
        />
      </ContentGroup>
      <ContentGroup
        title={intl.formatMessage({
          id: 'features.ruleTemplates.detail.identification.title',
        })}
        description={intl.formatMessage({
          id: 'features.ruleTemplates.detail.identification.description',
        })}
      >
        <TextFieldControl
          ControllerProps={{ control, name: 'name' }}
          label={intl.formatMessage({
            id: 'features.ruleTemplates.detail.identification.name.label',
          })}
          FieldLabelProps={{
            layout: 'floating',
          }}
        />
        <TextFieldControl
          ControllerProps={{ control, name: 'description' }}
          label={intl.formatMessage({
            id: 'features.ruleTemplates.detail.identification.description.label',
          })}
          FieldLabelProps={{
            layout: 'floating',
          }}
          multiline
          maxRows={3}
        />
      </ContentGroup>
      {schema ? (
        <>
          <TriggerSection
            schemaContent={schema.content ?? []}
            queueUrls={schema.queues ?? []}
            schemaUrl={schema.url}
            triggerConditionField={triggerConditionField}
            currentRuleConcept={currentRuleConcept}
            // eslint-disable-next-line @typescript-eslint/no-base-to-string
            validationError={triggerConditionValidationError?.message?.toString()}
          />
          <ThenSection control={control} schemaContent={schema.content ?? []} />
        </>
      ) : null}
    </>
  );
};
