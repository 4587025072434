import { Hook as HookType } from '@rossum/api-client/hooks';
import { useQueryClient } from '@tanstack/react-query';
import { get } from 'lodash';
import { useEffect } from 'react';
import store from '../../../../../redux/configureStore';
import { ApiRequest } from './classes/ApiRequest';
import { ConfigApp } from './classes/ConfigApp';
import { Hook } from './classes/Hook';
import {
  PostMessageClasses,
  resolveResponse,
  rossumRpcMessageType,
  RPCMessageEvent,
} from './postMessagesHelpers';

type UsePostMessageMessage = {
  configAppOrigin: string;
  selectedExtension: HookType;
  setShouldRefetch: (shouldRefetch: boolean) => void;
  onClose: () => void;
  setShouldLeaveSafely: (shouldLeaveSafely: boolean) => void;
};

type MessageListener = {
  configAppOrigin: string;
  postMessageClasses: PostMessageClasses;
};

const isValidSource = (source: MessageEventSource | null): source is Window =>
  source !== null;

const createMessageListener =
  ({ configAppOrigin, postMessageClasses }: MessageListener) =>
  ({ data, origin, source }: RPCMessageEvent) => {
    if (
      configAppOrigin === origin &&
      isValidSource(source) &&
      data?.type === rossumRpcMessageType
    ) {
      const createMessage = (response: unknown, config = { type: 'info' }) =>
        source.postMessage(
          {
            type: rossumRpcMessageType,
            callId: data?.callId,
            methodResponse: {
              [config.type === 'error' ? 'error' : 'value']: response,
            },
          },
          configAppOrigin
        );

      const reportError = (e: unknown) =>
        createMessage(
          {
            reason: `${e}`,
            ...(get(e, ['data']) && { response: get(e, ['data']) }),
            ...(get(e, ['code']) && { code: get(e, ['code']) }),
            meta: data,
          },
          { type: 'error' }
        );

      try {
        resolveResponse(postMessageClasses, data, createMessage, reportError);
      } catch (e) {
        reportError(e);
      }
    }
  };

export const usePostMessages = ({
  configAppOrigin,
  selectedExtension,
  setShouldRefetch,
  onClose,
  setShouldLeaveSafely,
}: UsePostMessageMessage) => {
  const queryClient = useQueryClient();
  useEffect(() => {
    const postMessageClasses: PostMessageClasses = {
      ConfigApp: new ConfigApp({
        store,
        onClose,
        setShouldRefetch,
        setShouldLeaveSafely,
      }),
      Hook: new Hook({ queryClient, selectedExtension }),
      ApiRequest: new ApiRequest(),
    };

    const messageListener = createMessageListener({
      configAppOrigin,
      postMessageClasses,
    });

    window.addEventListener('message', messageListener);

    return () => window.removeEventListener('message', messageListener);
  }, [
    configAppOrigin,
    onClose,
    selectedExtension,
    setShouldRefetch,
    setShouldLeaveSafely,
    queryClient,
  ]);
};
