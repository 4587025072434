import { Box, Button, Stack } from '@rossum/ui/material';
import { isSameDay } from 'date-fns';
import equal from 'fast-deep-equal/es6/react';
import { get, xor } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { connect, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Dispatch } from 'redux';
import { push } from 'redux-first-history';
import ContentWrapper from '../../components/ContentWrapper';
import Datepicker from '../../components/Datepicker';
import {
  CUSTOM,
  predefinedShortcuts,
} from '../../components/Datepicker/helpers';
import PageLayout from '../../components/PageLayout';
import { OptionT } from '../../components/Picker';
import InfoBox from '../../components/UI/InfoBox/index';
import { apiUrl } from '../../constants/config';
import { CONTACT_FORM_LINK } from '../../constants/values';
import { filterWorkspaces } from '../../features/queues/helpers/filterWorkspaces';
import { useWorkspacesWithQueues } from '../../features/queues/hooks/useWorkspacesWithQueues';
import { ResearchCallSurvey } from '../../features/surveys/ResearchCallSurvey/ResearchCallSurvey';
import { asArray, asScalar, parse, stringify } from '../../lib/url';
import {
  canUseUsageReportsSelector,
  isTrialSelector,
  statisticsUserReportsDisabled,
} from '../../redux/modules/organization/selectors';
import {
  formatDateQuery,
  parseDateQuery,
} from '../../redux/modules/statistics/helpers';
import { filterUsers, usersToOptions } from '../../redux/modules/users/helpers';
import { usersSelector } from '../../redux/modules/users/selectors';
import { State } from '../../types/state';
import { User } from '../../types/user';
import DisabledOverlay from './components/DisabledOverlay';
import EmptySeries from './components/EmptySeries';
import HidingButtons from './components/HidingButtons';
import StatsDatePanel from './components/StatsDatePanel';
import {
  AutomatedAggregate,
  CorrectionsAggregate,
  DelayAggregate,
  RejectionAggregate,
  TimeAggregate,
  TimePerDocumentAggregate,
  UsageAggregate,
} from './containers/Aggregates';
import Granularity, {
  granularities,
  GranularityT,
} from './containers/Granularity';
import AutomatedGraph from './containers/Graphs/AutomatedGraph';
import CorrectionsGraph from './containers/Graphs/CorrectionsGraph';
import DelayGraph from './containers/Graphs/DelayGraph';
import RejectionGraph from './containers/Graphs/RejectionGraph';
import TimeAndCorrectionsPerFieldTable from './containers/Graphs/TimeAndCorrectionsPerFieldTable';
import TimeGraph from './containers/Graphs/TimeGraph';
import TimePerDocumentGraph from './containers/Graphs/TimePerDocument';
import UsageGraph from './containers/Graphs/UsageGraph';
import QueuePicker from './containers/QueuePicker';
import Sidebar from './containers/Sidebar';
import UserPicker from './containers/UserPicker';
import { useUsageReportExport } from './hooks/useUsageReportExport';
import styles from './styles.module.sass';

const GRAPH_CONTAINER_WIDTH = 1100;

type StateProps = {
  allUsers: User[];
  beginDate: string | null;
  canUseUsageReports: boolean | undefined;
  defaultQueues: Array<number>;
  defaultUsers: Array<number>;
  endDate: string | null;
  groupBy: GranularityT;
  userReportsDisabled: boolean;
  isTrial: boolean;
  usersLoaded: boolean;
};

type OwnProps = RouteComponentProps;

type DispatchProps = {
  setSelectionToQuery: (query: Record<string, unknown>) => void;
};

type Props = OwnProps & DispatchProps & StateProps;

type SelectedDateRange = {
  beginDate: string;
  endDate: string;
};

const workspacesToOptions = <
  T extends { name: string; queues: { name: string; id: number }[] },
>(
  workspaces: T[]
): OptionT[] =>
  workspaces
    .filter(({ queues }) => queues.length)
    .map(({ name, queues }) => ({
      label: name,
      value: queues.map(({ name: _name, id }) => ({
        label: _name,
        value: id,
      })),
    }));

const Statistics = ({
  allUsers,
  beginDate,
  defaultQueues,
  defaultUsers,
  endDate,
  groupBy,
  location,
  setSelectionToQuery,
  usersLoaded,
  isTrial,
  canUseUsageReports,
  userReportsDisabled,
}: Props) => {
  const intl = useIntl();
  const [queues, setQueues] = useState(defaultQueues);
  const [users, setUsers] = useState(defaultUsers);
  const [activeGranularity, setActiveGranularity] =
    useState<GranularityT>(groupBy);
  const [selectedDateRange, setSelectedDateRange] =
    useState<SelectedDateRange | null>(
      beginDate && endDate
        ? {
            beginDate,
            endDate,
          }
        : null
    );

  const [queuesSearch, setQueuesSearch] = useState('');
  const [usersSearch, setUsersSearch] = useState('');
  const [queuePickerKey, setQueuePickerKey] = useState(Date.now());

  const { isExporting, triggerExport } = useUsageReportExport();

  const statistics = useSelector((state: State) => state.statistics);

  const hasSomeStatisticsData = statistics.series.length > 0;
  const isTemporary = statistics.isTmpState;
  const { series, totals } = statistics;

  const { workspacesWithQueues: workspaces } = useWorkspacesWithQueues({
    enableQueries: true,
  });

  const queuesOptions = useMemo<OptionT[]>(
    () =>
      workspacesToOptions(
        queuesSearch
          ? filterWorkspaces(queuesSearch, workspaces ?? [])
          : workspaces ?? []
      ),
    [queuesSearch, workspaces]
  );

  const usersOptions = useMemo<OptionT[]>(
    () =>
      usersToOptions(
        usersSearch ? filterUsers(usersSearch, allUsers) : allUsers
      ),
    [allUsers, usersSearch]
  );

  useEffect(() => setActiveGranularity(groupBy), [groupBy]);

  useEffect(
    () =>
      setSelectedDateRange(
        beginDate && endDate
          ? {
              beginDate,
              endDate,
            }
          : null
      ),
    [beginDate, endDate]
  );

  useEffect(() => {
    setQueues(defaultQueues);
    if (!equal(defaultQueues, queues)) {
      setQueuePickerKey(Date.now());
    }
  }, [defaultQueues]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setUsers(defaultUsers);
  }, [defaultUsers]);

  const selectionNotChanged =
    !xor(defaultQueues, queues).length &&
    !xor(defaultUsers, users).length &&
    groupBy === activeGranularity &&
    beginDate === selectedDateRange?.beginDate &&
    endDate === selectedDateRange?.endDate;

  const showBanner = isTrial || canUseUsageReports === undefined;
  const statisticsDisabled = canUseUsageReports === false;
  const isTmpState = statisticsDisabled || isTemporary;

  const downloadGroupBy = [groupBy, 'workspace', 'queue'];
  const downloadGroupByWithUsers = [...downloadGroupBy, 'user'];

  return (
    <PageLayout data-page-title="statistics">
      {statisticsDisabled && <DisabledOverlay link={CONTACT_FORM_LINK} />}
      <Sidebar>
        <QueuePicker
          titleId="containers.statistics.sidebar.title.queues"
          onSearch={setQueuesSearch}
          options={queuesOptions}
          isSearched={!!queuesSearch}
          picked={queues}
          onPick={setQueues}
          key={queuePickerKey}
          queuesLoaded={workspaces !== undefined}
        />
        {!userReportsDisabled && (
          <UserPicker
            isSearched={false}
            onSearch={setUsersSearch}
            options={usersOptions}
            picked={users}
            onPick={setUsers}
            usersLoaded={usersLoaded}
          />
        )}
        {statisticsDisabled && (
          <div className={styles.DisabledOverlaySidebar} />
        )}
      </Sidebar>
      <ContentWrapper>
        <Box sx={{ width: '100%', height: '100%', overflowY: 'auto' }}>
          {showBanner && (
            <Stack
              className={styles.StatisticsInfoBox}
              sx={{ pt: 2, marginLeft: '20px' }}
            >
              <InfoBox
                title="containers.statistics.upgrade.title"
                text="containers.statistics.upgrade.text"
              />
            </Stack>
          )}
          <Stack className={styles.Header} direction="row">
            <Stack
              spacing={2}
              direction="row"
              className={
                selectedDateRange && activeGranularity
                  ? styles.Dates
                  : styles.DatesHidden
              }
            >
              {selectedDateRange && activeGranularity && (
                <>
                  <Datepicker
                    defaultShortcut={get(
                      predefinedShortcuts.find(
                        ({ from, to }) =>
                          isSameDay(
                            parseDateQuery(beginDate as string),
                            from()
                          ) &&
                          isSameDay(parseDateQuery(endDate as string), to())
                      ),
                      'key',
                      CUSTOM
                    )}
                    endDate={parseDateQuery(selectedDateRange.endDate)}
                    beginDate={parseDateQuery(selectedDateRange.beginDate)}
                    onConfirm={({ from, to }: { from: Date; to: Date }) =>
                      setSelectedDateRange({
                        beginDate: formatDateQuery(from),
                        endDate: formatDateQuery(to),
                      })
                    }
                    DatePanel={StatsDatePanel({
                      beginDate: parseDateQuery(selectedDateRange.beginDate),
                      endDate: parseDateQuery(selectedDateRange.endDate),
                      intl,
                    })}
                    applyButtonTitle="ok"
                  />
                  <Granularity
                    setActiveGranularity={(groupByValue: GranularityT) =>
                      setActiveGranularity(groupByValue)
                    }
                    activeGranularity={activeGranularity}
                  />
                </>
              )}
            </Stack>
            <Button
              variant="outlined"
              color="secondary"
              disabled={isExporting || !selectedDateRange}
              onClick={() =>
                selectedDateRange &&
                triggerExport({
                  filter: {
                    users: users.map(id => `${apiUrl}/users/${id}`),
                    queues: queues.map(id => `${apiUrl}/queues/${id}`),
                    beginDate: asScalar(selectedDateRange.beginDate),
                    endDate: asScalar(selectedDateRange.endDate),
                  },
                  groupBy: userReportsDisabled
                    ? downloadGroupBy
                    : downloadGroupByWithUsers,
                })
              }
              data-cy="statistics-download-usage-report-button"
            >
              {intl.formatMessage({
                id: 'components.statistics.downloadUsageReport',
              })}
            </Button>
          </Stack>
          <Stack maxWidth={GRAPH_CONTAINER_WIDTH}>
            {hasSomeStatisticsData ? (
              <Stack direction="row" flexWrap="wrap" p={2.5} gap={2}>
                <UsageGraph series={series} isTmpState={isTmpState}>
                  <UsageAggregate totals={totals} isTmpState={isTmpState} />
                </UsageGraph>
                <TimeGraph
                  series={series}
                  isTmpState={isTmpState}
                  total={totals.turnaroundAvgS ?? 0}
                >
                  <TimeAggregate totals={totals} isTmpState={isTmpState} />
                </TimeGraph>
                <CorrectionsGraph
                  series={series}
                  isTmpState={isTmpState}
                  total={totals.correctionsPerDocumentAvg ?? 0}
                >
                  <CorrectionsAggregate
                    totals={totals}
                    isTmpState={isTmpState}
                  />
                </CorrectionsGraph>
                <DelayGraph series={series} isTmpState={isTmpState}>
                  <DelayAggregate totals={totals} isTmpState={isTmpState} />
                </DelayGraph>
                <TimePerDocumentGraph
                  series={series}
                  isTmpState={isTmpState}
                  total={totals.timePerDocumentAvgS ?? 0}
                >
                  <TimePerDocumentAggregate
                    totals={totals}
                    isTmpState={isTmpState}
                  />
                </TimePerDocumentGraph>
                <TimeAndCorrectionsPerFieldTable
                  series={series}
                  key={location.search}
                />
                <AutomatedGraph series={series} isTmpState={isTmpState}>
                  <AutomatedAggregate totals={totals} isTmpState={isTmpState} />
                </AutomatedGraph>
                <RejectionGraph series={series} isTmpState={isTmpState}>
                  <RejectionAggregate totals={totals} isTmpState={isTmpState} />
                </RejectionGraph>
              </Stack>
            ) : (
              <EmptySeries inGraph={false} />
            )}
          </Stack>
        </Box>
        {beginDate && endDate && groupBy && selectedDateRange && (
          <HidingButtons
            hideCondition={(selectionNotChanged && !!groupBy) || !usersLoaded}
            disabled={!users.length || !queues.length}
            onClickCancel={() => {
              setUsers(defaultUsers);
              setQueues(defaultQueues);
              setSelectedDateRange({ beginDate, endDate });
              setActiveGranularity(groupBy);
            }}
            onClickApply={() =>
              setSelectionToQuery({
                queues,
                users,
                groupBy: activeGranularity,
                beginDate: selectedDateRange.beginDate,
                endDate: selectedDateRange.endDate,
              })
            }
          />
        )}
      </ContentWrapper>
      <ResearchCallSurvey surveyPlacement="statistics" />
    </PageLayout>
  );
};

const assureArrayOfNumbers = (
  parsedItem: string | Array<string> | null
): Array<number> => asArray(parsedItem).map(Number);

export const mapStateToProps = (state: State): StateProps => {
  const {
    queues = [],
    users = [],
    groupBy,
    beginDate,
    endDate,
  } = parse(state.router.location.search);
  const {
    users: { loaded: usersLoaded },
  } = state;

  const userReportsDisabled = statisticsUserReportsDisabled(state);
  const allUsers = usersSelector(state);
  const allUsersIds = allUsers?.map(({ id }) => id);

  return {
    allUsers: usersLoaded ? allUsers : [],
    beginDate: asScalar(beginDate),
    canUseUsageReports: canUseUsageReportsSelector(state),
    defaultQueues: assureArrayOfNumbers(queues),
    defaultUsers: userReportsDisabled
      ? allUsersIds
      : assureArrayOfNumbers(users),
    userReportsDisabled,
    endDate: asScalar(endDate),
    groupBy:
      granularities.find(granularity => granularity === asScalar(groupBy)) ??
      'week',
    isTrial: !!isTrialSelector(state),
    usersLoaded,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  { location: { search } }: OwnProps
) => ({
  setSelectionToQuery: (query: Record<string, unknown>) =>
    dispatch(push({ search: stringify({ ...parse(search), ...query }) })),
});

export default connect<StateProps, DispatchProps, OwnProps, State>(
  mapStateToProps,
  mapDispatchToProps
)(Statistics);
