import { ExpandMore } from '@mui/icons-material';
import {
  Button,
  ButtonGroup,
  ButtonGroupProps,
  ButtonProps,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

export type SplitButtonAction = {
  label: string;
  callback: (
    e?: React.MouseEvent<HTMLLIElement | HTMLButtonElement, MouseEvent>
  ) => void;
};

export type SplitButtonProps = ButtonGroupProps & {
  // at least one is required
  actions: [SplitButtonAction, ...SplitButtonAction[]];
  defaultLabel?: string;
  ActionButtonProps?: Omit<ButtonProps, 'onClick'>;
  DropdownTriggerButtonProps?: Omit<
    ButtonProps,
    'onClick' | 'aria-controls' | 'aria-expanded' | 'aria-haspopup'
  >;
  defaultActionIndex?: number;
};

export const SplitButton = ({
  actions,
  defaultActionIndex = 0,
  defaultLabel,
  ActionButtonProps,
  DropdownTriggerButtonProps,
  ...buttonGroupProps
}: SplitButtonProps) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (defaultActionIndex >= actions.length) {
      throw new Error(
        '[SplitButton]: `defaultActionIndex` is out of bounds of `actions`'
      );
    }
  }, [actions.length, defaultActionIndex]);

  const handleToggle = () => setOpen(o => !o);

  const handleClose = (event: MouseEvent | TouchEvent) => {
    // @ts-expect-error Delete this shit
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <ButtonGroup ref={anchorRef} {...buttonGroupProps}>
        <Button
          onClick={actions[defaultActionIndex].callback}
          {...ActionButtonProps}
        >
          {defaultLabel ?? actions[defaultActionIndex].label}
        </Button>
        <Button
          onClick={handleToggle}
          sx={{ pl: 1, pr: 1, ...DropdownTriggerButtonProps?.sx }}
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select action"
          aria-haspopup="menu"
          {...DropdownTriggerButtonProps}
        >
          <ExpandMore />
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-end"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ||
                placement === 'bottom-end' ||
                placement === 'bottom-start'
                  ? 'center top'
                  : 'center bottom',
              width: '100%',
            }}
          >
            <Paper elevation={8} sx={{ backgroundImage: 'none' }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {actions.map(action => (
                    <MenuItem
                      key={action.label}
                      onClick={action.callback}
                      disabled={buttonGroupProps.disabled}
                    >
                      <Typography variant="body2">{action.label}</Typography>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
