import { parse } from 'query-string';
import { z } from 'zod';

export const parseQueryStringPreprocessor = (
  value: unknown,
  ctx: z.RefinementCtx
) => {
  if (typeof value === 'string') {
    try {
      return parse(value);
    } catch (e) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: (e as Error).message,
      });
    }
  }

  return value;
};
