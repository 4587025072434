import { endpoints } from '@rossum/api-client';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { notify } from '../../features/toaster';
import { api } from '../../lib/apiClient';
import { throwError } from '../../redux/modules/messages/actions';
import { QUERY_KEY_HOOKS } from './useHooks';

type HookMutationVariables = {
  hookId: number;
};

export const useDeleteHook = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const intl = useIntl();
  return useMutation({
    mutationFn: ({ hookId }: HookMutationVariables) =>
      api.request(endpoints.hooks.delete(hookId)),

    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEY_HOOKS]);
      notify.success({
        title: intl.formatMessage({
          id: 'containers.message.extensionDeleted.title',
        }),
      });
    },

    onError: () => {
      dispatch(throwError('extensionUpdateFailed'));
    },
  });
};
