import { yupResolver } from '@hookform/resolvers/yup';
import {
  CustomEmailTemplateType,
  EmailTemplate,
  EmailTemplateType,
} from '@rossum/api-client/emailTemplates';
import { EmailTemplateStats } from '@rossum/api-client/emailTemplatesStats';
import { Inbox } from '@rossum/api-client/inboxes';
import { Queue } from '@rossum/api-client/queues';
import { Add, InfoOutlined, Settings } from '@rossum/ui/icons';
import {
  alpha,
  Button,
  Divider,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@rossum/ui/material';
import { useQueryClient } from '@tanstack/react-query';
import { isEmpty, isEqual } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useController, useForm } from 'react-hook-form';
import { IntlShape, useIntl } from 'react-intl';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { fromEntries } from 'remeda';
import * as yup from 'yup';
import { useEmailTemplatesStatsPerQueueUnpaginated } from '../../../../business/emailTemplatesStats/useEmailTemplatesStatsPerQueueUnpaginated';
import { QUERY_KEY_QUEUES_UNPAGINATED } from '../../../../business/queues/useUnpaginatedQueues';
import { stripQuery } from '../../../../components/NavBar/navigationStructure';
import ValidationInput from '../../../../components/ReactHookForm/ValidationInput';
import HiddingButton from '../../../../components/UI/HiddingButton';
import { emailDomainName } from '../../../../constants/config';
import { CustomEmailTemplateRow } from '../../../../features/emails/email-templates/components/CustomEmailTemplateRow';
import { DefaultEmailTemplateRow } from '../../../../features/emails/email-templates/components/DefaultEmailTemplateRow';
import { EmailTemplateDrawer } from '../../../../features/emails/email-templates/components/EmailTemplateDrawer';
import { EmailTemplateList } from '../../../../features/emails/email-templates/components/EmailTemplateList';
import {
  EmailTemplateDrawerAction,
  isDefaultEmailTemplateType,
} from '../../../../features/emails/email-templates/components/helpers';
import { useEmailTemplatesPerQueueUnpaginated } from '../../../../features/emails/email-templates/hooks/useEmailTemplatesPerQueueUnpaginated';
import { UnpaidFeatureOverlay } from '../../../../features/pricing/components/UnpaidFeatureOverlay';
import { mailboxFeatureSelector } from '../../../../features/pricing/selectors';
import { linebreak, routerLink } from '../../../../lib/formaterValues';
import { clearValidationsOnChangeCreator } from '../../../../lib/messages';
import { updateInboxAction } from '../../../../redux/modules/inbox/actions';
import {
  ANNOTATIONS_QUERY,
  setItem,
} from '../../../../redux/modules/localStorage/actions';
import { isTrialSelector } from '../../../../redux/modules/organization/selectors';
import { updateQueueDetail as updateQueueDetailAction } from '../../../../redux/modules/queues/actions';
import { clearValidationMessagesAction } from '../../../../redux/modules/validationMessages/action';
import { InputMessages } from '../../../../redux/modules/validationMessages/types';
import { Url } from '../../../../types/basic';
import { InboxParams } from '../../../../types/inbox';
import { State } from '../../../../types/state';
import PaperSection from '../../../../ui/paper-section/PaperSection';
import FormLabel from '../../../User/components/FormLabel';
import DefaultAlignedToggle from '../../components/DefaultAlignedToggle';
import queueStyles from '../../styles.module.sass';
import FilteringRulesEditor from './FilteringRulesEditor';
import { Form } from './formTypes';
import {
  formatEmailList,
  joinElementsByNewline,
  parseEmailList,
  sanitizeValuesToCompare,
} from './helpers';
import IntroBanner from './IntroBanner';
import styles from './styles.module.sass';
import { TrialLimited } from './TrialLimited';

type StateProps = {
  isTrial: boolean;
};

type DispatchProps = {
  updateInbox: (_inboxParams: InboxParams, _url: Url) => void;
  updateQueueDetail: (_queueParams: Partial<Queue>) => void;
};

type OwnProps = {
  clearValidationMessages: typeof clearValidationMessagesAction;
  validationMessages: InputMessages;
  selectedQueue: Queue;
  inbox: Inbox;
};

type Props = DispatchProps & OwnProps & StateProps;

const updateInboxValidationSchema = (intl: IntlShape) =>
  yup.object().shape({
    emailPrefix: yup
      .string()
      .matches(
        // Recreated from Django Email Validator for user part of the email address
        // https://github.com/django/django/blob/main/django/core/validators.py#L178
        new RegExp(
          "(^[-!#$%&'*+/=?^_`{}|~0-9A-Z]+(\\.[-!#$%&'*+/=?^_`{}|~0-9A-Z]+)*$" +
            '|^"([\\x01-\\x08\\x0B\\x0C\\x0E-\\x1F!#-\\[\\]-\\x7F]|\\\\[\\x01-\\x09\\x0B\\x0C\\x0E-\\x7F])*"$)',
          'i'
        ),
        intl.formatMessage({
          id: 'containers.settings.queues.email.messages.emailPrefix.latinAlphabet',
        })
      )
      .required(
        intl.formatMessage({
          id: 'containers.settings.queues.email.messages.emailPrefix.required',
        })
      ),
    name: yup.string().required(
      intl.formatMessage({
        id: 'containers.settings.queues.email.messages.name.required',
      })
    ),
    whiteList: yup.string().notRequired(),
    blackList: yup.string().notRequired(),
    attachmentFilteringEnabled: yup.boolean().notRequired(),
  });

const numberOfUsagesSelector = (emailTemplatesStats: EmailTemplateStats[]) =>
  fromEntries(
    emailTemplatesStats.map(stat => [
      stat.url,
      stat.manualCount + stat.automatedCount,
    ])
  );

const MailboxDisabledOverlay = ({ dataCy }: { dataCy: string }) => {
  const intl = useIntl();
  return (
    <Stack
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 1,
        backgroundColor: t => alpha(t.palette.common.black, 0.5),
        '&&': { m: 0 },
      }}
    >
      <UnpaidFeatureOverlay
        title={intl.formatMessage({
          id: 'features.pricing.unpaidFeatureOverlay.title.mailbox',
        })}
        dataCy={dataCy}
      />
    </Stack>
  );
};

const UpdateInbox = ({
  clearValidationMessages,
  isTrial,
  selectedQueue,
  updateInbox,
  updateQueueDetail,
  validationMessages,
  inbox,
}: Props) => {
  const isMailboxFeaturePurchased = useSelector(mailboxFeatureSelector);

  const [filteringRulesOpen, setFilteringRulesOpen] = useState<boolean>(false);

  const [emailTemplateDrawerAction, setEmailTemplateDrawerAction] =
    useState<EmailTemplateDrawerAction | null>(null);

  const dispatch = useDispatch();

  const queryClient = useQueryClient();
  const { settings, id: queueId, url: queueUrl } = selectedQueue;

  const {
    emailPrefix,
    name,
    filters,
    url: inboxUrl,
    email: inboxEmail,
  } = inbox;

  const rejectionEnabledState = settings.rejectionConfig?.enabled ?? true;
  const intl = useIntl();

  const {
    watch,
    handleSubmit,
    formState: { isValid, isSubmitting, isSubmitSuccessful },
    control,
  } = useForm({
    mode: 'onTouched',
    defaultValues: {
      emailPrefix,
      name,
      rejectionEnabled: rejectionEnabledState,
      whiteList: joinElementsByNewline(filters.allowedSenders),
      blackList: joinElementsByNewline(filters.deniedSenders),
      whiteListEnabled: !!filters.allowedSenders.length,
      blackListEnabled: !!filters.deniedSenders.length,
      attachmentFilteringEnabled: filters.documentRejectionConditions.enabled,
    },
    resolver: yupResolver(updateInboxValidationSchema(intl)),
  });

  const watchValues = watch();

  const {
    field: whiteListField,
    fieldState: { error: whiteListError },
  } = useController({
    name: 'whiteList',
    control,
  });

  const {
    field: blackListField,
    fieldState: { error: blackListError },
  } = useController({
    name: 'blackList',
    control,
  });

  const { data: emailTemplateData, isLoading: isEmailTemplateDataLoading } =
    useEmailTemplatesPerQueueUnpaginated(queueId);

  const {
    data: emailTemplateStatsData,
    isLoading: isEmailTemplateStatsLoading,
  } = useEmailTemplatesStatsPerQueueUnpaginated(
    { queue: queueId },
    {
      select: numberOfUsagesSelector,
    }
  );

  const isLoading = isEmailTemplateDataLoading || isEmailTemplateStatsLoading;

  const handleOnCreateButtonClicked = useCallback(
    (emailTemplateType: CustomEmailTemplateType) => {
      setEmailTemplateDrawerAction({
        type: 'create',
        emailTemplateType,
      });
    },
    []
  );

  useEffect(() => {
    if (isSubmitSuccessful) {
      whiteListField.onChange(
        formatEmailList(watchValues.whiteList, watchValues.whiteListEnabled)
      );
      blackListField.onChange(
        formatEmailList(watchValues.blackList, watchValues.blackListEnabled)
      );
    }
     
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting, isSubmitSuccessful]);

  const canSubmit =
    !isEqual(sanitizeValuesToCompare(watchValues), {
      emailPrefix,
      name,
      rejectionEnabled: rejectionEnabledState,
      whiteList: joinElementsByNewline(filters.allowedSenders),
      blackList: joinElementsByNewline(filters.deniedSenders),
      attachmentFilteringEnabled: filters.documentRejectionConditions.enabled,
    }) &&
    // Do not show the save button until templates are loaded into the form
    !isLoading &&
    isValid &&
    isEmpty(validationMessages);

  const onSubmit = ({
    rejectionEnabled,
    emailPrefix: formValueEmailPrefix,
    name: formValueName,
    whiteList,
    blackList,
    whiteListEnabled,
    blackListEnabled,
    attachmentFilteringEnabled,
  }: Form) => {
    if (!canSubmit) return null;

    const existingAnnotationsQuery = localStorage.getItem(ANNOTATIONS_QUERY);

    const shouldUpdateQueueDetail = rejectionEnabled !== rejectionEnabledState;

    if (existingAnnotationsQuery && shouldUpdateQueueDetail) {
      dispatch(
        setItem(ANNOTATIONS_QUERY, stripQuery(existingAnnotationsQuery))
      );
    }

    if (shouldUpdateQueueDetail) {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_QUEUES_UNPAGINATED],
      });
      updateQueueDetail({
        settings: {
          rejectionConfig: {
            enabled: rejectionEnabled,
          },
        },
      });
    }

    const { enabled, ...filtersParameters } =
      filters.documentRejectionConditions;

    return updateInbox(
      {
        emailPrefix: formValueEmailPrefix,
        name: formValueName,
        filters: {
          allowedSenders: parseEmailList(whiteList, whiteListEnabled),
          deniedSenders: parseEmailList(blackList, blackListEnabled),
          documentRejectionConditions: {
            enabled: attachmentFilteringEnabled,
            ...filtersParameters,
          },
        },
      },
      inboxUrl
    );
  };

  const clearValidationsOnChange = clearValidationsOnChangeCreator(
    clearValidationMessages,
    'inbox',
    validationMessages
  );

  const renderRow = (emailTemplate: EmailTemplate) =>
    isDefaultEmailTemplateType(emailTemplate.type) ? (
      <DefaultEmailTemplateRow
        onEdit={editedEmailTemplate =>
          setEmailTemplateDrawerAction({
            type: 'update',
            emailTemplate: editedEmailTemplate,
          })
        }
        key={emailTemplate.id}
        emailTemplate={emailTemplate}
        numberOfUsages={emailTemplateStatsData?.[emailTemplate.url]}
      />
    ) : (
      <CustomEmailTemplateRow
        key={emailTemplate.id}
        onEdit={editedEmailTemplate =>
          setEmailTemplateDrawerAction({
            type: 'update',
            emailTemplate: editedEmailTemplate,
          })
        }
        emailTemplate={emailTemplate}
        numberOfUsages={emailTemplateStatsData?.[emailTemplate.url]}
      />
    );

  const renderRowsByTypes = (types: EmailTemplateType[]) =>
    (emailTemplateData ?? [])
      .filter(emailTemplate => types.includes(emailTemplate.type))
      .map(emailTemplate => renderRow(emailTemplate));

  const defaultRejectionTemplate = emailTemplateData?.find(
    template => template.type === 'rejection_default'
  );

  return (
    <div data-page-title="queue-emails">
      <form onSubmit={handleSubmit(onSubmit)}>
        <IntroBanner />
        <Stack sx={{ pb: 8.5 }} gap={4}>
          <PaperSection
            title={intl.formatMessage({
              id: 'containers.settings.queues.email.general',
            })}
          >
            <div className={queueStyles.Row}>
              <FormLabel>
                {intl.formatMessage({
                  id: 'containers.settings.queues.email',
                })}
              </FormLabel>
              <div>{inboxEmail}</div>
            </div>
            <div className={queueStyles.Row}>
              <FormLabel>
                {intl.formatMessage({
                  id: 'containers.settings.queues.email.emailPrefix',
                })}
                <Tooltip
                  sx={{ maxWidth: 400 }}
                  placement="top"
                  title={intl.formatMessage(
                    {
                      id: 'containers.settings.queues.email.emailPrefix.tooltip',
                    },
                    { domainName: emailDomainName }
                  )}
                >
                  <InfoOutlined />
                </Tooltip>
              </FormLabel>
              <ValidationInput<Form>
                clearValidationsOnChange={clearValidationsOnChange(
                  'emailPrefix'
                )}
                getErrorMessage={(_, { message }) => message ?? ''}
                name="emailPrefix"
                control={control}
                dataCy="queue-settings-email-prefix-input"
                placeholder={intl.formatMessage({
                  id: 'containers.settings.queues.email.emailPrefix.placeholder',
                })}
              />
            </div>
            <div className={queueStyles.Row}>
              <FormLabel>
                {intl.formatMessage({
                  id: 'containers.settings.queues.email.name',
                })}
                <Tooltip
                  sx={{ maxWidth: 400 }}
                  placement="top"
                  title={intl.formatMessage({
                    id: 'containers.settings.queues.email.name.tooltip',
                  })}
                >
                  <InfoOutlined />
                </Tooltip>
              </FormLabel>
              <ValidationInput<Form>
                clearValidationsOnChange={clearValidationsOnChange('name')}
                getErrorMessage={(_, { message }) => message ?? ''}
                name="name"
                control={control}
                dataCy="queue-settings-name-input"
                placeholder={intl.formatMessage({
                  id: 'containers.settings.queues.email.name.placeholder',
                })}
              />
            </div>
          </PaperSection>
          <PaperSection
            title={intl.formatMessage({
              id: 'containers.settings.queues.email.emailFiltering',
            })}
            sx={{ position: 'relative', overflow: 'hidden' }}
          >
            {!isMailboxFeaturePurchased ? (
              <MailboxDisabledOverlay dataCy="unpaid-feature-overlay-email-filtering" />
            ) : null}
            <Stack width={600} spacing={2}>
              <Typography
                color="text.secondary"
                variant="body2"
                sx={{ mt: 0.5 }}
              >
                {intl.formatMessage({
                  id: 'containers.settings.queues.email.emailFiltering.sublabel',
                })}
              </Typography>
              <Stack spacing={2}>
                <DefaultAlignedToggle
                  name="whiteListEnabled"
                  control={control}
                  dataCy="queue-settings-emails-allowlist"
                >
                  {intl.formatMessage({
                    id: 'containers.settings.queues.email.emailFiltering.whitelist.label',
                  })}
                </DefaultAlignedToggle>
                {watchValues.whiteListEnabled && (
                  <>
                    <span className={styles.WildcardInfo}>
                      {intl.formatMessage({
                        id: 'containers.settings.queues.email.emailFiltering.wildcardInfo',
                      })}
                    </span>
                    <TextField
                      {...whiteListField}
                      size="small"
                      multiline
                      id="whiteList"
                      error={whiteListError !== undefined}
                      helperText={whiteListError?.message ?? ''}
                      placeholder={intl.formatMessage({
                        id: 'containers.settings.queues.email.emailFiltering.placeholder',
                      })}
                    />
                  </>
                )}
              </Stack>
              <Stack spacing={2}>
                <DefaultAlignedToggle
                  name="blackListEnabled"
                  control={control}
                  dataCy="queue-settings-emails-denylist"
                >
                  {intl.formatMessage({
                    id: 'containers.settings.queues.email.emailFiltering.blacklist.label',
                  })}
                </DefaultAlignedToggle>
                {watchValues.blackListEnabled && (
                  <>
                    <span className={styles.WildcardInfo}>
                      {intl.formatMessage({
                        id: 'containers.settings.queues.email.emailFiltering.wildcardInfo',
                      })}
                    </span>
                    <TextField
                      {...blackListField}
                      size="small"
                      multiline
                      id="blackList"
                      error={blackListError !== undefined}
                      helperText={blackListError?.message ?? ''}
                      placeholder={intl.formatMessage({
                        id: 'containers.settings.queues.email.emailFiltering.placeholder',
                      })}
                    />
                  </>
                )}
              </Stack>
            </Stack>
          </PaperSection>
          <PaperSection
            title={intl.formatMessage({
              id: 'containers.settings.queues.email.automaticProcessing',
            })}
            sx={{ position: 'relative', overflow: 'hidden' }}
          >
            {!isMailboxFeaturePurchased ? (
              <MailboxDisabledOverlay dataCy="unpaid-feature-overlay-email-automatic-processing" />
            ) : null}
            <Stack width={600}>
              <Stack spacing={2} divider={<Divider />}>
                <Stack spacing={3}>
                  {isTrial && <TrialLimited />}
                  <Typography variant="body2" color="text.secondary">
                    {intl.formatMessage({
                      id: 'containers.settings.queues.email.automaticProcessing.sublabel',
                    })}
                  </Typography>
                </Stack>
                <Stack spacing={2}>
                  <DefaultAlignedToggle
                    name="attachmentFilteringEnabled"
                    control={control}
                    onClick={() => {
                      setFilteringRulesOpen(true);
                    }}
                    buttonIcon={<Settings />}
                    buttonTitle={intl.formatMessage({
                      id: 'containers.settings.queues.email.automaticProcessing.attachmentFiltering.button',
                    })}
                    dataCy="queue-settings-emails-filtering"
                  >
                    {intl.formatMessage({
                      id: 'containers.settings.queues.email.automaticProcessing.attachmentFiltering.label',
                    })}
                  </DefaultAlignedToggle>
                  <Typography variant="caption" color="text.secondary">
                    {intl.formatMessage({
                      id: 'containers.settings.queues.email.automaticProcessing.attachmentFiltering.description',
                    })}
                  </Typography>
                </Stack>
                <Stack spacing={2}>
                  <EmailTemplateList
                    numberOfSkeletons={1}
                    isLoading={isLoading}
                  >
                    {renderRowsByTypes([
                      'email_with_no_processable_attachments',
                    ])}
                  </EmailTemplateList>

                  <Typography variant="caption" color="text.secondary">
                    {intl.formatMessage(
                      {
                        id: 'containers.settings.queues.email.automaticProcessing.noProcessableDocsNotification.description',
                      },
                      {
                        link: routerLink({
                          route: `/emails/${queueId}`,
                          attributes: {
                            style: {
                              color: 'inherit',
                              textDecoration: 'underline',
                            },
                          },
                        }),
                      }
                    )}
                  </Typography>
                </Stack>
                <Stack spacing={2}>
                  <Typography variant="subtitle2">
                    {intl.formatMessage({
                      id: 'containers.settings.queues.email.emailTemplates.custom.title',
                    })}
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    {intl.formatMessage({
                      id: 'containers.settings.queues.email.emailTemplates.custom.subtitle',
                    })}
                  </Typography>

                  <EmailTemplateList isLoading={isLoading}>
                    {renderRowsByTypes(['custom'])}
                  </EmailTemplateList>
                  <Button
                    startIcon={<Add />}
                    sx={{ alignSelf: 'flex-start' }}
                    color="secondary"
                    onClick={() => handleOnCreateButtonClicked(`custom`)}
                    data-cy="queue-settings-create-custom-template"
                  >
                    {intl.formatMessage({
                      id: 'containers.settings.queues.email.emailTemplates.custom.button',
                    })}
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          </PaperSection>
          <PaperSection
            title={intl.formatMessage({
              id: 'containers.settings.queues.email.documentRejection.title',
            })}
            sx={{ position: 'relative', overflow: 'hidden' }}
          >
            {!isMailboxFeaturePurchased ? (
              <MailboxDisabledOverlay dataCy="unpaid-feature-overlay-email-document-rejection" />
            ) : null}
            <Stack width={600}>
              <Stack spacing={2} divider={<Divider />}>
                <Typography variant="body2" color="text.secondary">
                  {intl.formatMessage(
                    {
                      id: 'containers.settings.queues.email.documentRejection.description',
                    },
                    { linebreak }
                  )}
                </Typography>
                <Stack spacing={2}>
                  <DefaultEmailTemplateRow
                    onEdit={emailTemplate =>
                      setEmailTemplateDrawerAction({
                        type: 'update',
                        emailTemplate,
                      })
                    }
                    formPath="rejectionEnabled"
                    control={control}
                    numberOfUsages={
                      defaultRejectionTemplate
                        ? emailTemplateStatsData?.[defaultRejectionTemplate.url]
                        : undefined
                    }
                    emailTemplate={
                      defaultRejectionTemplate || { type: 'rejection_default' }
                    }
                  />
                  <Typography variant="caption" color="text.secondary">
                    {intl.formatMessage({
                      id: 'containers.settings.queues.email.emailTemplates.rejection.description',
                    })}
                  </Typography>
                </Stack>
                <Stack spacing={2}>
                  <Typography variant="subtitle2">
                    {intl.formatMessage({
                      id: 'containers.settings.queues.email.emailTemplates.rejection.title',
                    })}
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    {intl.formatMessage({
                      id: 'containers.settings.queues.email.emailTemplates.rejection.subtitle',
                    })}
                  </Typography>
                  <EmailTemplateList isLoading={isLoading}>
                    {renderRowsByTypes(['rejection'])}
                  </EmailTemplateList>
                  <Button
                    startIcon={<Add />}
                    sx={{ alignSelf: 'flex-start' }}
                    color="secondary"
                    onClick={() => handleOnCreateButtonClicked(`rejection`)}
                    data-cy="queue-settings-create-rejection-template"
                  >
                    {intl.formatMessage({
                      id: 'containers.settings.queues.email.emailTemplates.rejection.button',
                    })}
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          </PaperSection>
        </Stack>
        <div className={styles.Center}>
          <HiddingButton
            hideCondition={!canSubmit}
            messageId="containers.settings.fields.save"
            type="submit"
          />
        </div>
      </form>
      <FilteringRulesEditor
        closeDrawer={() => {
          setFilteringRulesOpen(false);
        }}
        attachmentFilteringRules={filters.documentRejectionConditions}
        updateInbox={filterRules =>
          updateInbox(
            {
              emailPrefix,
              name,
              filters: {
                allowedSenders: filters.allowedSenders,
                deniedSenders: filters.deniedSenders,
                documentRejectionConditions: {
                  enabled: filters.documentRejectionConditions.enabled,
                  ...filterRules,
                },
              },
            },
            inboxUrl
          )
        }
        validationMessages={validationMessages}
        clearValidationMessages={clearValidationMessages}
        open={filteringRulesOpen}
      />
      <EmailTemplateDrawer
        queueUrl={queueUrl}
        action={emailTemplateDrawerAction}
        DrawerProps={{
          open: emailTemplateDrawerAction !== null,
          onClose: () => {
            setEmailTemplateDrawerAction(null);
          },
        }}
      />
    </div>
  );
};

const mapStateToProps = (state: State) => ({
  isTrial: !!isTrialSelector(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch,
  { selectedQueue }: OwnProps
) => ({
  updateInbox: (inboxParams: InboxParams, url: Url) =>
    dispatch(updateInboxAction(inboxParams, url)),
  updateQueueDetail: (payload: Partial<Queue>) =>
    dispatch(updateQueueDetailAction(selectedQueue.id, payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateInbox);
