import { format } from 'date-fns';
import { useIntl } from 'react-intl';
import { DateFnsLocale,dateFnsLocales } from './dateLocales';

const defaultFormat = 'd MMM, yyyy';

const formats: Partial<Record<DateFnsLocale, string>> = {
  ja: 'yo MMM do',
};

const formatDate = (locale: Locale, dateFormat: string) => (date: string) => {
  return format(new Date(date), dateFormat, {
    locale,
  });
};

export const useIntlDate = () => {
  const intl = useIntl();
  const localeKey = intl.locale;
  const locale = dateFnsLocales[localeKey];
  const localeFormat = formats[localeKey] ?? defaultFormat;

  return { formatDate: formatDate(locale, localeFormat) };
};
