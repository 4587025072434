import { Hook, rossumStore } from '@rossum/api-client/hooks';
import { ListResponse } from '@rossum/api-client/utils';
import { DeleteRounded, SettingsRounded } from '@rossum/ui/icons';
import { Error as ErrorIcon } from '@rossum/ui/icons';
import {
  Chip,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  Skeleton,
  Stack,
  Switch,
  SxProps,
  Theme,
  Tooltip,
  Typography,
} from '@rossum/ui/material';
import { useQueryClient } from '@tanstack/react-query';
import { produce } from 'immer';
import { get } from 'lodash';
import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { debounce } from 'remeda';
import { QUERY_KEY_HOOKS } from '../../business/hooks/useHooks';
import { usePatchHook } from '../../business/hooks/usePatchHook';
import { linebreak } from '../../lib/formaterValues';
import { WorkspaceWithQueues } from '../../types/workspace';
import EmptyExtensionsList from './components/EmptyExtensionsList';
import EventsBadges from './components/EventsDropdown/components/EventsBadges';
import ExpandableWorkspaceBadges from './components/ExpandableWorkspaceBadges';
import FirstExtensionBanner from './components/FirstExtensionBanner';
import StoreAdBanner from './components/StoreAdBanner';
import { fullscreenConfigAppPath } from './containers/ConfigApp/helpers';
import { DeleteExtensionDialog } from './DeleteExtensionDialog';
import { extensionDetailPath } from './helpers';
import { getIcon } from './lib/helpers';

const deleteButtonClassName = 'extension-delete-buttons';

const clearLinkStyles: SxProps<Theme> = {
  color: 'unset',
  '&:hover': {
    color: 'unset',
    textDecoration: 'none',
  },
};
type Props = {
  extensions: Array<Hook>;
  extensionsLoaded: boolean;
  filtersActive: boolean;
  workspacesWithQueues?: WorkspaceWithQueues[];
  hookQuery: unknown;
};

const ExtensionsList = ({
  extensions,
  extensionsLoaded,
  filtersActive,
  workspacesWithQueues,
  hookQuery,
}: Props) => {
  const intl = useIntl();
  const [deletedExtension, setDeletedExtension] = useState<Hook | undefined>(
    undefined
  );

  const queryClient = useQueryClient();
  const { mutate } = usePatchHook();
  const history = useHistory();

  const debouncedUpdate = useMemo(
    () => debounce(mutate, { waitMs: 300 }),
    [mutate]
  );

  if (extensions.length === 0 && extensionsLoaded) {
    return filtersActive ? (
      <EmptyExtensionsList />
    ) : (
      <>
        <StoreAdBanner />
        <FirstExtensionBanner dataCy="extensions-intro-create-banner" />
      </>
    );
  }

  return (
    <Stack spacing={3}>
      <List disablePadding sx={{ whiteSpace: 'nowrap' }}>
        {extensions.map(extension => {
          return (
            <ListItem
              key={extension.id}
              disablePadding
              sx={{
                px: 1,
                '&:hover, &:focus-within': {
                  backgroundColor: 'background.paper',
                  [`& .${deleteButtonClassName}`]: {
                    visibility: 'visible',
                  },
                },
              }}
              alignItems="center"
              data-cy="extensions-table-row"
            >
              <Stack minWidth={50}>
                <Tooltip
                  placement="top"
                  disableInteractive
                  title={intl.formatMessage(
                    {
                      id: 'containers.settings.extensions.activityToggle',
                    },
                    { linebreak }
                  )}
                >
                  <Stack>
                    <Switch
                      disabled={
                        extension.type === 'function' &&
                        (extension.status === 'failed' ||
                          extension.status === 'pending')
                      }
                      checked={extension.active}
                      onChange={() => {
                        // Optimistic update to have UI more responsive.
                        queryClient.setQueryData(
                          [QUERY_KEY_HOOKS, 'list', hookQuery],
                          (state: ListResponse<Hook> | undefined) =>
                            produce(state, ext => {
                              const updated = ext?.results.find(
                                item => item.id === extension.id
                              );
                              if (updated) {
                                updated.active = !extension.active;
                              }
                            })
                        );

                        debouncedUpdate.call({
                          hookId: extension.id,
                          payload: {
                            active: !extension.active,
                            type: extension.type,
                          },
                        });
                      }}
                      size="small"
                      data-cy="extensions-list-toggle"
                    />
                  </Stack>
                </Tooltip>
              </Stack>
              <Stack
                direction="row"
                flex={1}
                alignItems="center"
                overflow="hidden"
              >
                <Stack
                  direction="row"
                  component={Link}
                  to={extensionDetailPath(extension.id)}
                  alignItems="center"
                  sx={{
                    ...clearLinkStyles,
                    minHeight: 60,
                    // to make outline visible we add margin here, otherwise it gets hidden by overflow
                    m: '3px',
                  }}
                  flex="0 1 90%"
                  overflow="hidden"
                >
                  <Stack
                    flex="0 1 60%"
                    overflow="hidden"
                    // to enable text truncation based on flex width we must define a minWidth recursively to the typogrophy child
                    minWidth={0}
                    spacing={1.5}
                    direction="row"
                    sx={{
                      svg: { display: 'block', color: 'text.secondary' },
                    }}
                    alignItems="center"
                  >
                    {extension.type === 'function' &&
                    extension.status === 'pending' ? (
                      <Tooltip
                        placement="top"
                        disableInteractive
                        title={intl.formatMessage({
                          id: `components.editor.infoMessage.pending.${extension.type}`,
                        })}
                      >
                        <CircularProgress size={20} color="inherit" />
                      </Tooltip>
                    ) : (
                      getIcon(
                        extension.type,
                        get(extension.config, 'runtime'),
                        { size: 22 },
                        { disableInteractive: true }
                      )
                    )}
                    {status === 'failed' && (
                      <Tooltip
                        placement="top"
                        disableInteractive
                        title={intl.formatMessage({
                          id: 'containers.settings.extensions.retryTooltip',
                        })}
                      >
                        <ErrorIcon color="error" fontSize="small" />
                      </Tooltip>
                    )}
                    <Stack flex={1} minWidth={0} maxWidth={600}>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <Typography
                          data-cy="extensions-list-name"
                          variant="body2"
                          fontWeight="bold"
                        >
                          {extension.name}
                        </Typography>
                        {extension.extensionSource === rossumStore && (
                          <Chip
                            label={intl.formatMessage({
                              id: 'containers.settings.extensions.title.fromStore.tag',
                            })}
                          />
                        )}
                      </Stack>

                      {extension.description && (
                        <Typography
                          variant="body2"
                          overflow="hidden"
                          whiteSpace="nowrap"
                          textOverflow="ellipsis"
                          sx={{ color: 'text.secondary', flex: 1 }}
                        >
                          {extension.description}
                        </Typography>
                      )}
                    </Stack>
                  </Stack>
                  <Stack flex="0 0 20%" overflow="hidden">
                    <EventsBadges id={extension.id} events={extension.events} />
                  </Stack>
                  <Stack flex="0 0 20%" overflow="hidden">
                    {workspacesWithQueues !== undefined ? (
                      extension.queues?.length > 0 ? (
                        <ExpandableWorkspaceBadges
                          id="expandable-ws-qs-badges-extensions-list"
                          queueUrls={extension.queues}
                          workspaces={workspacesWithQueues}
                          displayPlaceholder={false}
                        />
                      ) : (
                        <Typography
                          variant="body2"
                          color="text.disabled"
                          sx={{ ml: 1 }}
                        >
                          {intl.formatMessage(
                            {
                              id: 'containers.settings.extensions.queues',
                            },
                            { count: extension.queues.length }
                          )}
                        </Typography>
                      )
                    ) : (
                      <Skeleton width={60} height="100%" />
                    )}
                  </Stack>
                </Stack>
                <Stack
                  flex="1 0 10%"
                  direction="row"
                  spacing={1}
                  flexDirection="row-reverse"
                >
                  <Tooltip
                    placement="top"
                    disableInteractive
                    title={intl.formatMessage({
                      id: 'containers.settings.extensions.actionButtons.delete',
                    })}
                  >
                    <IconButton
                      color="secondary"
                      onClick={() => setDeletedExtension(extension)}
                      className={deleteButtonClassName}
                      sx={{
                        visibility: 'hidden',
                      }}
                    >
                      <DeleteRounded />
                    </IconButton>
                  </Tooltip>
                  {extension.config.app?.displayMode === 'fullscreen' && (
                    <Tooltip
                      placement="top"
                      title={intl.formatMessage(
                        {
                          id: 'containers.settings.extensions.configApp.open',
                        },
                        { name: extension.name }
                      )}
                    >
                      <IconButton
                        component={Link}
                        to={{
                          pathname: `${extensionDetailPath(extension.id)}${fullscreenConfigAppPath}`,
                          state: { backLink: history.location.pathname },
                        }}
                        color="secondary"
                        sx={{
                          color: 'secondary.main',
                          '&:hover, &:focus': { color: 'secondary.main' },
                        }}
                      >
                        <SettingsRounded />
                      </IconButton>
                    </Tooltip>
                  )}
                </Stack>
              </Stack>
            </ListItem>
          );
        })}
      </List>

      {extensionsLoaded && <StoreAdBanner withBackground={false} />}

      <DeleteExtensionDialog
        open={deletedExtension !== undefined}
        onClose={() => setDeletedExtension(undefined)}
        extension={deletedExtension}
      />
    </Stack>
  );
};

export default ExtensionsList;
