import { isMobile } from 'react-device-detect';
import { push, replace } from 'redux-first-history';
import { combineEpics } from 'redux-observable';
import * as R from 'remeda';
import { isTruthy } from 'remeda';
import { combineLatest, from, fromEvent, merge, of } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  first,
  map,
  mapTo,
  mergeMap,
  pluck,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { getType } from 'typesafe-actions';
import { getAnnotationBacklink } from '../../../components/AnnotationInformation/components/useAnnotationBacklink';
import { apiUrl, isEmbedded } from '../../../constants/config';
import {
  defaultTheme as _defaultTheme,
  ThemeType,
  themeTypes,
  UPLOAD_HASH,
} from '../../../constants/values';
import { CHANGES_SAVED_PATH } from '../../../containers/ChangesSaved/constants';
import { errorHandler } from '../../../lib/api';
import { isUserLoggedIn, LocationWithReferrerState } from '../../../lib/auth';
import { isNonEmptyArray } from '../../../lib/typeGuards';
import {
  getAnnotationId,
  getCurrentAnnotationId,
  getIDFromString,
  parse,
} from '../../../lib/url';
import { timeSpent } from '../../../timeSpent/timeSpent';
import { EmbeddedConfig } from '../../../types/ui';
import {
  annotationExpired,
  cancelAnnotation,
  cancelAnnotationFulfilled,
  clearAnnotationData,
  confirmAnnotation,
  confirmAnnotationFulfilled,
  deleteAnnotation,
  deleteAnnotationFulfilled,
  displayAnnotation,
  exitAnnotation,
  postponeAnnotation,
  postponeAnnotationFulfilled,
  startAnnotation,
  startAnnotationFulfilled,
} from '../annotation/actions';
import { pagesSelector } from '../annotation/selectors';
import { popAnnotationFromStack } from '../annotations/actions';
import { confirmEditModeFulfilled } from '../editMode/actions';
import {
  ANNOTATIONS_QUERY,
  NAVIGATE_TO_NEXT,
  setItem,
} from '../localStorage/actions';
import { organizationSelector } from '../organization/selectors';
import { fetchQueues } from '../queues/actions';
import { setThemeAction } from '../theme/actions';
import { fetchAllUsers } from '../users/actions';
import { isActionOf, locationChange, makeEpic } from '../utils';
import { fetchWorkspaces } from '../workspaces/actions';
import {
  cancelEditMode,
  enterCreateExtension,
  enterExtension,
  enterLogin,
  enterStatistics,
  enterUser,
  enterUserList,
  enterValidation,
  fetchEmbeddedConfigFullfiled,
  hideHints,
  hideUpload,
  leaveValidation,
  setApplicationTabVisibility,
  setNavigateToNext,
  setRepeatedStatus,
  showHints,
  showUpload,
  startEditMode,
} from './actions';

const fetchDataOnEnterEpic = makeEpic(action$ =>
  action$.pipe(
    filter(
      isActionOf([
        enterUserList,
        enterUser,
        enterStatistics,
        enterCreateExtension,
        enterExtension,
      ])
    ),
    switchMap(() => of(fetchWorkspaces(), fetchQueues()))
  )
);

const fetchWorkspacesAndQueuesOnValidationScreenEpic = makeEpic(
  (action$, state$) =>
    action$.pipe(
      filter(isActionOf(enterValidation)),
      filter(() => !state$.value.workspaces.list.length),
      switchMap(() => of(fetchWorkspaces(), fetchQueues()))
    )
);

const fetchUsersEpicOnRouteEpic = makeEpic(action$ =>
  action$.pipe(
    filter(isActionOf([enterStatistics, enterExtension, enterCreateExtension])),
    mapTo(fetchAllUsers())
  )
);

export const embeddedConfigEpic = makeEpic(
  (action$, state$, { authGetJSON$ }) =>
    action$.pipe(
      filter(isActionOf(enterValidation)),
      filter(isEmbedded),
      map(() => state$.value),
      pluck('router', 'location', 'pathname'),
      map(getCurrentAnnotationId),
      mergeMap(annotationId =>
        authGetJSON$<EmbeddedConfig>(
          `${apiUrl}/annotations/${annotationId}/embedded/config`
        ).pipe(
          map(config => fetchEmbeddedConfigFullfiled(config)),
          catchError(errorHandler)
        )
      )
    )
);

export const embeddedRedirectEpic = makeEpic((action$, state$) =>
  merge(
    action$.pipe(
      filter(isActionOf(cancelAnnotationFulfilled)),
      filter(isEmbedded),
      map(() => state$.value.ui.embeddedConfig?.cancelUrl)
    ),
    action$.pipe(
      filter(isActionOf(confirmAnnotationFulfilled)),
      filter(isEmbedded),
      map(() => state$.value.ui.embeddedConfig?.returnUrl)
    ),
    action$.pipe(
      filter(isActionOf(postponeAnnotationFulfilled)),
      filter(isEmbedded),
      map(() => state$.value.ui.embeddedConfig?.postponeUrl)
    ),
    action$.pipe(
      filter(isActionOf(deleteAnnotationFulfilled)),
      filter(isEmbedded),
      map(() => state$.value.ui.embeddedConfig?.deleteUrl)
    ),
    action$.pipe(
      filter(isActionOf(confirmEditModeFulfilled)),
      filter(isEmbedded),
      map(result => result.payload?.results[0]?.annotation),
      map(getIDFromString),
      withLatestFrom(
        state$.pipe(
          pluck('router', 'location', 'pathname'),
          map(getCurrentAnnotationId)
        )
      ),
      filter(([id, currentAnnotationId]) => id !== currentAnnotationId),
      map(() => state$.value.ui.embeddedConfig?.returnUrl)
    ),
    // Handle leaving of read-only annotation in embedded mode (via the back arrow in the top-left corner)
    action$.pipe(
      filter(isActionOf(displayAnnotation)),
      filter(isEmbedded),
      switchMap(() =>
        action$.pipe(
          filter(isActionOf(leaveValidation)),
          map(() => state$.value.ui.embeddedConfig?.cancelUrl)
        )
      )
    )
  ).pipe(
    switchMap(url => {
      if (url) {
        window.location.replace(url);
        return of({ type: 'REDIRECT_TO_THE_UNIVERSE' });
      }
      return of(replace(CHANGES_SAVED_PATH));
    })
  )
);

const startEditModeWithHashEpic = makeEpic((_, state$) =>
  state$.pipe(
    take(1),
    pluck('router', 'location'),
    filter(location => Boolean(getCurrentAnnotationId(location.pathname))),
    map(location => parse(location.hash).editMode),
    filter(isTruthy),
    switchMap(() =>
      state$.pipe(
        map(state => pagesSelector(state)),
        startWith(pagesSelector(state$.value)),
        filter(isNonEmptyArray),
        filter(() => {
          const { suggestedEdit } = state$.value.annotation;
          if (!suggestedEdit) {
            // Nothing to wait for
            return true;
          }

          if (typeof suggestedEdit === 'string') {
            // Waiting until it's loaded (string represents its URL)
            return false;
          }

          // It's loaded
          return true;
        }),
        take(1),
        map(() =>
          state$.value.ui.readOnly ? replace({ hash: '' }) : startEditMode()
        )
      )
    )
  )
);

export const annotationExpiredRedirectEpic = makeEpic(action$ =>
  action$.pipe(
    filter(isActionOf(annotationExpired)),
    map(() => {
      const defaultListLocation = getAnnotationBacklink();
      return push(isEmbedded() ? '/timeExpired' : defaultListLocation);
    })
  )
);

export const exitAnnotationEpic = makeEpic(action$ =>
  action$.pipe(
    filter(isActionOf(startAnnotationFulfilled)),
    map(({ meta: { url } }) => url),
    mergeMap(url =>
      action$.pipe(
        filter(isActionOf(exitAnnotation)),
        pluck('payload', 'id'),
        filter(id => getAnnotationId(url) === id),
        first(),
        takeUntil(
          action$.pipe(
            filter(
              isActionOf([
                postponeAnnotation,
                deleteAnnotation,
                popAnnotationFromStack,
                confirmAnnotation,
                displayAnnotation,
                annotationExpired,
              ])
            )
          )
        ),
        mapTo(cancelAnnotation(url))
      )
    )
  )
);

const cancelEmbeddedAnnotationEpic = makeEpic(action$ =>
  action$.pipe(
    filter(isActionOf(startAnnotationFulfilled)),
    map(({ meta: { url } }) => url),
    filter(isEmbedded),
    switchMap(url =>
      action$.pipe(
        filter(isActionOf(leaveValidation)),
        first(),
        takeUntil(
          action$.pipe(
            filter(
              isActionOf([
                postponeAnnotation,
                deleteAnnotation,
                confirmAnnotation,
                displayAnnotation,
                annotationExpired,
              ])
            )
          )
        ),
        mapTo(cancelAnnotation(url))
      )
    )
  )
);

const enterLoginEpic = makeEpic((action$, state$) =>
  merge(
    action$.pipe(
      filter(isActionOf(locationChange)),
      filter(({ payload }) => payload.location.pathname === '/')
    ),
    action$.pipe(filter(isActionOf(enterLogin)))
  ).pipe(
    filter(() => !isEmbedded()),
    filter(() =>
      isUserLoggedIn(state$.value.router.location as LocationWithReferrerState)
    ),
    map(() => {
      const pathname = localStorage.getItem(ANNOTATIONS_QUERY)?.split('/')[1];
      return pathname || '/annotations';
    }),
    map(pathname => push(pathname))
  )
);

const enterLoginEmbeddedEpic = makeEpic(action$ =>
  action$.pipe(
    filter(isActionOf(enterLogin)),
    filter(() => isEmbedded()),
    // EF-2448: The user should normally never get into Login page
    // in the embedded mode. But if it happens, redirect them
    // to /timeExpired so that they can retry the whole process.
    mapTo(replace('/timeExpired'))
  )
);

const redirectToMobileScreenEpic = makeEpic(action$ =>
  action$.pipe(
    filter(isActionOf(locationChange)),
    first(),
    filter(() => isMobile),
    filter(() => !isEmbedded()),
    map(({ payload: { location } }) => {
      const { pathname, search, hash } = location;
      const redirectUrl =
        pathname === '/mobileIntroScreen'
          ? undefined
          : `${pathname}${search}${hash}`;

      return push('/mobileIntroScreen', { redirectUrl });
    })
  )
);

const cacheNavigateToNextEpic = makeEpic(action$ =>
  action$.pipe(
    filter(isActionOf(setNavigateToNext)),
    map(({ payload }) => setItem(NAVIGATE_TO_NEXT, String(payload)))
  )
);

const hideHintsEpic = makeEpic(action$ =>
  action$.pipe(
    filter(isActionOf([showUpload, startEditMode])),
    mapTo(hideHints())
  )
);

const showUploadEpic = makeEpic((action$, state$) =>
  action$.pipe(
    filter(isActionOf(showUpload)),
    map(() => state$.value.router.location.search),
    map(search => push({ search, hash: UPLOAD_HASH }))
  )
);

const hideUploadEpic = makeEpic((action$, state$) =>
  action$.pipe(
    filter(isActionOf(hideUpload)),
    map(() => state$.value.router.location.search),
    map(search => push({ search, hash: '' }))
  )
);

const removeDatapointPathEpic = makeEpic((action$, state$) =>
  action$.pipe(
    filter(isActionOf(startEditMode)),
    map(() => {
      const search = new URLSearchParams(state$.value.router.location.search);
      search.delete('datapointPath');

      return replace({
        pathname: state$.value.router.location.pathname,
        search: search.toString(),
      });
    })
  )
);

const showHintsEpic = makeEpic(action$ =>
  action$.pipe(
    filter(isActionOf([hideUpload, cancelEditMode])),
    mapTo(showHints())
  )
);

const cancelEditModeEpic = makeEpic((action$, state$) =>
  action$.pipe(
    filter(isActionOf([exitAnnotation, confirmEditModeFulfilled])),
    filter(() => state$.value.ui.editModeActive),
    map(action =>
      // Timer for the current annotation should be already stoped by confirmEditMode action
      // when confirmEditModeFulfilled is dispatched.
      cancelEditMode(
        action.type === getType(exitAnnotation) ? action.payload.id : undefined
      )
    )
  )
);

const confirmEditModeFulfilledEpic = makeEpic((action$, state$) =>
  action$.pipe(
    filter(isActionOf(confirmEditModeFulfilled)),
    map(action => getIDFromString(action.payload?.results[0]?.annotation)),
    withLatestFrom(
      state$.pipe(
        pluck('router', 'location', 'pathname'),
        map(getCurrentAnnotationId)
      )
    ),
    switchMap(([id, currentAnnotationId]) =>
      from(
        R.pipe(
          [
            clearAnnotationData(),
            currentAnnotationId === id
              ? startAnnotation(currentAnnotationId)
              : !isEmbedded()
                ? replace(`/document/${id}`)
                : null,
          ],
          R.filter(R.isTruthy)
        )
      )
    )
  )
);

export const setThemeOnChangeEpic = ({
  defaultTheme,
  themes,
}: {
  defaultTheme: ThemeType;
  themes: ReadonlyArray<ThemeType>;
}) =>
  makeEpic((_, state$) =>
    combineLatest(
      state$.pipe(pluck('user', 'uiSettings', 'theme'), distinctUntilChanged()),
      state$.pipe(
        map(state => organizationSelector(state)?.uiSettings?.theme),
        distinctUntilChanged()
      )
    ).pipe(
      map(([userTheme, organizationTheme]) => userTheme || organizationTheme),
      map(theme => (theme && themes.includes(theme) ? theme : defaultTheme)),
      distinctUntilChanged(),
      map(setThemeAction)
    )
  );

const repeatedStatusEpic = makeEpic((_, state$) =>
  state$.pipe(
    pluck('repeateRequests'),
    distinctUntilChanged(),
    filter(
      repeateRequests =>
        !(!repeateRequests.length && !state$.value.ui.repeatedRequestStatus)
    ),
    filter(() => state$.value.ui.repeatedRequestStatus !== 'failed'),
    map(repeateRequests =>
      setRepeatedStatus(repeateRequests.length ? 'pending' : undefined)
    )
  )
);

const setTabVisibilityEpic = () =>
  fromEvent(document, 'visibilitychange').pipe(
    map(() =>
      setApplicationTabVisibility(document.visibilityState === 'visible')
    )
  );

const setPageVisibilityEpic = makeEpic(action$ =>
  action$.pipe(
    filter(isActionOf(setApplicationTabVisibility)),
    pluck('payload'),
    tap(b => {
      timeSpent.pageVisibilityHasChanged(b);
    }),
    mapTo({ type: 'EMPTY_ACTION' })
  )
);

export default combineEpics(
  annotationExpiredRedirectEpic,
  cacheNavigateToNextEpic,
  cancelEditModeEpic,
  cancelEmbeddedAnnotationEpic,
  confirmEditModeFulfilledEpic,
  embeddedConfigEpic,
  embeddedRedirectEpic,
  enterLoginEpic,
  enterLoginEmbeddedEpic,
  exitAnnotationEpic,
  fetchDataOnEnterEpic,
  fetchUsersEpicOnRouteEpic,
  fetchWorkspacesAndQueuesOnValidationScreenEpic,
  hideHintsEpic,
  hideUploadEpic,
  redirectToMobileScreenEpic,
  removeDatapointPathEpic,
  repeatedStatusEpic,
  setPageVisibilityEpic,
  setTabVisibilityEpic,
  setThemeOnChangeEpic({ themes: themeTypes, defaultTheme: _defaultTheme }),
  showHintsEpic,
  showUploadEpic,
  startEditModeWithHashEpic
);
