import { Url } from '@rossum/api-client';
import { AnnotationStatus } from '@rossum/api-client/shared';
import { SnakeToCamel } from '@rossum/api-client/utils';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { isStatusVisible } from '../../containers/AnnotationList/types';
import { useWorkflowStepNames } from '../../features/document-list-base/hooks/useWorkflowStepNames';
import { State } from '../../types/state';
import StatusChip from '../../ui/status/StatusChip';

type DocumentStatusProps = {
  annotationId: number;
  annotationUrl: Url;
  status: SnakeToCamel<AnnotationStatus> | undefined;
  modifier:
    | {
        id: number;
        username: string;
      }
    | string
    | undefined;
  showSplit?: boolean;
  disabled?: boolean;
};

export const DocumentStatus = ({
  annotationId,
  annotationUrl,
  status,
  modifier,
  showSplit = false,
  disabled,
}: DocumentStatusProps) => {
  const intl = useIntl();
  const userId = useSelector((state: State) => state.user.id);

  const { data: workflowStepNames } = useWorkflowStepNames(
    annotationId,
    status
  );

  const isValidModifier = typeof modifier !== 'string';

  const isOwned = isValidModifier ? modifier?.id === userId : false;

  const enforceVisible = showSplit && status === 'split';

  const getTooltipTitle = () => {
    if (status === 'reviewing') {
      return intl.formatMessage(
        {
          id: `containers.annotationList.statuses.reviewing.tooltip`,
        },
        {
          modifier:
            (isValidModifier && modifier?.username) ??
            intl.formatMessage({
              id: 'containers.documents.unknownModifier',
            }),
        }
      );
    }

    if (status === 'inWorkflow' && workflowStepNames?.stepNames) {
      return workflowStepNames.stepNames[annotationUrl];
    }

    return null;
  };

  if (status === undefined || (!enforceVisible && !isStatusVisible(status))) {
    return null;
  }

  return (
    <StatusChip
      status={status}
      isOwned={isOwned}
      tooltipTitle={getTooltipTitle()}
      disabled={disabled}
    />
  );
};
