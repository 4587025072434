import { IconLock } from '@rossum/ui/icons/tabler';
import {
  Chip,
  ChipProps,
  Stack,
  SvgIcon,
  Tooltip,
  Typography,
} from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { DisplayedStatus } from './types';

const CHIP_MAX_WIDTH = 160;

const warningStatuses = ['postponed'];
const successStatuses = ['confirmed'];
const infoStatuses = ['toReview'];
const errorStatuses = [
  'failedExport',
  'failedImport',
  'rejected',
  'deleted',
  'automaticallyDeleted',
];

const getChipColor = (status: DisplayedStatus): ChipProps['color'] => {
  if (errorStatuses.includes(status)) {
    return 'error';
  }
  if (warningStatuses.includes(status)) {
    return 'warning';
  }
  if (successStatuses.includes(status)) {
    return 'success';
  }
  if (infoStatuses.includes(status)) {
    return 'info';
  }
  return 'default';
};

export type StatusChipProps = {
  status: DisplayedStatus;
  isOwned?: boolean;
  tooltipTitle?: string | null;
  maxWidth?: number;
  dataCy?: string;
  disabled?: boolean;
};

const StatusChip = ({
  tooltipTitle,
  maxWidth,
  status,
  isOwned,
  disabled,
  dataCy,
}: StatusChipProps) => {
  const intl = useIntl();

  return (
    <Tooltip placement="top" title={tooltipTitle}>
      <Chip
        data-cy={dataCy}
        color={getChipColor(status)}
        disabled={disabled}
        sx={{
          maxWidth: maxWidth ?? CHIP_MAX_WIDTH,
          cursor: 'default',
        }}
        icon={
          status === 'reviewing' && !isOwned ? (
            <SvgIcon
              component={IconLock}
              color="secondary"
              sx={{ fill: 'none', fontSize: 16 }}
            />
          ) : undefined
        }
        label={
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <Typography
              variant="caption"
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {intl.formatMessage({
                id: `containers.annotationList.statuses.${status}`,
              })}
            </Typography>
          </Stack>
        }
      />
    </Tooltip>
  );
};

export default StatusChip;
