import { alpha, Components, Theme } from '../../material';

const COMMON_COLOR_VARIANTS = [
  'aurora',
  'info',
  'success',
  'warning',
  'error',
] as const;

export const muiChip = (theme: Theme): Components['MuiChip'] => ({
  defaultProps: {
    size: 'small',
  },
  styleOverrides: {
    root: {
      lineHeight: 1.3,
      color: theme.palette.text.primary,
      border: '1px solid',
      fontSize: 12,
    },
    icon: {
      fontSize: 16,
      marginLeft: 8,
    },
    deleteIcon: {
      fontSize: 16,
      color: theme.palette.action.active,
      '&:hover': {
        color: theme.palette.text.primary,
      },
    },
    avatar: {
      width: 16,
      height: 16,
    },
  },
  variants: [
    ...COMMON_COLOR_VARIANTS.map(color => ({
      props: { color },
      style: {
        backgroundColor: alpha(theme.palette[color].main, 0.15),
        borderColor: alpha(theme.palette[color].main, 0.5),
      },
    })),
    {
      props: { color: 'default' },
      style: {
        backgroundColor: 'rgba(166, 177, 193, 0.08)',
        borderColor: 'rgba(166, 177, 193, 0.24)',
      },
    },
    {
      props: { disabled: true },
      style: {
        color: theme.palette.text.disabled,
        backgroundColor: 'rgba(166, 177, 193, 0.08)',
        borderColor: alpha(theme.palette.action.disabled, 0.8),
      },
    },
  ],
});
