import { Close } from '@rossum/ui/icons';
import { ArrowDropDown as ArrowDropDownIcon } from '@rossum/ui/icons';
import { ArrowDropUp as ArrowDropUpIcon } from '@rossum/ui/icons';
import {
  CircularProgress,
  ClickAwayListener,
  FormControl,
  Grow,
  IconButton,
  Paper,
  Popper,
  Stack,
  TextField,
} from '@rossum/ui/material';
import { MouseEvent, MouseEventHandler, ReactNode, useState } from 'react';
import { VirtualElement } from '../../../types/CustomTypes/VirtualElement';
import {
  activeLabelStyle,
  activeOutlineOpenStyle,
  activeOutlineStyle,
} from './styles';

const DEFAULT_WIDTH = 300;

type ListSelectProps = {
  value: { name: string } | undefined;
  isLoading: boolean;
  onCloseClick: (e: MouseEvent) => void;
  selectPlaceholder: string;
  label: string;
  selectWidth?: number;
  error?: string;
  children: (params: { close: () => void }) => ReactNode;
};

export const ListSelect = ({
  value,
  isLoading,
  onCloseClick,
  selectPlaceholder,
  label,
  selectWidth,
  error,
  children,
}: ListSelectProps) => {
  const [anchorEl, setAnchorEl] = useState<VirtualElement | null>(null);

  const handleClick: MouseEventHandler = event => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <FormControl
        variant="outlined"
        style={{ width: selectWidth ?? DEFAULT_WIDTH }}
      >
        <TextField
          label={label}
          InputLabelProps={{
            sx: value || open ? activeLabelStyle : {},
          }}
          value={value ? value.name : selectPlaceholder}
          sx={{
            width: selectWidth ?? DEFAULT_WIDTH,
            ...(open
              ? activeOutlineOpenStyle
              : value
                ? activeOutlineStyle
                : {}),
          }}
          onMouseDown={e => !isLoading && handleClick(e)}
          disabled={isLoading}
          InputProps={{
            endAdornment: (
              <>
                {value && (
                  <IconButton
                    size="small"
                    onMouseDown={e => {
                      onCloseClick(e);
                      setAnchorEl(null);
                    }}
                  >
                    <Close fontSize="small" />
                  </IconButton>
                )}
                {!isLoading ? (
                  open ? (
                    <ArrowDropUpIcon
                      sx={{
                        cursor: 'pointer',
                      }}
                    />
                  ) : (
                    <ArrowDropDownIcon
                      sx={{
                        cursor: 'pointer',
                      }}
                    />
                  )
                ) : (
                  <CircularProgress sx={{ flex: '0 0 auto' }} size={18} />
                )}
              </>
            ),
            inputProps: {
              readOnly: true,
              sx: {
                color: error ? 'error.main' : 'text.primary',
                cursor: !isLoading && 'pointer',
                pl: 3,
              },
            },
          }}
          error={Boolean(error)}
          helperText={error}
          size="small"
          data-cy="search"
        />
      </FormControl>
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        transition
        sx={{ zIndex: 20000 }}
        onKeyUp={event => event.key === 'Escape' && setAnchorEl(null)}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener
            onClickAway={() => setAnchorEl(null)}
            mouseEvent="onMouseDown"
          >
            <Grow {...TransitionProps}>
              <Paper
                sx={{
                  width: selectWidth ?? DEFAULT_WIDTH,
                }}
              >
                <Stack
                  maxWidth={selectWidth ?? DEFAULT_WIDTH}
                  height="clamp(400px,40vh, 500px)"
                >
                  {children({ close: () => setAnchorEl(null) })}
                </Stack>
              </Paper>
            </Grow>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  );
};
