import {
  alpha,
  ListItem,
  ListItemButton,
  ListItemProps,
  listItemSecondaryActionClasses,
} from '@rossum/ui/material';
import { ID } from '../../../../types/basic';
import { WithDepth } from '../getAttachmentRows';
import { AnnotationAttachment } from './AnnotationAttachment';
import { DocumentAttachment } from './DocumentAttachment';
import { AttachmentT, isAnnotationDisabled } from './helpers';

type Props = Pick<ListItemProps, 'sx' | 'secondaryAction'> & {
  onMouseEnter: (attachment: AttachmentT) => void;
  onMouseLeave: () => void;
  attachment: WithDepth<AttachmentT>;
  userId: ID;
};

const EmailAttachment = ({
  attachment: attachmentWithDepth,
  userId,
  sx,
  secondaryAction,
  onMouseEnter,
  onMouseLeave,
}: Props) => {
  const { item: attachment, depth } = attachmentWithDepth;
  const { annotation } = attachment;

  return (
    <ListItem
      data-cy={
        annotation
          ? 'attachements-list-annotation'
          : 'attachements-list-document'
      }
      disablePadding
      sx={{
        ...sx,
        ml: depth * 2,
        width: 'auto',
        [`& > .${listItemSecondaryActionClasses.root}`]: {
          display: 'none',
          zIndex: 1,
        },
        [`&:hover .${listItemSecondaryActionClasses.root}`]: {
          display: 'flex',
        },
        '&:nth-of-type(odd)': {
          borderRadius: 0.5,
          backgroundColor: theme =>
            alpha(
              theme.palette.mode === 'dark'
                ? theme.palette.common.white
                : theme.palette.common.black,
              0.04
            ),
        },
      }}
      secondaryAction={secondaryAction}
    >
      <ListItemButton
        sx={{ padding: 0 }}
        disabled={annotation && isAnnotationDisabled(annotation.status)}
      >
        {attachment.annotation ? (
          <AnnotationAttachment
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            attachment={{
              annotation: attachment.annotation,
              document: attachment.document,
              annotationModifier: attachment.annotationModifier,
            }}
            isChild={depth > 0}
            userId={userId}
          />
        ) : (
          <DocumentAttachment
            attachment={attachment.document}
            isChild={depth > 0}
          />
        )}
      </ListItemButton>
    </ListItem>
  );
};

export { EmailAttachment };
