import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button,
  CircularProgress,
  DialogContent,
  Stack,
  Typography,
} from '@rossum/ui/material';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import z from 'zod';
import { useUnpaginatedUsers } from '../../../../business/users/useUnpaginatedUsers';
import { boldText } from '../../../../lib/formaterValues';
import { getRoleName } from '../../../../redux/modules/groups/helpers';
import { State } from '../../../../types/state';
import TokenOwnerSelect from '../TokenOwnerSelect';

const tokenOwnerDialogSchema = z.object({
  tokenOwner: z.string().min(1),
});

type TokenOwnerDialogContentProps = {
  onSubmit: (tokenOwner: string) => void;
  onClose: () => void;
  submitting: boolean;
};

export const TokenOwnerDialogContent = ({
  onClose,
  onSubmit,
  submitting,
}: TokenOwnerDialogContentProps) => {
  const intl = useIntl();

  const usersQuery = useUnpaginatedUsers();

  const groups = useSelector((state: State) => state.groups);

  const withTokenOwnersPermission = usersQuery.data?.filter(
    user => getRoleName(user.groups, groups) !== 'organization_group_admin'
  );

  const { handleSubmit, control, formState } = useForm<
    z.infer<typeof tokenOwnerDialogSchema>
  >({
    mode: 'onSubmit',
    resolver: zodResolver(tokenOwnerDialogSchema),
    defaultValues: { tokenOwner: '' },
  });

  return (
    <DialogContent sx={{ mt: 3, mx: 3, px: 0 }}>
      <Stack spacing={3}>
        <Stack spacing={1}>
          <Typography variant="body2">
            {intl.formatMessage(
              {
                id: 'components.tokenOwnerDialog.text1',
              },
              { boldText }
            )}
          </Typography>
          <Typography variant="body2">
            {intl.formatMessage({
              id: 'components.tokenOwnerDialog.text2',
            })}
          </Typography>
        </Stack>
        <form
          onSubmit={handleSubmit(({ tokenOwner }) => {
            onSubmit(tokenOwner);
          })}
        >
          <Stack spacing={4}>
            <Stack spacing={2}>
              <Controller
                control={control}
                name="tokenOwner"
                render={({ field }) => (
                  <TokenOwnerSelect
                    {...field}
                    loading={usersQuery.isLoading}
                    allOrgUsers={usersQuery.data ?? []}
                    users={withTokenOwnersPermission ?? []}
                  />
                )}
              />
            </Stack>
            <Stack
              flexDirection="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Stack spacing={1.5} direction="row" justifyContent="flex-end">
                <Button variant="outlined" onClick={onClose} color="secondary">
                  {intl.formatMessage({
                    id: 'components.tokenOwnerDialog.buttons.close',
                  })}
                </Button>
                <Button
                  type="submit"
                  disabled={submitting || !formState.isValid}
                  startIcon={
                    submitting && <CircularProgress color="inherit" size={20} />
                  }
                  variant="contained"
                  data-cy="assign-token-owner-submit-btn"
                >
                  {intl.formatMessage({
                    id: 'components.tokenOwnerDialog.buttons.assign',
                  })}
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </form>
      </Stack>
    </DialogContent>
  );
};
