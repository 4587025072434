import { alpha, Skeleton, Stack, Typography } from '@rossum/ui/material';
import { random } from 'lodash';
import { Fragment, ReactElement, useRef } from 'react';
import { useSelector } from 'react-redux';
import { range } from 'remeda';
import { OriginalDatapointSchema } from '../../../types/schema';
import { State } from '../../../types/state';

type MiddleSkeletonWrapperProps = {
  children: ReactElement;
  datapointsAreReady: boolean;
};

const DATAPOINT_ROW_HEIGHT = 40;
const SKELETON_MIN = 30;
const SKELETON_MAX = 150;

// stolen from apps/elis/src/features/workflows/request-detail-schema/SchemaSkeleton.tsx
const LabelSkeleton = () => {
  const labelSize = useRef(random(SKELETON_MIN, SKELETON_MAX)).current;
  return <Skeleton height="22px" width={labelSize} />;
};

const ValueSkeleton = ({ isMultivalue }: { isMultivalue?: boolean }) => {
  const valueSize = useRef(random(SKELETON_MIN, SKELETON_MAX)).current;
  // 64px is width of the chip shown for multivalues
  return <Skeleton height="22px" width={isMultivalue ? '64px' : valueSize} />;
};

const MiddleSkeletonWrapper = ({
  children,
  datapointsAreReady,
}: MiddleSkeletonWrapperProps): ReactElement => {
  const sections = useSelector(
    (state: State) =>
      // WORKAROUND: Top level elements are always sections,
      // but fixing the defined type would be complicated, so we just cast it
      state.schema.originalContent as Extract<
        OriginalDatapointSchema,
        { category: 'section' }
      >[]
  );

  if (datapointsAreReady) {
    // Ideally the parent component would decide what to render. Since it's
    // a class component, with complicated areStateEqual function,
    // rather than extending mapStateToProps to access schema, we do it here
    return children;
  }

  const defaultNumberOfSkeletons = Math.max(
    0,
    // 240 accounts for top bar, sidebar footer, and section header
    (window.innerHeight - 240) / DATAPOINT_ROW_HEIGHT
  );

  const schemaIsLoaded = sections && sections.length > 0;

  return !schemaIsLoaded ? (
    <>
      <Stack spacing={0} sx={{ mt: '22px', mb: '10px', pl: '20px' }}>
        <Skeleton height="22px" width="60%" sx={{ mt: 0 }} />
      </Stack>
      {range(0, defaultNumberOfSkeletons).map(item => (
        <Stack
          direction="row"
          justifyContent="space-between"
          key={item}
          spacing={0}
          sx={{ mt: '8px', mb: '8px', pl: '20px', pr: '16px' }}
        >
          <LabelSkeleton />
          <ValueSkeleton />
        </Stack>
      ))}
    </>
  ) : (
    <>
      {sections
        .filter(row => !row.hidden)
        .map(({ id, label, children: sectionChildren }) => (
          <Fragment key={id}>
            <Stack
              direction="row"
              spacing={0}
              sx={{
                mt: '22px',
                mb: '6px',
                pl: '20px',
                pr: '16px',
                fontSize: '16px',
                fontWeight: 'bold',
              }}
            >
              {label}
            </Stack>
            {sectionChildren
              ?.filter(({ hidden, type }) => !hidden && type !== 'button')
              .map(({ id: childId, label: childLabel, category }) => (
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  key={childId}
                  spacing={0}
                  sx={{
                    mt: '8px',
                    mb: '8px',
                    pl: '47px',
                    pr: '16px',
                    fontSize: '16px',
                    color: theme => alpha(theme.palette.text.primary, 0.7),
                  }}
                >
                  <Typography noWrap>{childLabel}</Typography>
                  <ValueSkeleton isMultivalue={category === 'multivalue'} />
                </Stack>
              ))}
          </Fragment>
        ))}
    </>
  );
};

export default MiddleSkeletonWrapper;
