import {
  IconAlertOctagon,
  IconAlertTriangle,
  IconInfoSquareRounded,
} from '@rossum/ui/icons/tabler';
import {
  Button,
  Grow,
  IconButton,
  Stack,
  SvgIcon,
  Tooltip,
  Typography,
} from '@rossum/ui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { z } from 'zod';
import { Annotation } from '../../../../../types/annotation';
import { useConfirmButton } from './useConfirmButton';

type ConfirmButtonProps = {
  annotation: Annotation;
  onNavigate: (id: number | 'all') => void;
};

const iconMap = {
  error: <IconAlertOctagon />,
  warning: <IconAlertTriangle />,
  info: <IconInfoSquareRounded />,
};

export const ConfirmButton = ({
  annotation,
  onNavigate,
}: ConfirmButtonProps) => {
  const [nextMessageIndex, setNextMessageIndex] = useState(0);

  const {
    disabled: confirmButtonDisabled,
    focused: confirmButtonFocused,
    startIcon: confirmButtonStartIcon,
    label: confirmButtonLabel,
    onClick: handleConfirmClick,
    onKeyDown: handleConfirmKeyDown,
    datapointMessages,
  } = useConfirmButton(annotation);

  // prioritise errors over warnings over info
  const { error, warning, info } = datapointMessages;
  const nextMessageScope = error ?? warning ?? info ?? [];

  const nextMessage = nextMessageScope[nextMessageIndex];

  const intl = useIntl();

  const confirmButtonRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    const confirmButton = confirmButtonRef.current;
    if (confirmButton) {
      if (confirmButtonFocused) {
        confirmButton.focus();
      } else {
        confirmButton.blur();
      }
    }
  }, [confirmButtonFocused]);

  const handleNavigate = useCallback(() => {
    if (!nextMessage) return;

    const parsed = z.coerce.number().safeParse(nextMessage.id);

    if (parsed.success) {
      onNavigate(parsed.data);
    } else onNavigate('all');

    // move to the next message
    setNextMessageIndex(prev => (prev + 1) % nextMessageScope.length);
  }, [nextMessage, nextMessageScope.length, onNavigate]);

  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{ width: '100%', overflow: 'hidden' }}
    >
      <Button
        ref={confirmButtonRef}
        variant="contained"
        color="primary"
        sx={{
          flexGrow: 1,
        }}
        disabled={confirmButtonDisabled}
        startIcon={confirmButtonStartIcon}
        onClick={handleConfirmClick}
        onKeyDownCapture={handleConfirmKeyDown}
        data-cy="confirm-annotation-btn"
      >
        <Typography
          variant="button"
          sx={{
            minWidth: 0,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {confirmButtonLabel}
        </Typography>
      </Button>
      <Grow in={!!nextMessage} timeout={300} unmountOnExit>
        <span>
          {nextMessage ? (
            <Tooltip
              title={intl.formatMessage({
                id:
                  nextMessage.type === 'error'
                    ? 'components.sidebarV2.footer.navigateToNextError'
                    : 'components.sidebarV2.footer.navigateToNextNonError',
              })}
            >
              <IconButton
                color={nextMessage.type}
                onClick={handleNavigate}
                sx={{ alignSelf: 'center' }}
              >
                <SvgIcon fontSize="small">{iconMap[nextMessage.type]}</SvgIcon>
              </IconButton>
            </Tooltip>
          ) : null}
        </span>
      </Grow>
    </Stack>
  );
};
