import { HookTemplate } from '@rossum/api-client/hookTemplates';
import { DetailDrawer } from '@rossum/ui';
import {
  Button,
  CircularProgress,
  Stack,
  Tooltip,
  Typography,
} from '@rossum/ui/material';
import createDOMPurify from 'dompurify';
import { get } from 'lodash';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'redux-first-history';
import { useCreateHookFromTemplate } from '../../../business/hooks/useCreateHookFromTemplate';
import { sanitizeLinks } from '../../../lib/htmlSanitization';
import { buildAuthLink } from '../../../lib/url';
import {
  isUserInDifferentOrganization,
  isUserOrgGroupAdminSelector,
  userSelector,
} from '../../../redux/modules/user/selectors';
import { fullscreenConfigAppPath } from '../containers/ConfigApp/helpers';
import { useIsHookTemplateRestricted } from '../containers/Store/hooks/useIsHookTemplateRestricted';
import { extensionDetailPath, extensionsStorePath } from '../helpers';
import { getIcon } from '../lib/helpers';
import { ContactDialog } from './ContactDialog/ContactDialog';
import styles from './style.module.sass';
import { TokenOwnerDialog } from './TokenOwnerDialog/TokenOwnerDialog';
import { useCheckIfAlreadyInstalled } from './useCheckIfAlreadyInstalled';

const DOMPurify = createDOMPurify();

DOMPurify.addHook('afterSanitizeAttributes', sanitizeLinks);

export type ExtendedHookTemplate = HookTemplate & {
  tags: string;
};

type Props = {
  extension: ExtendedHookTemplate | null;
  onClose: () => void;
};

const ExtensionDrawer = ({ onClose, extension }: Props) => {
  const [openDialogType, setOpenDialogType] = useState<
    'contactUs' | 'requestAccess' | 'tokenOwnerSelection' | undefined
  >(undefined);
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const userFromDifferentOrg = useSelector(isUserInDifferentOrganization);
  const isUserOrgGroupAdmin = useSelector(isUserOrgGroupAdminSelector);
  const canBeTokenOwner = !isUserOrgGroupAdmin && !userFromDifferentOrg;

  const { mutate, isLoading: creating } = useCreateHookFromTemplate();
  const isRestricted = useIsHookTemplateRestricted(extension);

  const intl = useIntl();

  const { installedHookId, hookTemplateStatus } =
    useCheckIfAlreadyInstalled(extension);

  const actionInProgress =
    creating || hookTemplateStatus === 'installable-checking';

  const handleCreateHookFromTemplate = useCallback(
    (hookTemplate: ExtendedHookTemplate, tokenOwner: string | null) => {
      mutate(
        {
          payload: {
            events: hookTemplate.events,
            hookTemplate: hookTemplate.url,
            name: hookTemplate.name,
            queues: [],
            tokenOwner,
          },
        },
        {
          onSuccess: hook => {
            if (hook.config.app?.displayMode === 'fullscreen') {
              dispatch(
                push({
                  pathname: `${extensionDetailPath(hook.id)}${fullscreenConfigAppPath}`,
                  state: {
                    backLink: extensionsStorePath(),
                  },
                })
              );
            } else {
              dispatch(
                push({
                  pathname: `${extensionDetailPath(hook.id)}`,
                })
              );
            }
          },
        }
      );
    },
    [dispatch, mutate]
  );

  const handleHookTemplateButtonClick = useCallback(
    (hookTemplate: ExtendedHookTemplate) => {
      if (hookTemplate.useTokenOwner && !canBeTokenOwner) {
        setOpenDialogType('tokenOwnerSelection');
        return;
      }

      handleCreateHookFromTemplate(
        hookTemplate,
        hookTemplate.useTokenOwner ? user.url : null
      );
    },
    [canBeTokenOwner, handleCreateHookFromTemplate, user.url]
  );

  return (
    // TODO: This will get fixed when elevations are refactored and then drawer is adjusted in RUI
    <DetailDrawer
      PaperProps={{
        elevation: 2,
        sx: {
          '.DetailDrawer-bodyBox': {
            height: '100%',
          },
        },
      }}
      title={
        extension ? (
          <Stack direction="row" spacing={2} alignItems="center">
            {getIcon(extension.type, get(extension.config, 'runtime'), {})}
            <Typography variant="h5">{extension.name}</Typography>
          </Stack>
        ) : undefined
      }
      open={extension !== null}
      keepMounted={false}
      onClose={onClose}
    >
      {extension && (
        <Stack
          padding={3}
          height="100%"
          spacing={2}
          justifyContent="space-between"
          data-cy="extension-drawer-content"
        >
          <div
            className={styles.StoreDescription}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(extension.storeDescription),
            }}
          />
          <Stack direction="row" justifyContent="flex-end" spacing={1.5}>
            <Button
              variant="outlined"
              color="secondary"
              data-cy="extensions-contact-us"
              onClick={() => setOpenDialogType('contactUs')}
            >
              {intl.formatMessage({
                id: 'containers.settings.extensions.store.contactUs',
              })}
            </Button>
            {isRestricted || extension.installAction === 'request_access' ? (
              <Button
                variant="contained"
                color="primary"
                data-cy="extensions-request-access"
                onClick={() => setOpenDialogType('requestAccess')}
              >
                {intl.formatMessage({
                  id: 'containers.settings.extensions.store.requestAccess',
                })}
              </Button>
            ) : extension.externalUrl ? (
              <Tooltip
                title={intl.formatMessage({
                  id: 'containers.settings.extensions.store.useExtensionTooltip.external',
                })}
              >
                <Button
                  variant="contained"
                  data-cy="extensions-try-extension"
                  href={buildAuthLink(extension.externalUrl)}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.TryExtensionButton}
                >
                  {intl.formatMessage({
                    id: 'containers.settings.extensions.store.useExtension',
                  })}
                </Button>
              </Tooltip>
            ) : (
              <Tooltip
                title={
                  hookTemplateStatus === 'not-installable'
                    ? intl.formatMessage({
                        id: 'containers.settings.extensions.store.useExtensionTooltip.serverless',
                      })
                    : ''
                }
              >
                <Button
                  variant="contained"
                  disabled={actionInProgress}
                  data-cy="extensions-try-extension"
                  onClick={
                    installedHookId
                      ? () =>
                          dispatch(
                            push(
                              `${extensionDetailPath(installedHookId)}${fullscreenConfigAppPath}`,
                              {
                                backLink: extensionsStorePath(),
                              }
                            )
                          )
                      : () => handleHookTemplateButtonClick(extension)
                  }
                  startIcon={
                    actionInProgress && (
                      <CircularProgress color="inherit" size={14} />
                    )
                  }
                >
                  {intl.formatMessage({
                    id:
                      hookTemplateStatus === 'not-installable'
                        ? 'containers.settings.extensions.store.useExtension'
                        : `containers.settings.extensions.store.${hookTemplateStatus}`,
                  })}
                </Button>
              </Tooltip>
            )}
          </Stack>
          {openDialogType !== 'tokenOwnerSelection' && (
            <ContactDialog
              type={openDialogType}
              open={
                openDialogType === 'requestAccess' ||
                openDialogType === 'contactUs'
              }
              onClose={() => setOpenDialogType(undefined)}
              extensionName={extension.name}
            />
          )}
          <TokenOwnerDialog
            open={openDialogType === 'tokenOwnerSelection'}
            onSubmit={(tokenOwner: string) =>
              handleCreateHookFromTemplate(extension, tokenOwner)
            }
            submitting={creating}
            onClose={() => setOpenDialogType(undefined)}
          />
        </Stack>
      )}
    </DetailDrawer>
  );
};

export default ExtensionDrawer;
