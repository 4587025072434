import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useHook } from '../../business/hooks/useHook';
import { ExtensionEditor } from '../../features/extension-editor';
import {
  enterExtensionEditor,
  leaveExtensionEditor,
} from '../../redux/modules/ui/actions';
import { isPublicFunction } from '../Extension/helpers';
import { extensionDetailPath } from '../Extensions/helpers';

export const ExtensionEditorRoute = ({
  extensionId,
}: {
  extensionId: number;
}) => {
  const dispatch = useDispatch();
  const { replace } = useHistory();
  const { data: selectedExtension } = useHook(extensionId, {
    refetchInterval: hook =>
      hook && hook.type === 'function' && hook.status === 'pending'
        ? 10000
        : false,
  });

  const extensionHasEditor =
    selectedExtension && isPublicFunction(selectedExtension);

  // instead of onEnter/onExit on parent route
  // compatibility issue
  useEffect(() => {
    dispatch(enterExtensionEditor());

    return () => {
      dispatch(leaveExtensionEditor());
    };
  }, [dispatch]);

  useEffect(() => {
    if (selectedExtension && !extensionHasEditor)
      replace({
        pathname: extensionDetailPath(selectedExtension.id),
      });
  }, [replace, extensionHasEditor, selectedExtension]);

  return extensionHasEditor ? (
    <ExtensionEditor selectedExtension={selectedExtension} />
  ) : null;
};
