import { Stack, Typography } from '@rossum/ui/material';
import { get } from 'lodash';
import { useIntl } from 'react-intl';
import { Redirect, RouteProps } from 'react-router';
import Loader from '../Loader';

const OrganizationLoader = (props: RouteProps) => {
  const intl = useIntl();

  const orgName = get(props.location?.state, 'organizationName');

  return orgName ? (
    <Stack
      spacing={0}
      sx={{ alignItems: 'center', justifyContent: 'center', height: '100%' }}
    >
      <span style={{ height: '180px' }}>
        <Loader />
      </span>
      <Typography variant="body1">
        {intl.formatMessage(
          { id: 'containers.organizationLoader.title' },
          { organizationName: orgName }
        )}
      </Typography>
    </Stack>
  ) : (
    <Redirect to="/annotations" />
  );
};

export default OrganizationLoader;
