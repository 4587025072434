import { ID } from '../../utils/codecUtils';
import { PaginationQuery } from '../../utils/listQuery';
import { ListResponse, listResponse } from '../../utils/listResponse';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { Hook } from '../models/hook';
import { hookSchema } from '../models/hook.schema';
import { ExtensionEvent } from '../models/hookUtils';
import { hooksListQuerySchema } from './list.schema';

export type HooksListQuery = Partial<{
  id: ID | ID[];
  name: string;
  type: string;
  queue: number;
  active: boolean;
  configAppUrl: string;
  events: ExtensionEvent;
}> &
  PaginationQuery;

export const list = (query: HooksListQuery = {}) => {
  return {
    endpoint: `/hooks`,
    method: 'GET',
    responseSchema: listResponse(hookSchema),
    query,
    querySchema: hooksListQuerySchema,
  } as const;
};
