import { Paper, Slider, Stack, TextField, Theme } from '@rossum/ui/material';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './style.module.sass';
import { useThresholdInput } from './useThresholdInput';

const getOptionStyle = (isActive: boolean) => ({
  marginRight: '-2px',
  borderRadius: 0,
  border: (t: Theme) =>
    `2px solid ${isActive ? t.palette.primary.main : t.palette.divider}`,
  cursor: 'pointer',
  zIndex: isActive ? 1 : 0,
  boxShadow: 'none',
  '&:hover': {
    backgroundColor: (t: Theme) => t.palette.primary.main,
  },
});

type PredefinedValueT = { value: number; label: string };

type Props = {
  defaultValue: number;
  onChange: (value: number | null) => void;
  predefinedValues: PredefinedValueT[];
  value: number;
};

const Threshold = ({
  defaultValue,
  predefinedValues,
  value,
  onChange,
}: Props) => {
  const [isCustom, setIsCustom] = useState(
    !predefinedValues.some(option => option.value === value)
  );

  const { thresholdValue, handleInputBlur, handleInputChange } =
    useThresholdInput({ value, defaultValue, onChange });

  return (
    <>
      <Stack direction="row">
        {predefinedValues.map((option, index) => {
          const firstItem = index === 0;

          return (
            <Stack
              component={Paper}
              data-cy={`threshold-value-${option.value}`}
              key={option.label}
              elevation={4}
              onClick={() => {
                setIsCustom(false);
                onChange(option.value);
              }}
              p={2}
              sx={{
                ...getOptionStyle(!isCustom && value === option.value),
                borderTopLeftRadius: firstItem ? '10px' : 0,
                borderBottomLeftRadius: firstItem ? '10px' : 0,
              }}
            >
              {option.label}
            </Stack>
          );
        })}
        <Stack
          component={Paper}
          onClick={() => setIsCustom(true)}
          data-cy="threshold-value-custom"
          elevation={4}
          p={2}
          sx={{
            ...getOptionStyle(isCustom),
            borderTopRightRadius: '10px',
            borderBottomRightRadius: '10px',
          }}
        >
          <FormattedMessage id="components.threshold.custom" />
        </Stack>
      </Stack>
      {isCustom && (
        <div className={styles.ValueSlider}>
          <span className={styles.MinValue}>0</span>
          <Slider
            onChange={(_, val) => onChange(Array.isArray(val) ? val[0] : val)}
            step={0.01}
            value={value}
            max={1}
            classes={{
              root: styles.SliderRoot,
              thumb: styles.SliderThumb,
              rail: styles.SliderRail,
              track: styles.SliderTrack,
            }}
          />
          <span className={styles.MaxValue}>100 %</span>
          <div className={styles.InputWrapper}>
            <TextField
              size="small"
              // Since percentages are limited to one decimal place, user shouldn't be able to enter more than 4 characters
              slotProps={{ htmlInput: { maxLength: 4, size: 4 } }}
              onBlur={handleInputBlur}
              onChange={e => handleInputChange(e.target.value)}
              value={`${thresholdValue}`}
            />
            %
          </div>
        </div>
      )}
    </>
  );
};

export default Threshold;
