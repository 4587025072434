import { useCallback, useEffect, useState } from 'react';

export const useFooterVirtualObserver = (
  container: HTMLElement | undefined,
  getDatasetId: (el: HTMLElement) => string
) => {
  const [observer, setObserver] = useState<IntersectionObserver | null>(null);

  const [visibleItems, setVisibleItems] = useState<Record<string, boolean>>({});

  const handleVisibilityChange = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const changedItems = Object.fromEntries(
        entries.map(
          entry =>
            [
              getDatasetId(entry.target as HTMLElement),
              entry.isIntersecting,
            ] as const
        )
      );
      setVisibleItems(prevVisibleItems => ({
        ...prevVisibleItems,
        ...changedItems,
      }));
    },
    [getDatasetId]
  );

  useEffect(() => {
    if (container) {
      setObserver(
        new IntersectionObserver(entries => handleVisibilityChange(entries), {
          rootMargin: '500px',
          root: container,
        })
      );
    }
  }, [container, handleVisibilityChange]);

  return { visibleItems, observer };
};
