import { rankWith, scopeEndsWith, uiTypeIs } from '@jsonforms/core';
import { materialRenderers } from '@jsonforms/material-renderers';
import { ImageControl } from './ImageControl';
import { PromptResultControl } from './PromptResultControl';
import { QueueControl } from './QueueControl';
import { TableControl } from './TableControl';
import { TypographyControl } from './TypographyControl';

const queueControlTester = rankWith(3, scopeEndsWith('queue'));
const imageControlTester = rankWith(2, uiTypeIs('Image'));
const typographyControlTester = rankWith(2, uiTypeIs('Typography'));
const promptResultControlTester = rankWith(2, uiTypeIs('PromptResult'));
const tableControlTester = rankWith(2, uiTypeIs('Table'));

export const renderers = [
  ...materialRenderers,
  { tester: queueControlTester, renderer: QueueControl },
  { tester: imageControlTester, renderer: ImageControl },
  { tester: typographyControlTester, renderer: TypographyControl },
  { tester: promptResultControlTester, renderer: PromptResultControl },
  { tester: tableControlTester, renderer: TableControl },
];
