import { CalendarMonth, Schedule } from '@rossum/ui/icons';
import { IconButton, Stack, Theme } from '@rossum/ui/material';
import {
  DateField,
  FieldFormatTokenMap,
  PickersActionBar,
  TimePicker,
} from '@rossum/ui/x-date-pickers-pro';
import { useIntl } from 'react-intl';
import {
  defaultFullDateFormat,
  fullDateFormats,
} from '../../../../../lib/timeUtils';
import { applyTextButtonStyle } from './styles';

export const DATE_RANGE_CALENDAR_WIDTH = 624;

// MUI Date picker does not work with our preferred japanse format ('yo' for year)
const fallbackFormats: Record<string, string> = {
  ja: 'yyyy MMM do',
};

export const DateTimeInput = ({
  value,
  onChange,
  isActive,
  formatTokenMap,
}: {
  value: Date | null;
  onChange: (value: Date | null) => void;
  isActive: boolean;
  formatTokenMap: FieldFormatTokenMap;
}) => {
  const intl = useIntl();

  const getSafeFormat = () => {
    const format = fullDateFormats[intl.locale] ?? '';
    // We replicate MUI's way to check for format using `firstWord` to prevent MUI from crashing when it comes accross a format it does not recognise.
    // search for formatToken in https://github.com/mui/mui-x/blob/master/packages/x-date-pickers/src/internals/hooks/useField/useField.utils.ts#L34 for more details
    const firstWord = format.split(' ')[0];
    const formatExists = firstWord ? Boolean(formatTokenMap[firstWord]) : false;

    return formatExists
      ? format
      : fallbackFormats[intl.locale] ?? defaultFullDateFormat;
  };

  const isActiveStyle = isActive
    ? { fieldset: { borderColor: (t: Theme) => t.palette.secondary.main } }
    : {};

  return (
    <Stack
      direction="row"
      spacing={1}
      px={2}
      maxWidth={DATE_RANGE_CALENDAR_WIDTH / 2}
    >
      <DateField
        format={getSafeFormat()}
        value={value}
        onChange={onChange}
        slotProps={{
          // @ts-expect-error This works but TS is unhappy - maybe when upgrading pickers?
          textField: {
            inputProps: { sx: { pl: 1.5, pr: 1 } },
            InputProps: {
              endAdornment: (
                <CalendarMonth
                  fontSize="small"
                  sx={{ color: 'text.disabled' }}
                />
              ),
              sx: {
                pr: 1.5,
                ...isActiveStyle,
                // @ts-expect-error see above
                // eslint-disable-next-line
                fontSize: theme => theme.typography.body2.fontSize,
              },
            },
          },
        }}
        sx={{
          flex: 1,
        }}
        size="small"
      />

      <TimePicker
        value={value}
        onChange={onChange}
        ampm={false}
        slotProps={{
          textField: {
            inputProps: { sx: { pl: 1.5 } },
            InputProps: {
              sx: {
                pr: 0,
                ...isActiveStyle,
                fontSize: theme => theme.typography.body2.fontSize,
              },
            },
            size: 'small',
          },
          layout: {
            sx: {
              ul: {
                '&:after': { display: 'none' },
              },
              li: {
                fontSize: theme => theme.typography.body2.fontSize,
              },
            },
          },
          inputAdornment: {
            sx: { ml: 0 },
          },
        }}
        slots={{
          actionBar: props => (
            <PickersActionBar
              {...props}
              sx={{ button: applyTextButtonStyle() }}
            />
          ),
          openPickerButton: props => (
            <Stack pr={1}>
              <IconButton size="small" {...props} sx={{ pr: 0.5 }}>
                <Schedule fontSize="small" sx={{ color: 'text.disabled' }} />
              </IconButton>
            </Stack>
          ),
        }}
        sx={{ flex: 0.5 }}
      />
    </Stack>
  );
};
