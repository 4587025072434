import { Pagination, Stack } from '@rossum/ui/material';
import { parse } from 'query-string';
import { useHistory, useLocation } from 'react-router';
import PageSizeSelector from '../../../components/UI/PageSizeSelector';
import { setQueryCreator } from '../../../lib/url';

export type ListFooterProps = {
  listLength: number | undefined;
  totalPages: number | undefined;
};

const ListFooter = ({ listLength, totalPages }: ListFooterProps) => {
  const history = useHistory();
  const location = useLocation();
  const { page: currentPage, pageSize } = parse(location.search);

  const setQuery = setQueryCreator(history);

  return (
    <Stack
      sx={{
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '20px',
        position: 'relative',
        '& > *': {
          flex: '1 0 33%',
          '&:last-child': {
            display: 'flex',
            justifyContent: 'flex-end',
          },
        },
      }}
    >
      <Stack direction="row" alignItems="center" spacing={3}>
        <PageSizeSelector
          setPageSize={newPageSize => setQuery({ pageSize: `${newPageSize}` })}
          pageSize={pageSize}
        />
        {!!listLength && totalPages && (
          <Pagination
            page={Number(currentPage)}
            count={totalPages}
            onChange={(_, page: number) => setQuery({ page: `${page}` })}
          />
        )}
      </Stack>
    </Stack>
  );
};

export default ListFooter;
