import { endpoints } from '@rossum/api-client';
import { useQuery } from '@tanstack/react-query';
import { useApiClient } from '../../../../../lib/apiClient';

export const useTemplateWithTokenOwner = (
  isFromStore: boolean,
  hookTemplateUrl: string | null
) => {
  const api = useApiClient();

  const hookTemplate = useQuery({
    queryKey: ['hookTemplates', hookTemplateUrl] as const,

    queryFn: () => {
      if (!hookTemplateUrl) {
        throw new Error('Hook template URL is required');
      }

      return api.request(endpoints.hookTemplates.get(hookTemplateUrl));
    },
    staleTime: 10 * 1000,
    enabled: !!(isFromStore && hookTemplateUrl),
  });

  return !!hookTemplate.data?.useTokenOwner;
};
