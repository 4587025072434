import { FunctionRuntime, HookType } from '@rossum/api-client/hooks';
import { ImageNotSupported } from '@rossum/ui/icons';
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Stack,
  Typography,
} from '@rossum/ui/material';
import { useState } from 'react';
import { getIcon } from '../../lib/helpers';

type Props = {
  type: HookType;
  runtime: FunctionRuntime | undefined;
  handleClick: () => void;
  name: string;
  description: string;
  imageUrl: string | null;
  dataCy?: string;
};

export const RossumStoreTile = ({
  type,
  runtime,
  imageUrl,
  handleClick,
  name,
  description,
  dataCy,
}: Props) => {
  const [isError, setIsError] = useState(false);

  return (
    <Card sx={{ height: '100%' }}>
      <CardActionArea
        data-cy={dataCy}
        onClick={() => handleClick()}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          height: '100%',
        }}
      >
        {isError ? (
          <CardMedia
            sx={{
              justifySelf: 'center',
              aspectRatio: '905 / 312',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <ImageNotSupported
              color="disabled"
              sx={{
                width: '60px',
                height: '60px',
                alignSelf: 'center',
              }}
            />
          </CardMedia>
        ) : (
          <CardMedia
            component="img"
            image={imageUrl ?? undefined}
            alt={name}
            sx={{ p: '1px', aspectRatio: '905 / 312' }}
            onError={() => setIsError(true)}
          />
        )}
        <CardContent>
          <Stack direction="row" spacing={1} mb={1}>
            {getIcon(type, runtime, { size: 20 }, { title: '' })}
            <Typography gutterBottom variant="h6" component="div">
              {name}
            </Typography>
          </Stack>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
