import { useSelector } from 'react-redux';
import usePrometheusMetrics from '../../metrics';
import { safeOrganizationSelector } from '../../redux/modules/organization/selectors';
import { State } from '../../types/state';
import { useFeatureFlag } from '../../unleash/useFeatureFlag';

export const DocumentMetrics = () => {
  const sendMetricsToPrometheusEnabled = useFeatureFlag(
    'send-metrics-to-prometheus'
  );

  const prometheusMetricsEnabled =
    !!useSelector(safeOrganizationSelector)?.uiSettings
      .sendMetricsToPrometheus || sendMetricsToPrometheusEnabled;

  const documentIsInitiallyValidated = useSelector(
    (state: State) => state.datapoints.initiallyValidated
  );

  const activeOrganizationUrl = useSelector(safeOrganizationSelector)?.url;

  usePrometheusMetrics(
    documentIsInitiallyValidated,
    {
      metricName: 'frontend_annotation_view_initially_validated_time_seconds',
      description: 'Time to initial validate on annotation view in seconds',
    },
    prometheusMetricsEnabled,
    activeOrganizationUrl
  );

  return null;
};
