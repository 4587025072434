import { Settings as SettingsIcon } from '@rossum/ui/icons';
import {
  Grid,
  IconButton,
  Stack,
  SxProps,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from '@rossum/ui/material';
import { getScrollbarColors } from '@rossum/ui/theme';
import { get } from 'lodash';
import GoogleCirclesExtendedIcon from 'mdi-react/GoogleCirclesExtendedIcon';
import React, { RefObject, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { useXarrow } from 'react-xarrows';
import { extensionDetailPath } from '../../helpers';
import { ExtensionEventAction, getIcon } from '../../lib/helpers';
import {
  ExtendedHook,
  ExtensionsGraphData,
  ExtensionsRelationMap,
} from '../helpers';
import Line from './Line';
import { DRAWER_WIDTH } from './UpdateDependenciesDrawer';

type Props = {
  graphData: ExtensionsGraphData;
  extensionGraphNodesMap: ExtensionsRelationMap;
  selectedExtension?: ExtendedHook | null;
  eventNames?: ExtensionEventAction[];
  onUpdateDependenciesClick?: (
    node: ExtendedHook | null,
    nodeEvents: ExtensionEventAction[] | null,
    nodeElement: RefObject<HTMLDivElement> | undefined
  ) => void;
  gridSx?: SxProps<Theme>;
};

const DAG = ({
  graphData,
  extensionGraphNodesMap,
  selectedExtension,
  eventNames,
  gridSx,
  onUpdateDependenciesClick,
}: Props) => {
  const [actives, setActives] = useState<Array<ExtendedHook['url']>>([]);

  const history = useHistory();
  const intl = useIntl();
  const theme = useTheme();

  const updateXarrow = useXarrow();

  const { thumbColor, trackColor } = getScrollbarColors(theme);

  const refCollection = useRef<{
    [key: ExtendedHook['url']]: RefObject<HTMLDivElement>;
  }>({});

  const isNotDisabled =
    onUpdateDependenciesClick !== undefined && eventNames !== undefined;

  // return existing ref or assign new one
  const addRefToRefCollection = (key: ExtendedHook['url']) => {
    if (refCollection.current[key]) {
      return refCollection.current[key];
    }
    const newRef = React.createRef<HTMLDivElement>();
    refCollection.current[key] = newRef;
    return newRef;
  };

  useEffect(() => {
    updateXarrow();
     
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack
      justifyContent="flex-start"
      width={selectedExtension ? `calc(100% - ${DRAWER_WIDTH}px)` : '100%'}
      sx={{ transition: 'all ease-in-out 0.2s' }}
    >
      <Stack
        sx={{
          position: 'relative',
          py: 4,
          overflowX: 'auto',
          scrollbarColor: 'auto',
          [`&::-webkit-scrollbar`]: {
            width: 8,
            height: 8,
            backgroundColor: `${trackColor}`,
          },
          [`&::-webkit-scrollbar-thumb`]: {
            backgroundColor: `${thumbColor}`,
            borderRadius: 8,
          },
        }}
        onClick={() => setActives([])}
      >
        <Grid
          container
          columnSpacing={8}
          wrap="nowrap"
          columns={graphData.length}
          sx={gridSx}
        >
          {graphData.map((column: Array<ExtendedHook>) => {
            return (
              <Grid
                item
                key={column[0]?.url ?? 'empty-column-key'}
                sx={{
                  '&:nth-of-type(even)': { mt: 4.5 },
                }}
              >
                <Stack spacing={4}>
                  {column.map((node: ExtendedHook) => {
                    const currentNodeUrl = node.url;
                    const isDrawerOpen =
                      selectedExtension?.url === currentNodeUrl;

                    return (
                      <Stack key={currentNodeUrl}>
                        {node.runBefore.map(childUrl => {
                          const start = refCollection.current[currentNodeUrl];

                          const end = refCollection.current[childUrl];

                          const isActive =
                            actives.includes(childUrl) &&
                            actives.includes(currentNodeUrl);

                          return (
                            <Line
                              start={start}
                              end={end}
                              isActive={isActive}
                              key={`line-${childUrl}`}
                              dataCy={`from-${start?.current?.innerText
                                .toLowerCase()
                                .replaceAll(
                                  ' ',
                                  '-'
                                )}-to-${end?.current?.innerText
                                .toLowerCase()
                                .replaceAll(' ', '-')}`}
                            />
                          );
                        })}

                        <Stack
                          display="inline-flex"
                          direction="row"
                          spacing={1}
                          alignItems="center"
                          justifyContent="space-between"
                          sx={{
                            position: 'relative',
                            cursor: 'pointer',
                            border: '1px solid',
                            borderColor: isDrawerOpen
                              ? theme.palette.primary.main
                              : actives.includes(currentNodeUrl)
                                ? theme.palette.primary.main
                                : theme.palette.text.disabled,

                            backgroundColor: theme.palette.background.paper,
                            zIndex: actives.includes(currentNodeUrl) ? 3 : 1,
                            borderRadius: 3,
                            minWidth: 200,
                            maxWidth: 300,
                            minHeight: 40,
                            pl: 2,
                            pr: 1,
                            '&:hover': {
                              borderColor: actives.includes(currentNodeUrl)
                                ? theme.palette.primary.light
                                : theme.palette.text.secondary,
                            },
                          }}
                          ref={addRefToRefCollection(currentNodeUrl)}
                          onClick={e => {
                            e.stopPropagation();
                            setActives([
                              currentNodeUrl,
                              ...extensionGraphNodesMap[currentNodeUrl]
                                .mergedRelations,
                            ]);
                          }}
                        >
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                            sx={{
                              svg: {
                                display: 'block',
                              },
                              overflow: 'hidden',
                              opacity:
                                actives.length &&
                                !actives.includes(currentNodeUrl)
                                  ? 0.4
                                  : 1,
                            }}
                            data-cy={`extension-cell-${node.name
                              .toLowerCase()
                              .replaceAll(' ', '-')}`}
                          >
                            {getIcon(node.type, get(node.config, 'runtime'), {
                              size: 22,
                              color: node.active
                                ? theme.palette.success.light
                                : theme.palette.text.disabled,
                            })}
                            <Tooltip title={node.name} enterNextDelay={500}>
                              <Typography
                                noWrap
                                sx={{ textOverflow: 'ellipsis' }}
                              >
                                {node.name}
                              </Typography>
                            </Tooltip>
                          </Stack>

                          <Stack direction="row">
                            <Tooltip
                              enterNextDelay={500}
                              title={intl.formatMessage({
                                id: 'containers.queueExtensions.contextMenu.extensionSettings',
                              })}
                            >
                              <IconButton
                                size="small"
                                onClick={() => {
                                  history.push(extensionDetailPath(node.id), {
                                    backLink: history.location.pathname,
                                  });
                                }}
                                data-cy="go-to-extension-settings"
                              >
                                <SettingsIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>

                            <Tooltip
                              enterNextDelay={500}
                              title={intl.formatMessage({
                                id: 'containers.queueExtensions.contextMenu.updateDependencies',
                              })}
                            >
                              <IconButton
                                onClick={e => {
                                  e.stopPropagation();
                                  const nodeElement = node.url
                                    ? refCollection.current[node.url]
                                    : undefined;

                                  if (isNotDisabled) {
                                    onUpdateDependenciesClick(
                                      node,
                                      eventNames,
                                      nodeElement
                                    );
                                  }
                                }}
                                size="small"
                                data-cy={`add-extension-dependencies-${node.name
                                  .toLowerCase()
                                  .replaceAll(' ', '-')}`}
                              >
                                <GoogleCirclesExtendedIcon size={20} />
                              </IconButton>
                            </Tooltip>
                          </Stack>
                        </Stack>
                      </Stack>
                    );
                  })}
                </Stack>
              </Grid>
            );
          })}
        </Grid>
      </Stack>
    </Stack>
  );
};

export default DAG;
