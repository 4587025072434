import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@rossum/ui/icons';
import {
  Autocomplete,
  autocompleteClasses,
  createFilterOptions,
  inputBaseClasses,
  outlinedInputClasses,
  Paper,
  Popper,
  Stack,
  styled,
  TextField,
  Typography,
} from '@rossum/ui/material';
import { get } from 'lodash';
import React, {
  CSSProperties,
  FocusEventHandler,
  forwardRef,
  HTMLAttributes,
  KeyboardEvent as ReactKeyboardEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { connect } from 'react-redux';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import FakeSelect from '../../components/Datapoint/components/FakeSelect';
import { OnValueChangeOptions } from '../../components/Datapoint/components/Highlighter';
import {
  nextRow as nextRowAction,
  previousRow as previousRowAction,
} from '../../redux/modules/datapoints/actions';
import { openSelectMenu as openSelectMenuAction } from '../../redux/modules/ui/actions';
import { State as ReduxState } from '../../types/state';

const ITEM_SIZE = 30;
const EMPTY_VALUE = '';
const BOX_WIDTH = 350;
const OVER_SCAN_COUNT = 20;

const Wrapper = styled(Stack)({
  display: 'block',
  background: 'transparent',
  width: '100%',
});

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  [`& .${outlinedInputClasses.root}`]: {
    [`&.${autocompleteClasses.inputRoot}`]: {
      [`&.${inputBaseClasses.root}`]: {
        fontSize: 13,
        paddingLeft: theme.spacing(1),
        paddingRight: 60,

        [`& .${inputBaseClasses.input}`]: {
          padding: `0 ${theme.spacing(1 / 4)} 0 ${theme.spacing(1 / 4)}`,
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        },
      },
    },
    [`& .${outlinedInputClasses.notchedOutline}`]: {
      border: 'unset !important',
    },
  },
})) as typeof Autocomplete;

const ListBoxRow = (props: ListChildComponentProps) => {
  const { data, index, style } = props;
  const thisItem = data[index];

  return React.cloneElement(thisItem, { style });
};

const OuterElementContext = React.createContext<HTMLAttributes<HTMLDivElement>>(
  {}
);

const OuterElementType = forwardRef<HTMLDivElement>(
  (props: HTMLAttributes<HTMLDivElement>, ref) => {
    const outerProps = React.useContext(OuterElementContext);

    return <div ref={ref} {...props} {...outerProps} />;
  }
);

const ListBoxAdapter = forwardRef<HTMLDivElement, HTMLAttributes<HTMLElement>>(
  (componentProps, ref) => {
    const { children, style, ...rest } = componentProps;
    const itemCount = React.Children.count(children);
    const getListBoxHeight = Math.min(7, itemCount) * ITEM_SIZE;

    return (
      <div ref={ref} style={{ ...style }}>
        <OuterElementContext.Provider value={rest}>
          <FixedSizeList
            itemData={children}
            itemSize={ITEM_SIZE}
            height={getListBoxHeight}
            width="100%"
            itemCount={itemCount}
            overscanCount={OVER_SCAN_COUNT}
            innerElementType="ul"
            outerElementType={OuterElementType}
          >
            {ListBoxRow}
          </FixedSizeList>
        </OuterElementContext.Provider>
      </div>
    );
  }
);

const ListBoxPaper = (props: React.HTMLAttributes<HTMLDivElement>) => (
  <Paper
    {...props}
    square
    sx={{
      backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24))`,
    }}
  />
);

const StyledListBoxAdapter = styled(ListBoxAdapter)(({ theme }) => ({
  padding: `0 !important`,
  [`& ul`]: {
    margin: 0,
  },
  [`&::-webkit-scrollbar`]: {
    width: 8,
    height: 8,
  },
  [`& .${autocompleteClasses.option}`]: {
    fontSize: 13,
    paddingLeft: `${theme.spacing(1)}`,
    height: ITEM_SIZE,
  },
}));

type ValueType = {
  label: string;
  value: string;
};

type OwnProps = {
  onChange: (value: string, options?: OnValueChangeOptions) => void;
  options: Array<ValueType>;
  value: { label: string; value: string } | null | undefined;
  style?: CSSProperties;
  active: boolean;
  readOnly: boolean;
  clearable: boolean;
};

type DispatchProps = {
  openSelectMenu: () => void;
  nextRow: () => void;
  previousRow: () => void;
};

type Props = OwnProps & DispatchProps;

const styles = {
  popper: {
    maxWidth: 'fit-content',
  },
};

const CustomPopper = (props: React.ComponentPropsWithRef<typeof Popper>) => {
  return <Popper {...props} style={styles.popper} placement="bottom-start" />;
};

const filterOptions = createFilterOptions<ValueType>({
  stringify: ({ value, label }: ValueType) => `${value} ${label}`,
});

const SelectInputFooter = ({
  options,
  value = null,
  readOnly,
  onChange,
  clearable,
  openSelectMenu,
  nextRow,
  previousRow,
}: Props) => {
  const [activeValue, setActiveValue] = useState(value);

  const [autocompleteOpen, setAutocompleteOpen] = useState(false);
  const [highlightedOption, setHighlightedOption] = useState<ValueType | null>(
    null
  );

  const textFieldInputRef = useRef<HTMLInputElement | null>(null);
  const autocompleteRef = useRef<HTMLDivElement | null>(null);

  const mutableOptions = options;

  useEffect(() => {
    setActiveValue(value);
  }, [value]);

  const handleOpenState = useCallback(
    (isOpen: boolean) => {
      if (isOpen) {
        openSelectMenu();
        textFieldInputRef.current?.focus();
      } else {
        textFieldInputRef.current?.blur();
      }

      setAutocompleteOpen(isOpen);
    },
    [openSelectMenu]
  );

  const onValueChange = useCallback(
    (newValue: ValueType) => {
      onChange(newValue?.value || '', { setHumanValidationSource: true });
    },
    [onChange]
  );

  const textFieldInputRefCallback = useCallback((node: HTMLInputElement) => {
    textFieldInputRef.current = node;

    if (node) {
      node?.focus();
    }
  }, []);

  const textFieldInputOnFocus: FocusEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = event => {
    // timeout workaround is related to this issue
    // https://stackoverflow.com/questions/6003300/how-to-place-cursor-at-end-of-text-in-textarea-when-tabbed-into/6003829#6003829
    setTimeout(() => event.target.setSelectionRange(0, 0), 0);
  };

  useEffect(() => {
    const onKeyPress = (event: KeyboardEvent): void => {
      if (['Enter', 'Tab'].includes(event.key)) {
        handleOpenState(true);
      }
      if (['Delete', 'Backspace'].includes(event.key)) {
        event.stopPropagation();

        if (clearable) {
          onValueChange({ label: EMPTY_VALUE, value: EMPTY_VALUE });
        }
      }
    };

    window.addEventListener('keydown', onKeyPress);

    return () => {
      window.removeEventListener('keydown', onKeyPress);
    };
  }, [clearable, handleOpenState, onValueChange]);

  const onKeyDownHandler = (event: ReactKeyboardEvent) => {
    if (['ArrowDown'].includes(event.key) && !autocompleteOpen) {
      event.stopPropagation();

      textFieldInputRef.current?.blur();
      nextRow();
    }

    if (['ArrowUp'].includes(event.key) && !autocompleteOpen) {
      event.stopPropagation();

      textFieldInputRef.current?.blur();
      previousRow();
    }

    if (event.key === 'Tab' && autocompleteOpen) {
      if (highlightedOption) {
        onValueChange(highlightedOption);
      }
    }

    if (event.key === 'ArrowRight' && autocompleteOpen) {
      handleOpenState(false);
    }
  };

  const resetActiveValue = () => {
    // activeValue.value is empty only in case of new search hint without selected option
    if (activeValue && activeValue.value.length < 1) {
      setActiveValue(value);
    }
  };

  const handleActiveValue = (
    newTypedValue: string,
    previousLabel: string | number | readonly string[] | undefined
  ) => {
    const hasInitialValue = String(previousLabel) === value?.label;

    setActiveValue({
      label: hasInitialValue
        ? newTypedValue.replace(String(previousLabel), '')
        : newTypedValue,
      value: EMPTY_VALUE,
    });
  };

  if (readOnly) {
    return (
      <Stack
        sx={{
          flex: 1,
          textAlign: 'right',
        }}
      >
        {get(value, 'label', null)}
      </Stack>
    );
  }

  return (
    <Wrapper alignItems="center">
      <StyledAutocomplete
        autoHighlight
        PopperComponent={CustomPopper}
        PaperComponent={ListBoxPaper}
        id="react-select"
        className="sidebar-select react-select"
        disableClearable={!clearable}
        open={autocompleteOpen}
        filterOptions={filterOptions}
        onOpen={() => handleOpenState(true)}
        onClose={() => handleOpenState(false)}
        options={mutableOptions}
        renderInput={params => {
          return (
            <TextField
              {...params}
              inputRef={textFieldInputRefCallback}
              onFocus={textFieldInputOnFocus}
              onBlur={resetActiveValue}
              inputProps={{
                ...params.inputProps,
                onKeyDown: onKeyDownHandler,
              }}
              onChange={newValue => {
                handleActiveValue(
                  newValue.target.value,
                  params.inputProps.value
                );
              }}
            />
          );
        }}
        onHighlightChange={(_e, option: ValueType | null) => {
          return setHighlightedOption(option);
        }}
        onChange={(_e, newValue) => onValueChange(newValue as ValueType)}
        value={value}
        inputValue={activeValue?.label ?? ''}
        popupIcon={<KeyboardArrowDownIcon />}
        ListboxComponent={StyledListBoxAdapter}
        ListboxProps={{
          sx: {
            height: 'auto !important',
            ul: {
              padding: 0,
              width: BOX_WIDTH,
              overflow: 'auto',
            },
          },
        }}
        slotProps={{
          paper: {
            elevation: 12,
            sx: {
              width: BOX_WIDTH,
            },
          },
        }}
        renderOption={(props, option: ValueType) => {
          const label =
            !option.label || option.label.length === 0
              ? option.value
              : option.label;

          return (
            <Typography
              component="li"
              variant="caption"
              {...props}
              sx={{
                whiteSpace: 'nowrap',
                // !important is mandatory due override calculated inline styles
                width: 'fit-content !important',
                minWidth: '100%',
              }}
            >
              {label}
            </Typography>
          );
        }}
        ref={autocompleteRef}
      />
    </Wrapper>
  );
};

const mapDispatchToProps = {
  openSelectMenu: openSelectMenuAction,
  nextRow: nextRowAction,
  previousRow: previousRowAction,
};

const Connected = connect<unknown, DispatchProps, OwnProps, ReduxState>(
  null,
  mapDispatchToProps
)(SelectInputFooter);

export default (props: OwnProps) =>
  props.active ? (
    <Connected {...props} />
  ) : (
    <FakeSelect
      style={props.style}
      value={get(props.value, 'label')}
      inFooter
    />
  );
