import { inputBaseClasses, TextField, useTheme } from '@rossum/ui/material';
import { ChangeEventHandler, useCallback } from 'react';

type SidebarItemActiveValueProps = {
  value: string;
  onChange: (val: string) => void;
  disabled: boolean;
};

export const SidebarItemActiveValue = ({
  value,
  onChange,
  disabled,
}: SidebarItemActiveValueProps) => {
  const theme = useTheme();

  const handleChange: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = useCallback(
    e => {
      return onChange(e.target.value);
    },
    [onChange]
  );

  const handleFocus: React.FocusEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = event => {
    // https://stackoverflow.com/questions/35951771/react-autofocus-sets-cursor-to-beginning-of-input-value
    const val = event.target.value;
     
    event.target.value = '';
     
    event.target.value = val;
  };

  return (
    <TextField
      multiline
      maxRows={3}
      size="small"
      value={value}
      onChange={handleChange}
      disabled={disabled}
      autoFocus
      onFocus={handleFocus}
      sx={{
        width: '100%',
        [`& .${inputBaseClasses.adornedEnd}`]: {
          alignItems: 'flex-start',
        },
      }}
      InputProps={{
        sx: {
          textarea: {
            scrollbarColor: 'transparent transparent',
            scrollbarWidth: 'none',
          },
        },
      }}
      inputProps={{
        style: {
          ...theme.typography.body2,
        },
      }}
    />
  );
};
