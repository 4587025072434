import { Stack } from '@rossum/ui/material';
import { ReactNode } from 'react';

type Props = { children: ReactNode };

const ContentWrapper = ({ children }: Props) => (
  <Stack
    direction="column"
    sx={{
      height: '100%',
      overflow: 'hidden',
      marginLeft: '300px',
    }}
  >
    {children}
  </Stack>
);

export default ContentWrapper;
