import { CloseRounded, SearchRounded } from '@rossum/ui/icons';
import {
  IconButton,
  inputBaseClasses,
  outlinedInputClasses,
  Stack,
  StackProps,
  TextField,
} from '@rossum/ui/material';
import { useState } from 'react';
import { useIntl } from 'react-intl';

export const SEARCH_MIN_CHAR_LENGTH = 2;

type Props = {
  onChange: (value: string) => void;
  value: string;
  sx?: StackProps['sx'];
  shouldCollapseOnBlur?: boolean;
  targetWidth?: number;
};

const toggleButtonId = 'expandable-search-toggle-button';

const INPUT_PADDING = 32;
const ICON_BUTTON_WIDTH = 40;

const DEAFULT_WIDTH = 200 + INPUT_PADDING + ICON_BUTTON_WIDTH;
export const ExpandableSearch = ({
  onChange,
  value,
  sx,
  shouldCollapseOnBlur,
  targetWidth,
}: Props) => {
  const intl = useIntl();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  if (!isExpanded && !!value) setIsExpanded(true);

  const expandedWidth = targetWidth ?? DEAFULT_WIDTH;

  return (
    <Stack direction="row" spacing={1} height={1} alignItems="center" sx={sx}>
      <TextField
        inputRef={(node: HTMLInputElement | null) => {
          if (node && isExpanded) node.focus();
        }}
        variant="standard"
        value={value}
        onChange={e => onChange(e.currentTarget.value)}
        placeholder={intl.formatMessage({
          id: 'components.dashboard.queues.search',
        })}
        sx={{
          height: 1,
          [`& .${inputBaseClasses.input}`]: {
            transition: ({ transitions: { create, duration, easing } }) =>
              create(['width', 'px'], {
                duration: duration.shorter,
                easing: easing.easeInOut,
              }),
            width: isExpanded
              ? expandedWidth - INPUT_PADDING - ICON_BUTTON_WIDTH
              : 0,
            px: isExpanded ? 2 : 0,
          },
          [`& .${outlinedInputClasses.notchedOutline}`]: {
            transition: ({ transitions: { create, duration, easing } }) =>
              create(['opacity'], {
                duration: duration.shorter,
                easing: easing.easeInOut,
              }),
            opacity: isExpanded ? 1 : 0,
          },
        }}
        onBlur={e => {
          if (
            shouldCollapseOnBlur &&
            !value &&
            e.relatedTarget?.id !== toggleButtonId
          )
            setIsExpanded(false);
        }}
        InputProps={{
          sx: {
            p: 0,
            height: 1,
            '&:hover': {
              '&.MuiInput-underline:before': {
                borderBottom: theme =>
                  `2px solid ${theme.palette.secondary.main}`,
              },
            },
            '&.MuiInput-underline:before': {
              borderBottom: theme =>
                `1px solid ${theme.palette.secondary.main}`,
            },
            '&:not(:hover)': {
              '&.MuiInput-underline:before': {
                display: isExpanded ? 'block' : 'none',
              },
            },
            '&.MuiInput-underline:after': {
              ...(value ? { transform: 'scale(1)' } : {}),
            },
          },
          endAdornment: (
            <IconButton
              id={toggleButtonId}
              onClick={() => {
                if (isExpanded) {
                  onChange('');
                }

                setIsExpanded(!isExpanded);
              }}
              sx={{
                position: 'relative',
                overflow: 'hidden',
                color: 'secondary.main',
              }}
            >
              <SearchRounded
                sx={{
                  transform: `rotate(${isExpanded ? -180 : 0}deg) scale(${
                    isExpanded ? 0 : 1
                  })`,
                  opacity: isExpanded ? 0 : 1,
                  transition: ({ transitions: { duration, easing } }) =>
                    `all ${duration.shorter}ms ${easing.easeInOut}`,
                }}
              />
              <CloseRounded
                sx={{
                  transform: `rotate(${isExpanded ? 0 : 180}deg) scale(${
                    isExpanded ? 1 : 0
                  })`,
                  opacity: isExpanded ? 1 : 0,
                  transition: ({ transitions: { duration, easing } }) =>
                    `all ${duration.short}ms ${easing.easeInOut}`,
                  position: 'absolute',
                }}
              />
            </IconButton>
          ),
        }}
      />
    </Stack>
  );
};
