// This also defines their order in UI.
export const sideloadOptions = [
  'schemas',
  'modifiers',
  'queues',
  'emails',
  'related_emails',
  'relations',
  'child_relations',
  'suggested_edits',
  'assignees',
  'pages',
  'notes',
  'labels',
  'automation_blockers',
] as const;

export type SideloadValues = (typeof sideloadOptions)[number];

export type ExtensionsView = 'list' | 'graph';
